import axios from '../../util/axios';
import { Dispatch } from 'redux';
import { RootStore } from '../store';
import {
	RESET_CONSOLIDATED,
	START_CONSOLIDATED,
	SET_CARRIERS_CONSOLIDATED,
	SET_CONSOLIDATED_DESTINATION_FORM,
	SET_CONSOLIDATED_ORIGIN_FORM,
	SET_CONSOLIDATED_SPECIALS,
	ConsolidatedActions,
	CANCEL_CONSOLIDATED,
	SET_OPTION_CONSOLIDATED,
	SET_PICK_UP_CONSOLIDATED,
	SET_DELIVER_CONSOLIDATED,
	ID
} from './ActionTypes';

export const resetConsolidated = (): ConsolidatedActions => {
	return { type: RESET_CONSOLIDATED };
};

export const startConsolidated = (): ConsolidatedActions => {
	return { type: START_CONSOLIDATED };
};

export const setCarriersConsolidated = (carriers: string[]): ConsolidatedActions => {
	return { type: SET_CARRIERS_CONSOLIDATED, carriers };
};

export const setConsolidatedDestinationForm = (form: any): ConsolidatedActions => {
	return { type: SET_CONSOLIDATED_DESTINATION_FORM, form };
};

export const setConsolidatedOriginForm = (form: any): ConsolidatedActions => {
	return { type: SET_CONSOLIDATED_ORIGIN_FORM, form };
};

export const setConsolidatedSpecials = (form: any): ConsolidatedActions => {
	return { type: SET_CONSOLIDATED_SPECIALS, form };
};

export const cancelConsolidated = (): ConsolidatedActions => {
	return { type: CANCEL_CONSOLIDATED };
};

export const setOptionCosolidated = (option: ID): ConsolidatedActions => {
	return { type: SET_OPTION_CONSOLIDATED, option };
};

export const setPickUpConsolidated = (status: boolean): ConsolidatedActions => {
	return { type: SET_PICK_UP_CONSOLIDATED, status };
};

export const setDeliverConsolidated = (status: boolean): ConsolidatedActions => {
	return { type: SET_DELIVER_CONSOLIDATED, status };
};

export const postConsolidate =
	() => async (dispatch: Dispatch<ConsolidatedActions>, getStore: () => RootStore) => {
		const { send, flow, rate, create, consolidated } = getStore();
		const payload: any = {};
		if (flow.currentFlow === 'send') {
			payload.origin = {
				...send.originAddressSend
			};
			payload.destination = {
				...send.destinationAddressSend
			};
		} else {
			// Rate
			payload.origin = {
				...rate.originAddressRate
			};
			payload.destination = {
				...rate.destinationAddressRate
			};
		}

		if (consolidated.originRequirements) {
			payload.origin.requirements = consolidated.originRequirements;
		}

		if (consolidated.destinationRequirements) {
			payload.destination.requirements = consolidated.destinationRequirements;
		}

		payload.specials = consolidated.specials;

		payload.items =
			flow.currentFlow === 'rate'
				? rate.currentPackagesRate
				: flow.currentFlow === 'send'
				? send.currentPackagesSend
				: create.currentPackagesCreate;

		try {
			await axios.post('/api/rate', payload);
		} catch (error) {
			console.log('Consolidated actions', error);
		}
	};
