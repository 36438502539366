import { CSSProperties } from 'react';

export const HeadingOne = ({
	text,
	style,
	classes
}: {
	text: string;
	style?: CSSProperties;
	classes?: any;
}) => {
	return (
		<p
			style={{ fontSize: '26px', padding: '6px 0', fontWeight: 'bold', margin: 0, ...style }}
			className={classes ?? {}}
		>
			{text}
		</p>
	);
};

export const HeadingTwo = ({
	text,
	style,
	classes
}: {
	text: string;
	style?: CSSProperties;
	classes?: any;
}) => {
	return (
		<p
			style={{ fontSize: '18px', padding: '6px 0', fontWeight: 'bold', margin: 0, ...style }}
			className={classes ?? {}}
		>
			{text}
		</p>
	);
};

export const TextOne = ({
	text,
	style,
	classes
}: {
	text: string;
	style?: CSSProperties;
	classes?: any;
}) => {
	return (
		<p style={{ fontSize: '16px', margin: 0, ...style }} className={classes ?? {}}>
			{text}
		</p>
	);
};

export const TextTwo = ({
	text,
	style,
	classes
}: {
	text: string;
	style?: CSSProperties;
	classes?: any;
}) => {
	return (
		<p style={{ fontSize: '14px', margin: 0, ...style }} className={classes ?? {}}>
			{text}
		</p>
	);
};
