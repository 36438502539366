import {
	Typography,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	Grid
} from '@material-ui/core';
import { Package as PackageIcon } from 'react-feather';

import { ShipmentSendResponse } from '~store/actions/ActionTypes';
import { useStyles } from './styles';
import { formatPrice } from '~util/formatPrice';

const PackagesDetail = ({ updatedData }: { updatedData: ShipmentSendResponse }) => {
	const classes = useStyles();

	return (
		<div>
			{updatedData.packages.map((item, index) => (
				<div key={item.id}>
					<div className={classes.box}>
						<Typography variant='h5' className={classes.sectionTitle}>
							Empaque {updatedData.packages.length > 1 ? index + 1 : ''} <PackageIcon />
						</Typography>
						<Grid item container>
							<Grid item xs={12} md={6} className={classes.package}>
								<p>
									Tipo:<span>{item.type}</span>
								</p>
								<p>
									Dimensiones:
									<span>{` ${item.length}x${item.width}x${item.height} ${
										item.measurement ?? 'cm'
									}`}</span>
								</p>
								<p>
									Peso:<span>{item.weight}kg.</span>
								</p>
							</Grid>
							<Grid item xs={12} md={6} className={classes.package}>
								<p>
									Valor declarado:<span>{formatPrice(item.insurance ?? 0)}</span>
								</p>
								<p>
									Contenido:
									<span>{item.content}</span>
								</p>
								{updatedData?.price && (
									<p>
										Precio Cotizado:
										<span>{formatPrice(updatedData?.price)}</span>
									</p>
								)}
								{item.content === 'Otro' && (
									<p>
										Descripción: <span>{item.description ?? ''}</span>
									</p>
								)}
							</Grid>
						</Grid>
					</div>
					{item.merchandise && (
						<div className={classes.box}>
							<Typography variant='h5' className={classes.sectionTitle}>
								Carta Porte
							</Typography>
							<Grid item xs={12} className={classes.package}>
								<TableContainer component={Paper} className={classes.merchandise}>
									<Table aria-label='merchandise-table'>
										<TableHead>
											<TableRow>
												<TableCell className={classes.tableHead}>Clave/Descripción</TableCell>
												<TableCell className={classes.tableHead}>Cantidad</TableCell>
												<TableCell className={classes.tableHead}>Unidad</TableCell>
												<TableCell className={classes.tableHead}>Precio</TableCell>
												<TableCell className={classes.tableHead}>Subtotal</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{item.merchandise.map((row: any) => (
												<TableRow key={row.id}>
													<TableCell component='th' scope='row'>
														{typeof row.description === 'string' && row.description}
													</TableCell>
													<TableCell>{row.quantity}</TableCell>
													<TableCell>{row.unit_code}</TableCell>
													<TableCell>{formatPrice(row.value)}</TableCell>
													<TableCell>{formatPrice(row.value * row.quantity)}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						</div>
					)}
				</div>
			))}
		</div>
	);
};

export default PackagesDetail;
