const StaticMap = ({
	address,
	latitude,
	longitude
}: {
	address: string;
	latitude?: number;
	longitude?: number;
}) => {
	const parameters = longitude && latitude ? `${latitude},${longitude}` : address;

	return (
		<img
			onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${parameters}`)}
			src={`https://maps.googleapis.com/maps/api/staticmap?zoom=14&size=560x500&maptype=roadmap&markers=${parameters}&key=AIzaSyA9mQ-ghQDAssm31Tonf5dCG0ln0SNXyu0`}
			style={{ width: '100%', borderRadius: '8px', cursor: 'pointer' }}
			alt={address}
		/>
	);
};

export default StaticMap;
