import produce from 'immer';
import { MultiSendActions, RESET_MULTISEND, SET_CARRIER_MULTISEND, SET_SERVICE_MULTISEND } from '~store/actions/ActionTypes';

type MultiSendState = {
	carrierMultiSend: string | null;
	serviceMultiSend: string | null;
};

const initialState: MultiSendState = {
	carrierMultiSend: null,
	serviceMultiSend: null,
};

const multiSendReducer = (state: MultiSendState = initialState, action: MultiSendActions): MultiSendState => {
	let nextState: MultiSendState;
	switch (action.type) {
		case RESET_MULTISEND:
			return initialState;
		case SET_CARRIER_MULTISEND:
			nextState = produce(state, (draft) => {
				draft.carrierMultiSend = action.carrier;
			});
			break;
		case SET_SERVICE_MULTISEND:
			nextState = produce(state, draft => {
				draft.serviceMultiSend = action.service;
			})
			break;
		default:
			return state;
	}
	return nextState;
};

export default multiSendReducer;
