/**
 * Cell status color for grids
 */

export enum COLORS {
	ORANCE = '#ff9800',
	RED = '#aa3e3d',
	LIGHTBLUE = '#57BBD8',
	BLUE = '#409cff',
	GREEN = '#4caf50',
	VIOLET = '#77568a',
	GREY = 'dimgray'
}

// LABELS

export enum FollowUpLabelStatus {
	REGISTERED = 'Generada',
	FACILITY = 'Suc. / Ocurre',
	TRANSIT = 'En tránsito',
	CANCELLED = 'Cancelada',
	EXPIRED = 'Caducada',
	EXCEPTION = 'Incidente',
	DELIVERED = 'Entregada',
	SCANNED = 'Escaneada',
	ABORT = 'Anulada',
	FINALIZED = 'Finalizada'
}

export const ShipmentStatusRef = {
	REGISTERED: 'Generada',
	FACILITY: 'Suc. / Ocurre',
	SCANNED: 'Escaneada',
	TRANSIT: 'En tránsito',
	EXPIRED: 'Caducada',
	EXCEPTION: 'Incidente',
	ABORT: 'Anulada',
	CANCELLED: 'Cancelada',
	DELIVERED: 'Entregada',
	FINALIZED: 'Finalizada'
};

// PICKUP

export const PickupStatusRef = {
	PENDING: 'Pendiente',
	SCHEDULED: 'Programada',
	RESCHEDULED: 'Reprogramada',
	ROUTED: 'Enrutada',
	PARTIAL: 'Parcial',
	EXCEPTED: 'Excepcionada',
	CANCELLED: 'Cancelada',
	COLLECTED: 'Recolectada'
};

export enum PickupStatus {
	PENDING = 'Pendiente',
	SCHEDULED = 'Programada',
	RESCHEDULED = 'Reprogramada',
	ROUTED = 'Enrutada',
	COLLECTED = 'Recolectada',
	PARTIAL = 'Parcial',
	EXCEPTED = 'Excepcionada',
	CANCELLED = 'Cancelada'
}

// INVOICE

export enum InvoiceStatus {
	CREATED = 'Creada',
	NOT_PAID = 'Sin pagar',
	EXPIRED = 'Vencida',
	CANCELLED = 'Cancelada',
	PAID = 'Pagada',
	ABOUT_TO_CANCEL = 'Por cancelar',
	PRE_CANCELLED = 'Precancelada',
	LIQUIDATED = 'Liquidada'
}

// PAYMENT LABELS

export enum LabelPaymentStatus {
	CREATED = 'Created',
	APPLIED = 'Applied', // ??
	CANCELLED = 'Cancelled',
	REPORT = 'Report', // ??
	PRE_INVOICE = 'PreInvoice', // ??
	INVOICED = 'Invoiced' // ??
}

export enum CustomerPaymentStatus {
	PENDING = 'pending',
	VALIDATED = 'validated',
	REJECTED = 'rejected'
}

// DISPERSION

export enum DispersionStatus {
	ACTIVE = 'Vigente',
	EXPIRED = 'Caducada',
	ABOUT_TO_EXPIRE = 'Por caducar',
	FINISHED = 'Finalizada'
}

export const getCellStatusColor = (status: string) => {
	switch (status) {
		case PickupStatus.EXCEPTED:
		case PickupStatus.PARTIAL:
		case FollowUpLabelStatus.EXCEPTION:
		case InvoiceStatus.ABOUT_TO_CANCEL:
		case InvoiceStatus.EXPIRED:
		case DispersionStatus.ABOUT_TO_EXPIRE:
			// case LabelPaymentStatus.CREATED:
			// case CustomerPaymentStatus.PENDING:
			return COLORS.RED; // orange
		case PickupStatus.CANCELLED:
		case FollowUpLabelStatus.EXPIRED:
		case FollowUpLabelStatus.CANCELLED:
		case FollowUpLabelStatus.ABORT:
		case InvoiceStatus.CANCELLED:
		case LabelPaymentStatus.CANCELLED:
		case CustomerPaymentStatus.REJECTED:
		case DispersionStatus.EXPIRED:
			return COLORS.RED; // red
		case PickupStatus.SCHEDULED:
		case PickupStatus.RESCHEDULED:
		case FollowUpLabelStatus.FACILITY:
			return COLORS.LIGHTBLUE; // lightblue
		case PickupStatus.ROUTED:
		case FollowUpLabelStatus.TRANSIT:
		case FollowUpLabelStatus.SCANNED:
		case InvoiceStatus.PRE_CANCELLED:
			return COLORS.BLUE; // blue
		case PickupStatus.COLLECTED:
		case FollowUpLabelStatus.DELIVERED:
		case InvoiceStatus.PAID:
		case InvoiceStatus.LIQUIDATED:
		case 'cotización lista':
		case CustomerPaymentStatus.VALIDATED:
		case DispersionStatus.ACTIVE:
			return COLORS.GREEN; // green
		// case PickupStatus.PENDING:
		case FollowUpLabelStatus.FINALIZED:
			return COLORS.VIOLET; // grey violet
		default:
			// console.log(`@@@ Missing case for status: ${status}`);
			return COLORS.GREY;
	}
};
