// import { Dispatch } from 'redux';
import {
	BookletServiceForm,
// 	CLEAR_ADDRESS_DATA,
// 	//AddressRequest,
	CreateOrUpdateCustomerActions,
// 	DataActions,
// 	NewAddress,
// 	RESET_CUCUSTOMER,
// 	SET_ADDRESS_INFO_DRAFT_CUCUSTOMER,
// 	SET_EDITING_ADDRESS_CUCUSTOMER,
// 	SET_GENERAL_INFO_DRAFT_CUCUSTOMER,
// 	SET_GENERAL_INFO_VALID_CUCUSTOMER,
// 	SET_LEGAL_INFO_DRAFT_CUCUSTOMER,
// 	SET_LEGAL_INFO_VALID_CUCUSTOMER,
	SET_SERVICES_CUCUSTOMER,
// 	SET_TOUCHED_ADDRESS_CUCUSTOMER,
} from '../ActionTypes';

// export const setAddressInfoDraftCUCustomer = (address: NewAddress): CreateOrUpdateCustomerActions => {
// 	return { type: SET_ADDRESS_INFO_DRAFT_CUCUSTOMER, address };
// };

// export const setTouchAddressInfoDraftCUCustomer = (touched: boolean): CreateOrUpdateCustomerActions => {
// 	return { type: SET_TOUCHED_ADDRESS_CUCUSTOMER, touched: touched };
// };

// export const setGeneralInfoValidCUCustomer = (valid: boolean): CreateOrUpdateCustomerActions => {
// 	return { type: SET_GENERAL_INFO_VALID_CUCUSTOMER, valid };
// };

// export const setGeneralInfoDraftCUCustomer = (form: any): CreateOrUpdateCustomerActions => {
// 	return { type: SET_GENERAL_INFO_DRAFT_CUCUSTOMER, form };
// };

// export const setidCustomerEditingCUCustomer = (idCustomer: number, idUser: number): CreateOrUpdateCustomerActions => {
// 	return { type: SET_EDITING_ADDRESS_CUCUSTOMER, idCustomer, idUser};
// };

// export const setLegallInfoValidCUCustomer = (valid: boolean): CreateOrUpdateCustomerActions => {
// 	return { type: SET_LEGAL_INFO_VALID_CUCUSTOMER, valid };
// };

// export const setLegalInfoDraftCUCustomer = (form: any): CreateOrUpdateCustomerActions => {
// 	return { type: SET_LEGAL_INFO_DRAFT_CUCUSTOMER, form };
// };

export const setServicesCUCustomer = (services: BookletServiceForm[]): CreateOrUpdateCustomerActions => {
	return { type: SET_SERVICES_CUCUSTOMER, services };
};

// export const resetCUCustomer = () => async (dispatch: Dispatch<DataActions | CreateOrUpdateCustomerActions>) => {
// 	dispatch({ type: RESET_CUCUSTOMER });
// 	dispatch({ type: CLEAR_ADDRESS_DATA });
// 	// dispatch({ type: RESET_FLOW })
// };
