import { useState, useCallback } from 'react';
import axiosInstance from '~util/axios';
import { FedexAvailibityType } from '~store/actions/ActionTypes';

interface FedexAvailabilityData {
	[key: string]: FedexAvailibityType;
}

const usePickupAvailability = () => {
	// Pickup range for fedex
	const [fedexAvailability, setFedexAvailability] = useState<FedexAvailabilityData | null>(null);

	// Check availability for fedex
	const checkFedexAvailability = useCallback(async (zipCode: string) => {
		try {
			const { data } = await axiosInstance.get(
				`/api/pickup/fedex/availability?zip_code=${zipCode}`
			);
			const formatData: { [key: string]: FedexAvailibityType } = {};
			data.options?.forEach((item: FedexAvailibityType) => {
				formatData[item?.pickup_date?.toString()] = item;
			});

			setFedexAvailability(formatData);
		} catch (error) {
			console.error({ error });
		}
	}, []);

	return {
		checkFedexAvailability,
		fedexAvailability
	};
};

export default usePickupAvailability;
