import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, TextField } from '@material-ui/core';
import { /*Plus as PlusIcon,*/ Home, User, Box } from 'react-feather';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
//import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		inputRoot: {
			backgroundColor: theme.palette.background.paper,
			borderRadius: theme.spacing(1)
		},
		// iconButton: {
		// 	padding: 10,
		// },
		icon: {
			//paddingTop: theme.spacing(2),
			marginRight: '4px'
		}
	})
);

interface Props {
	for: 'addressOrigin' | 'contactOrigin' | 'addressDestination' | 'contactDestination' | 'package';
	label: string;
	error: boolean;
	errorMessage: string;
	hideIcon?: boolean;

	//for renderInput, for normal use unneded
	params?: AutocompleteRenderInputParams;
	disabled?: boolean;
}

/**
 * The textfield for each autocomplete
 */
const RenderInput: React.FC<Props> = (props) => {
	const classes = useStyles();
	return (
		<React.Fragment>
			<Grid
				container
				alignItems='center'
				justifyContent='center'
				style={{ height: props.error ? 70 : 60 }}
				className={classes.inputRoot}
			>
				{/* Icono */}
				{!props.hideIcon && (
					<Grid item xs={1} className={classes.icon}>
						{props.for.includes('address') && <Home />}
						{props.for.includes('contact') && <User />}
						{props.for === 'package' && <Box />}
					</Grid>
				)}

				{/* Input */}
				<Grid item xs={props.hideIcon ? 12 : 10}>
					<TextField
						{...props.params}
						error={props.error}
						//fullWidth
						variant='filled'
						label={props.label}
						helperText={props.error ? props.errorMessage : null}
						disabled={props.disabled}
					/>
				</Grid>

				{/* Plus Btn */}
				{/* <Grid item xs={1}>
					{props.link && (
						<Button
							disabled={
								props.options.length < 1 && props.for.includes('contact')
							}
							className={classes.iconButton}
							component={Link}
							to={props.link}
						>
							<PlusIcon />
						</Button>
					)}
					{props.onClick && (
						<Button
							disabled={props.shouldDisable && props.for === 'package'}
							className={classes.iconButton}
							onClick={props.onClick}
						>
							<PlusIcon />
						</Button>
					)}
				</Grid> */}
			</Grid>
		</React.Fragment>
	);
};

export default RenderInput;
