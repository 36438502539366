import { Controller, Control } from 'react-hook-form';
import { TextField, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import DropdownPaper from '~components/Forms/DropdownPaper';

import { useStyles } from './styles';
import { ShipmentSendResponse } from '~store/actions/ActionTypes';

const EditLabelFields = ({
	updatedData,
	control
}: {
	updatedData?: ShipmentSendResponse;
	control: Control;
}) => {
	const classes = useStyles();

	return (
		<Grid item container spacing={2} className={classes.box} style={{ paddingTop: '32px' }}>
			<Grid item xs={12} md={6}>
				<Controller
					name='modality'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={['Cobro', 'Incidente', 'Intercambio', 'Prepago']}
							getOptionLabel={(option) => option}
							getOptionSelected={(option, value) => value && option === value}
							onChange={(_, data) => {
								onChange(data);
							}}
							renderInput={(params) => <TextField {...params} label='Modalidad' variant='filled' />}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={updatedData?.modality}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Controller
					name='compensation'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={['Guía', 'Guía / Mercancía', 'Mercancía']}
							getOptionLabel={(option) => option}
							getOptionSelected={(option, value) => value && option === value}
							onChange={(_, data) => {
								onChange(data);
							}}
							renderInput={(params) => (
								<TextField {...params} label='Indemnización' variant='filled' />
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={updatedData?.compensation}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<Controller
					name='notes'
					control={control}
					render={({ onChange }) => (
						<TextField
							label='Notas'
							variant='filled'
							onChange={(e) => {
								onChange(e.target.value);
							}}
							fullWidth
							defaultValue={updatedData?.notes}
						/>
					)}
					defaultValue={updatedData?.notes}
					rules={{
						maxLength: { value: 256, message: 'La longitud máxima es de 256 caracteres' }
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default EditLabelFields;
