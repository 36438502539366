import { Dispatch } from 'redux';
import {
	SET_DESTINATION_ADDRESS_CREATE,
	SET_ORIGIN_ADDRESS_CREATE,
	RESET_CREATE,
	ADD_PACKAGE_CREATE,
	REMOVE_PACKAGE_CREATE,
	UPDATE_COLUMN_PACKAGE_CREATE,
	TRIGGER_VALIDATE_BEFORE_CREATE_CREATE,
	CAN_CONTINUE_TO_CREATE_CREATE,
	SET_FORM_PACKAGES_VALID_CREATE,
	CreateActions,
	Package,
	Contact,
	PayloadPackageRequest,
	AddressRequest,
	ID,
	TOGGLE_ORIGIN_ADDRESS_COMPONENT,
	TOGGLE_DESTINATION_ADDRESS_COMPONENT
} from './ActionTypes';

export const resetCreate = (): CreateActions => {
	return { type: RESET_CREATE };
};

export const setAddressCreate =
	(address: AddressRequest | null, target: 'destination' | 'origin', contacts: Contact[]) =>
	(dispatch: Dispatch<CreateActions>) => {
		if (target === 'destination') {
			dispatch({ type: SET_DESTINATION_ADDRESS_CREATE, address, contacts });
		} else {
			dispatch({ type: SET_ORIGIN_ADDRESS_CREATE, address, contacts });
		}
		dispatch({ type: CAN_CONTINUE_TO_CREATE_CREATE });
	};

export const addPkgCreate = (pkg: Package) => (dispatch: Dispatch<CreateActions>) => {
	dispatch({ type: ADD_PACKAGE_CREATE, pkg });
	dispatch({ type: CAN_CONTINUE_TO_CREATE_CREATE });
};

export const removePackageCreate = (id: ID) => (dispatch: Dispatch<CreateActions>) => {
	dispatch({ type: REMOVE_PACKAGE_CREATE, id });
	dispatch({ type: CAN_CONTINUE_TO_CREATE_CREATE });
};
export const updatePkgCreate = (
	id: string | number,
	payload: PayloadPackageRequest
): CreateActions => {
	return { type: UPDATE_COLUMN_PACKAGE_CREATE, id, payload };
};

export const triggerValidationsCreate = (status: boolean): CreateActions => {
	return { type: TRIGGER_VALIDATE_BEFORE_CREATE_CREATE, status };
};

export const setValidateFormPkgsCreate =
	(status: boolean) => (dispatch: Dispatch<CreateActions>) => {
		dispatch({ type: SET_FORM_PACKAGES_VALID_CREATE, status });
		dispatch({ type: CAN_CONTINUE_TO_CREATE_CREATE });
	};

export const toggleOriginAddress = (): CreateActions => {
	return { type: TOGGLE_ORIGIN_ADDRESS_COMPONENT };
};

export const toggleDestinationAddress = (): CreateActions => {
	return { type: TOGGLE_DESTINATION_ADDRESS_COMPONENT };
};
