import { Grid, makeStyles, createStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			color: '#FFFFFF50',
			fontSize: '0.75rem'
		}
	})
);

const CopyRight: React.FC = () => {
	const classes = useStyles();
	return (
		<Grid
			container
			item
			xs={12}
			justifyContent='center'
			alignItems='center'
			className={classes.root}
			style={{ margin: '20px 0' }}
		>
			© 2021 Encamino SA de CV. Todos los derechos reservados.
		</Grid>
	);
};

export default CopyRight;
