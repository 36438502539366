import React, { useContext, useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch } from 'react-redux';

import { useSelector } from '~store/store';
import DrawContext from '../../context/DrawContext';
import { RootStore } from '../../store/store';
import { clearError } from '../../store/actions';

const SnackBarToast: React.FC = () => {
	const dispatch = useDispatch();
	const { notification, setNotification, showNotification, setShowNotification } =
		useContext(DrawContext);
	const { errorMessageData } = useSelector((state: RootStore) => state.data);

	// Listen for error message
	useEffect(() => {
		if (errorMessageData.length > 1) {
			// setNotification({ message: errorMessageData });
			dispatch(clearError());
		}
	}, [dispatch, errorMessageData, setNotification, setShowNotification]);

	useEffect(() => {
		// console.log('current message', notification?.message.length);
		if (notification?.message.length > 0) {
			setShowNotification(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notification]);

	const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setShowNotification(false);
		// setTimeout(() => {
		// 	setNotification({ message: '' });
		// }, 1000);
	};

	return (
		<Snackbar
			style={{ width: '100%' }}
			open={showNotification}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			autoHideDuration={notification.time ? notification.time : 10000}
			onClose={handleClose}
			key={notification.message}
		>
			<Alert
				onClose={handleClose}
				variant='filled'
				severity={notification.severity ? notification.severity : 'error'}
			>
				{notification?.message}
			</Alert>
		</Snackbar>
	);
};

export default SnackBarToast;
