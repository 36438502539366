import { useState } from 'react';
import { Grid, Checkbox, TextField, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import { Edit } from 'react-feather';
import { RootStore } from '~store/store';
import { useSelector } from 'react-redux';
import DropdownPaper from '~components/Forms/DropdownPaper';
import { NotificationType } from '~store/actions/ActionTypes';

enum NotificationStatusOption {
	DELIVERED = 'Entregado',
	ANY = 'Cualquier movimiento'
}

const STATUS_OPTIONS = [NotificationStatusOption.DELIVERED, NotificationStatusOption.ANY];
// const MODALITY_OPTIONS = ['Todos los envíos', 'Este envío'];
export const DEFAULT_MODALITY = 'Este envío';

export type NotificationsContactsFields = {
	alias: string;
	phone_number: string;
	guide_status: string;
	shipping_mode: string;
	active: boolean;
	user_id?: number;
	contact_id?: number;
	type?: NotificationType;
};

type NotificationProps = {
	control: Control;
	errors: FieldErrors;
	defaultValues?: NotificationsContactsFields | null;
	backgroundColor?: string;
	type?: 'user' | 'contact';
	canEdit: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: '6px 0',
			borderRadius: '8px'
		}
	})
);

const NotificationRow = ({
	control,
	errors,
	defaultValues,
	backgroundColor,
	type,
	canEdit
}: NotificationProps) => {
	const classes = useStyles();
	const { originAddressSend, destinationAddressSend } = useSelector(
		(state: RootStore) => state.send
	);
	const [isActive, setIsActive] = useState(
		!canEdit ? defaultValues?.active ?? false : type !== 'user'
	);
	const [isEdittingPhone, setToggleEdittingPhone] = useState(false);

	return (
		<Grid
			item
			xs={10}
			container
			alignItems='flex-end'
			justifyContent='center'
			spacing={2}
			style={{
				backgroundColor: backgroundColor ?? '#2d2d2d'
			}}
			className={classes.root}
		>
			<Grid item md={1} container alignItems='flex-start' justifyContent='flex-start'>
				<Grid item xs={12}>
					<Controller
						control={control}
						name={`${type}_active`}
						type={'boolean'}
						defaultValue={isActive}
						render={({ onChange }) => (
							<Checkbox
								color={'primary'}
								checked={isActive}
								onChange={(e) => {
									onChange(e.target.checked);
									setIsActive(e.target.checked);
								}}
								disabled={!canEdit}
							/>
						)}
						disabled={!canEdit}
					/>
				</Grid>
			</Grid>
			<Grid item md={3} container alignItems='center'>
				<Grid item xs={12}>
					<Controller
						rules={isActive ? { required: { value: true, message: 'Ingresa un alias' } } : {}}
						as={
							<TextField
								variant='standard'
								size='small'
								error={
									isActive &&
									Boolean(errors?.notifications) &&
									Boolean(errors?.notifications[`${type}_alias`])
								}
								// helperText={
								// 	isActive &&
								// 	Boolean(errors?.notifications) &&
								// 	errors?.notifications[`${type}_alias`]?.message
								// }
								fullWidth
								label={type === 'user' ? 'Remitente' : 'Destinatario'}
								disabled
							/>
						}
						name={`${type}_alias`}
						control={control}
						defaultValue={
							defaultValues?.alias
								? defaultValues.alias
								: type === 'user'
								? originAddressSend?.contact?.name ?? null
								: type === 'contact'
								? destinationAddressSend?.contact?.name ?? null
								: null
						}
						disabled
					/>
				</Grid>
			</Grid>
			<Grid item md={4} container alignItems='center'>
				<Grid item xs={12}>
					<Controller
						render={({ onChange, ...propsown }) => (
							<Autocomplete
								{...propsown}
								openOnFocus
								options={STATUS_OPTIONS}
								getOptionLabel={(option) => option}
								getOptionSelected={(option, value) =>
									option.normalize().toLowerCase() === value.normalize().toLowerCase()
								}
								onChange={(_, data) => {
									if (!data) return;
									onChange(data);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant='standard'
										size='small'
										// helperText={
										// 	isActive &&
										// 	Boolean(errors[`${type}_guide_status`]) &&
										// 	errors[`${type}_guide_status`]?.message
										// }
										error={isActive && Boolean(errors[`${type}_guide_status`])}
										disabled={!canEdit}
									/>
								)}
								disableClearable
								disabled={!canEdit}
								PaperComponent={(paperProps) => (
									<DropdownPaper>{paperProps.children}</DropdownPaper>
								)}
							/>
						)}
						name={`${type}_guide_status`}
						control={control}
						rules={isActive ? { required: 'Selecciona un status' } : {}}
						defaultValue={defaultValues?.guide_status || NotificationStatusOption.DELIVERED}
					/>
				</Grid>
			</Grid>
			{/* <Grid item md={3} container alignItems='center'>
				<Grid item xs={12}>
					<Controller
						render={({ onChange, ...propsown }) => (
							<Autocomplete
								{...propsown}
								openOnFocus
								options={MODALITY_OPTIONS}
								getOptionLabel={(option) => option}
								getOptionSelected={(option, value) =>
									option.normalize().toLowerCase() === value.normalize().toLowerCase()
								}
								onChange={(_, data) => {
									if (!data) return;
									onChange(data);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant='standard'
										size='small'
										error={isActive && Boolean(errors[`${type}_shipping_mode`])}
										// helperText={
										// 	isActive &&
										// 	Boolean(errors[`${type}_shipping_mode`]) &&
										// 	errors[`${type}_shipping_mode`]?.message
										// }
										disabled={!canEdit}
									/>
								)}
								disableClearable
								disabled={!canEdit}
								PaperComponent={(paperProps) => (
									<DropdownPaper>{paperProps.children}</DropdownPaper>
								)}
							/>
						)}
						name={`${type}_shipping_mode`}
						control={control}
						rules={isActive ? { required: 'Selecciona una modalidad' } : {}}
						defaultValue={defaultValues?.shipping_mode ?? ''}
					/>
				</Grid>
			</Grid> */}
			<Grid item md={!canEdit ? 4 : 3} container alignItems='center'>
				<Controller
					name={`${type}_phone_number`}
					control={control}
					rules={isActive ? { required: 'Ingrese un número de teléfono' } : {}}
					defaultValue={
						defaultValues?.phone_number
							? defaultValues.phone_number
							: type === 'user'
							? originAddressSend?.contact?.phone_number ?? null
							: type === 'contact'
							? destinationAddressSend?.contact?.phone_number ?? null
							: null
					}
					as={
						<TextField
							variant='standard'
							size='small'
							error={isActive && Boolean(errors[`${type}_phone_number`])}
							// helperText={isActive && errors[`${type}_phone_number`]?.message}
							fullWidth
							disabled={!isEdittingPhone || !canEdit}
						/>
					}
				/>
			</Grid>
			{canEdit && (
				<Grid
					item
					md={1}
					container
					alignItems='flex-start'
					justifyContent='center'
					style={{ height: '100%', cursor: 'pointer', marginBottom: '2px' }}
				>
					<Edit
						onClick={() => {
							setToggleEdittingPhone((prev) => !prev);
						}}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default NotificationRow;
