import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import RenderInput from './_RenderInput';
import DropdownPaper from '~components/Forms/DropdownPaper';

import { setContactSend, setContactPickup } from '../../store/actions';
import { setAddressCreate } from '~store/actions';

import { AddressRequest, Contact } from '~store/actions/ActionTypes';
import { useSelector } from '~store/store';
import useCurrentFlow from '~hooks/useCurrentFlow';
import { Edit as EditIcon, Plus as PlusIcon } from 'react-feather';
import { useAddBtnAutocompleteStyles } from '~hooks/useStyles';

// Filter search for Contact
const filterOptionsContact = createFilterOptions<Contact>({
	trim: true,
	stringify: (option) => JSON.stringify(option)
});

// to handle add address
const idForNewContact = 'newContact';

interface PlsBtnAutoComplete {
	target: 'origin' | 'destination';
	address?: number | string;
	children: React.ReactNode;
}

// https://stackoverflow.com/questions/59291614/how-can-i-create-a-clickable-first-option-in-material-ui-labs-autocomplete
const PlusButtonAutoComplete: React.FC<PlsBtnAutoComplete> = (props) => {
	const history = useHistory();
	const { btnAutocompleteClasses } = useAddBtnAutocompleteStyles();
	const { isSending } = useCurrentFlow();
	//const dispatch = useDispatch();

	return (
		<DropdownPaper>
			{isSending && (
				<Button
					//variant='outlined'
					fullWidth
					onMouseDown={(e) => {
						e.preventDefault();
					}}
					className={btnAutocompleteClasses.btnAdd}
					onClick={() => {
						history.push(`/direccion/${props.address}?target=${props.target}&contact=newest`);
					}}
				>
					<IconButton
						component={Typography}
						onMouseDown={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
							e.preventDefault();
						}}
						disableRipple
						className={btnAutocompleteClasses.iconAdd}
					>
						<PlusIcon />
					</IconButton>
					&nbsp; Agregar nuevo contacto
				</Button>
			)}
			{props.children}
		</DropdownPaper>
	);
};

interface Props {
	for: 'contactOrigin' | 'contactDestination';
	label: string; //passed to the render param
	isPickingUp?: boolean; // when pickup
	isCreating?: boolean;
	reset?: boolean;
	isPickingUpFromDelivery?: boolean;
	disabled?: boolean;
}

/**
 * Autocomplete for search contact
 */
const InputSearchContact: React.FC<Props> = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { isSending } = useCurrentFlow();
	const { btnAutocompleteClasses } = useAddBtnAutocompleteStyles();

	const { availableContactsPickUp, isTouchedPickup, addressPickUp } = useSelector(
		(state) => state.pickup
	);
	const {
		availableDestinationContactsSend,
		availableOriginContactsSend,
		originAddressSend,
		destinationAddressSend,
		isTouchedSend
	} = useSelector((state) => state.send);
	const {
		availableDestinationContactsCreate,
		availableOriginContactsCreate,
		originAddressCreate,
		destinationAddressCreate
	} = useSelector((state) => state.create);
	const { currentFlow } = useSelector((state) => state.flow);

	const [contact, setContact] = useState<null | Contact>(null);
	const [contactOptions, setContactOptions] = useState<Contact[]>([]);
	const [showError, setShowError] = useState(false);

	// handle the error
	useEffect(() => {
		if (currentFlow === 'pickup' && isTouchedPickup) {
			setShowError(!Boolean(addressPickUp?.contact));
		} else if (currentFlow === 'send' && isTouchedSend) {
			if (props.for === 'contactDestination') {
				setShowError(!Boolean(destinationAddressSend?.contact));
			} else if (props.for === 'contactOrigin') {
				setShowError(!Boolean(originAddressSend?.contact));
			}
		} else if (currentFlow === 'create') {
			if (props.for === 'contactDestination') {
				setShowError(!Boolean(destinationAddressCreate?.contact));
			} else if (props.for === 'contactOrigin') {
				setShowError(!Boolean(originAddressCreate?.contact));
			}
		}
	}, [
		addressPickUp?.contact,
		currentFlow,
		isTouchedPickup,
		props.for,
		originAddressSend?.contact,
		destinationAddressSend?.contact,
		isTouchedSend,
		destinationAddressCreate?.contact,
		originAddressCreate?.contact
	]);

	useEffect(() => {
		if (typeof contact?.id === 'string') {
			setContact(null);
			dispatch(setContactSend(null, props.for === 'contactOrigin' ? 'origin' : 'destination'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// set default value origin
	useEffect(() => {
		if (currentFlow === 'send' && typeof originAddressSend?.contact?.id !== 'string') {
			if (props.for === 'contactOrigin') {
				setContact(originAddressSend?.contact === undefined ? null : originAddressSend?.contact);
				//resetear contacto
				if (props.reset) {
					setContact(null);
				}
			}
		} else if (currentFlow === 'create') {
			if (props.for === 'contactOrigin') {
				setContact(
					originAddressCreate?.contact === undefined ? null : originAddressCreate?.contact
				);
				//resetear contacto
				if (props.reset) {
					setContact(null);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentFlow,
		originAddressSend?.contact,
		props.for,
		props.reset,
		originAddressCreate?.contact
	]);

	// set default value destination
	useEffect(() => {
		if (currentFlow === 'send' && typeof destinationAddressSend?.contact?.id !== 'string') {
			if (props.for === 'contactDestination') {
				setContact(
					destinationAddressSend?.contact === undefined ? null : destinationAddressSend?.contact
				);
				//resetear contacto
				if (props.reset) {
					setContact(null);
				}
			}
		} else if (currentFlow === 'create') {
			if (props.for === 'contactDestination') {
				setContact(
					destinationAddressCreate?.contact === undefined ? null : destinationAddressCreate?.contact
				);
				//resetear contacto
				if (props.reset) {
					setContact(null);
				}
			}
		}
	}, [
		currentFlow,
		destinationAddressSend?.contact,
		props.for,
		props.reset,
		destinationAddressCreate?.contact
	]);

	// set default value pickup
	useEffect(() => {
		if (currentFlow === 'pickup') {
			setContact(addressPickUp?.contact === undefined ? null : addressPickUp?.contact);
		}
	}, [addressPickUp?.contact, currentFlow]);

	// set options
	useEffect(() => {
		if (currentFlow === 'pickup') {
			setContactOptions([...availableContactsPickUp]);
		} else if (currentFlow === 'send') {
			if (props.for === 'contactDestination') {
				setContactOptions([...availableDestinationContactsSend]);
				//resetear contacto
				if (props.reset) {
					setContactOptions([]);
				}
			} else if (props.for === 'contactOrigin') {
				setContactOptions([...availableOriginContactsSend]);
				//resetear contacto
				if (props.reset) {
					setContactOptions([]);
				}
			}
		} else if (currentFlow === 'create') {
			if (props.for === 'contactDestination') {
				setContactOptions([...availableDestinationContactsCreate]);
				//resetear contacto
				if (props.reset) {
					setContactOptions([]);
				}
			} else if (props.for === 'contactOrigin') {
				setContactOptions([...availableOriginContactsCreate]);
				//resetear contacto
				if (props.reset) {
					setContactOptions([]);
				}
			}
		}
	}, [
		props.for,
		currentFlow,
		availableContactsPickUp,
		availableDestinationContactsSend,
		availableOriginContactsSend,
		props.reset,
		availableDestinationContactsCreate,
		availableOriginContactsCreate
	]);

	return (
		<Tooltip
			title='Primero elija una dirección'
			placement='bottom-start'
			arrow
			disableFocusListener={contactOptions.length > 0}
			disableHoverListener={contactOptions.length > 0}
			disableTouchListener={contactOptions.length > 0}
		>
			<Autocomplete
				disabled={contactOptions.length === 0 || props.disabled}
				size='small'
				selectOnFocus //to helps the user clear the selected value
				clearOnBlur //to helps the user to enter a new value
				// autoHighlight // to high always the first option
				openOnFocus
				PaperComponent={(paperProps) => (
					<PlusButtonAutoComplete
						target={props.for === 'contactDestination' ? 'destination' : 'origin'}
						address={
							props.for === 'contactOrigin' ? originAddressSend?.id : destinationAddressSend?.id
						}
					>
						{paperProps.children}
					</PlusButtonAutoComplete>
				)}
				filterOptions={(options, params) => {
					const filtered = filterOptionsContact(options, params);
					// Suggest the creation of a new value
					if (params.inputValue !== '') {
						filtered.push({
							email: '',
							id: idForNewContact,
							name: 'Agregar nuevo contacto',
							phone_number: '',
							type_phone_number: '',
							type: ''
						});
					}
					return filtered;
				}}
				getOptionSelected={() => true} //FIXME I cant find the reason why I cant compare as it should be
				options={contactOptions}
				// The options that are shown
				getOptionLabel={(option) => `${option.name.toUpperCase()}`}
				renderInput={(params) =>
					isSending ? (
						<Grid container>
							<Grid item xs={!props.isPickingUpFromDelivery ? 11 : 12}>
								<RenderInput
									error={showError && contactOptions.length > 0}
									errorMessage={'Seleccione un contacto'}
									params={params}
									hideIcon={props.isPickingUpFromDelivery}
									disabled={props.disabled}
									{...props}
								/>
							</Grid>
							{!props.isPickingUpFromDelivery && (
								<Grid item xs={1}>
									<Grid
										style={{ height: '100%' }}
										container
										justifyContent={'center'}
										alignItems={'center'}
									>
										<button
											className={btnAutocompleteClasses.withoutStyles}
											disabled={!Boolean(contact)}
											onClick={(event) => {
												event.preventDefault();
												const id =
													props.for === 'contactOrigin'
														? originAddressSend!.id
														: destinationAddressSend!.id;
												history.push(
													`/direccion/${id}?target=${
														props.for === 'contactOrigin' ? 'origin' : 'destination'
													}&contact_id=${contact!.id}`
												);
											}}
										>
											<EditIcon />
										</button>
									</Grid>
								</Grid>
							)}
						</Grid>
					) : props.isCreating ? (
						<Grid container>
							<Grid item xs={11}>
								<RenderInput
									error={showError && contactOptions.length > 0}
									errorMessage={'Seleccione un contacto'}
									params={params}
									hideIcon={props.isPickingUpFromDelivery}
									disabled={props.disabled}
									{...props}
								/>
							</Grid>
							{!props.isPickingUpFromDelivery && (
								<Grid item xs={1}>
									<Grid
										style={{ height: '100%' }}
										container
										justifyContent={'center'}
										alignItems={'center'}
									>
										<button
											className={btnAutocompleteClasses.withoutStyles}
											disabled={!Boolean(contact)}
											onClick={(event) => {
												event.preventDefault();
												const id =
													props.for === 'contactOrigin'
														? originAddressCreate!.id
														: destinationAddressCreate!.id;
												history.push(
													`/direccion/${id}?target=${
														props.for === 'contactOrigin' ? 'origin' : 'destination'
													}&contact_id=${contact!.id}`
												);
											}}
										>
											<EditIcon />
										</button>
									</Grid>
								</Grid>
							)}
						</Grid>
					) : props.isPickingUp ? (
						<Grid container>
							<Grid item xs={11}>
								<RenderInput
									error={showError && contactOptions.length > 0}
									errorMessage={'Seleccione un contacto'}
									params={params}
									disabled={props.disabled}
									{...props}
								/>
							</Grid>
							{/* <Grid item xs={1}>
                <Grid
                  style={{ height: "100%" }}
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <button
                    className={btnAutocompleteClasses.withoutStyles}
                    disabled={!Boolean(contact)}
                    onClick={(event) => {
                      event.preventDefault();
                      const id =
                        props.for === "contactOrigin"
                          ? contact!.id
                          : destinationAddressSend!.id;
                      history.push(
                        `/direccion/${id}?target=${
                          props.for === "contactOrigin"
                            ? "origin"
                            : "destination"
                        }&contact_id=${contact!.id}`
                      );
                    }}
                  >
                    <EditIcon />
                  </button>
                </Grid>
              </Grid> */}
						</Grid>
					) : (
						<RenderInput
							error={showError && contactOptions.length > 0}
							errorMessage={'Seleccione un contacto'}
							params={params}
							{...props}
						/>
					)
				}
				value={contact}
				onChange={(_, value) => {
					if (typeof value !== 'string') {
						if (value?.id === idForNewContact) {
							history.push('./direccion');
						} else {
							if (props.isPickingUp) {
								dispatch(setContactPickup(value));
							} else if (props.isCreating) {
								setContact(value);
								const newContactValue =
									props.for === 'contactDestination'
										? { ...destinationAddressCreate, contact: value }
										: { ...originAddressCreate, contact: value };
								dispatch(
									setAddressCreate(
										newContactValue as AddressRequest,
										props.for === 'contactDestination' ? 'destination' : 'origin',
										[]
									)
								);
							} else {
								dispatch(
									setContactSend(
										value,
										props.for === 'contactDestination' ? 'destination' : 'origin'
									)
								);
							}
						}
					}
				}}
			/>
		</Tooltip>
	);
};

export default InputSearchContact;
