import { Dispatch } from 'redux';
import {
	SET_DESTINATION_ADDRESS_RATE,
	SET_ORIGIN_ADDRESS_RATE,
	RESET_RATE,
	ADD_PACKAGE_RATE,
	REMOVE_PACKAGE_RATE,
	UPDATE_COLUMN_PACKAGE_RATE,
	TRIGGER_VALIDATE_BEFORE_RATE_RATE,
	CAN_CONTINUE_TO_RATE_RATE,
	SET_FORM_PACKAGES_VALID_RATE,
	RateActions,
	Package,
	PayloadPackageRequest,
	AddressRequest,
	ID,
	TOGGLE_ORIGIN_ADDRESS_COMPONENT,
	TOGGLE_DESTINATION_ADDRESS_COMPONENT,
} from './ActionTypes';

export const setAddressRate = (address: AddressRequest | null, target: 'destination' | 'origin') => (
	dispatch: Dispatch<RateActions>
) => {
	if (target === 'destination') {
		dispatch({ type: SET_DESTINATION_ADDRESS_RATE, address });
	} else {
		dispatch({ type: SET_ORIGIN_ADDRESS_RATE, address });
	}
	dispatch({ type: CAN_CONTINUE_TO_RATE_RATE });
};

export const resetRate = (): RateActions => {
	return { type: RESET_RATE };
};

export const addPkgRate = (pkg: Package) => (dispatch: Dispatch<RateActions>) => {
	dispatch({ type: ADD_PACKAGE_RATE, pkg });
	dispatch({ type: CAN_CONTINUE_TO_RATE_RATE });
};

export const removePackageRate = (id: ID) => (dispatch: Dispatch<RateActions>) => {
	dispatch({ type: REMOVE_PACKAGE_RATE, id });
	dispatch({ type: CAN_CONTINUE_TO_RATE_RATE });
};
export const updatePkgRate = (id: string | number, payload: PayloadPackageRequest): RateActions => {
	return { type: UPDATE_COLUMN_PACKAGE_RATE, id, payload };
};

export const triggerValidationsRate = (status: boolean): RateActions => {
	return { type: TRIGGER_VALIDATE_BEFORE_RATE_RATE, status };
};

export const setValidateFormPkgsRate = (status: boolean) => (dispatch: Dispatch<RateActions>) => {
	dispatch({ type: SET_FORM_PACKAGES_VALID_RATE, status });
	dispatch({ type: CAN_CONTINUE_TO_RATE_RATE });
};

export const toggleOriginAddressComponent = (): RateActions => {
	return { type: TOGGLE_ORIGIN_ADDRESS_COMPONENT }
}

export const toggleDestinationAddressComponent = (): RateActions => {
	return { type: TOGGLE_DESTINATION_ADDRESS_COMPONENT }
}