import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";

interface Props {
  onClick?: () => void;
  [x: string]: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelIconBtn: {
      display: "flex",
      flexDirection: "column",
      "&:hover": {
        color: theme.palette.primary.main,
      },
      fontSize: 11,
    },
  })
);

/**
 * Este botón tiene por defecto el icono arriba y el texto abajo
 */

const CustomIconButton: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      onClick={props.onClick}
      classes={{
        label: classes.labelIconBtn,
      }}
    >
      {props.children}
    </Button>
  );
};

export default CustomIconButton;
