import React, { useContext, useEffect, useState } from 'react';
import { createStyles, Fab, Fade, Grid, makeStyles, Theme } from '@material-ui/core';
import { RefreshCcw } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DrawContext from '~context/DrawContext';
import { maxWidthContainer, radiusCard } from '~hooks/useStyles';
import { useSelector } from '~store/store';
import { initFlow, resetSend, swipeAddressSend, triggerValidationsSend } from '~store/actions';
import AddressCard from '../Cards/AddressCard';
import PackageCard from '../Cards/PackageCard';
import ControlBar from '../Bar/ControlBar';
import RateOrSendControl from '../Bar/RateOrSendControl';
import usePermissions from '~hooks/usePermissions';
import { clearErrorsSimulatedUser, triggerErrorSimulatedUser } from '~store/actions/agents';
import { removePackageSend, addPkgSend } from '~store/actions';
import usePackagesStore from '~store/newstore/usePackagesStore';
import { PackageContentFieldOptions } from '~components/Inputs/PackageContentField';
import { shallow } from 'zustand/shallow';
import { resetCreatedLabels } from '~store/actions/DataActions';
import { Tooltip } from '@material-ui/core';
import ConfirmActionDialog from '~components/Modals/ConfirmActionDialog';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		convertBtn: {
			position: 'absolute',
			marginTop: '55px',
			marginLeft: '10px',
			border: `6px solid ${theme.palette.background.paper}`,
			backgroundColor: theme.palette.background.default,
			color: theme.palette.text.primary
		},
		centerFab: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		root: {
			width: '100%',
			padding: theme.spacing(3),
			borderRadius: `0 0 ${radiusCard}px ${radiusCard}px`,
			maxWidth: maxWidthContainer
		}
	})
);

/**
 * The container for send
 */
const Send: React.FC = () => {
	const classes = useStyles();
	const [setHasOldContent, hasOldContent] = usePackagesStore(
		(state) => [state.setHasOldContent, state.hasOldContent],
		shallow
	);
	const { setNotification } = useContext(DrawContext);

	const dispatch = useDispatch();
	const history = useHistory();
	const {
		originAddressSend,
		destinationAddressSend,
		currentPackagesSend,
		canContinueToRateSend,
		showInsuranceOnSummary
	} = useSelector((state) => state.send);
	const { canPostSimulatedUser } = useSelector((state) => state.simulatedUser);
	const { disableGlobalInsuranceFlow } = useSelector((state) => state.flow);

	const { isRootOrAgent } = usePermissions();

	const [reset, setReset] = useState(false);
	const [wantsToMove, setWantsToMove] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [addInsurance, setAddInsurance] = useState<boolean>(
		!disableGlobalInsuranceFlow || showInsuranceOnSummary
	);
	// 1. Change the card
	// 2. Reset errors when the container gets mounted anyway can not move util is valid
	// 3. Init the flow
	// 4. Clear error if is an agent or customer
	const { setRCardStatus } = useContext(DrawContext);
	useEffect(() => {
		dispatch(triggerValidationsSend(false));
		dispatch(initFlow('send'));
		setRCardStatus('SUMMARY_ADDRESS');
		dispatch(clearErrorsSimulatedUser());

		return () => {
			setRCardStatus('HIDDEN');
		};
	}, [setRCardStatus, dispatch]);

	useEffect(() => {
		if (currentPackagesSend.length > 0) {
			let hasOld = false;
			currentPackagesSend.forEach((item) => {
				if (item.merchandise?.length === 0 || item.merchandise === null) {
					const {
						alias,
						content,
						height,
						id,
						insurance,
						length,
						measurement,
						pkg_id,
						quantity,
						weight,
						type,
						width
					} = item;
					const packageItem = {
						alias,
						content,
						height,
						id,
						insurance,
						length,
						measurement,
						pkg_id,
						quantity,
						weight,
						type,
						width
					};
					dispatch(removePackageSend(pkg_id));
					dispatch(addPkgSend(packageItem));
				}
				const localSearch = PackageContentFieldOptions.find(
					(content) =>
						content.title
							.replace(' (Sin seguro)', '')
							.replace(' (Seguro depende del material)', '') === item.content
				);
				hasOld = Boolean(hasOld || !localSearch);
			});
			setHasOldContent(hasOld);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPackagesSend, setHasOldContent]);

	useEffect(() => {
		if (hasOldContent) {
			setNotification({
				message:
					'El contenido del paquete ha cambiado, en necesario actualizarlo para continuar con la creación de la guía',
				severity: 'error'
			});
		}
	}, [hasOldContent, setNotification]);

	useEffect(() => {
		return () => {
			setHasOldContent(false);
		};
	}, [setHasOldContent]);

	// validate before move to rate when sending
	useEffect(() => {
		setWantsToMove(false);

		if (!(wantsToMove && canContinueToRateSend)) {
			return;
		}
		if (!isRootOrAgent) {
			history.push('/resultados');
			return;
		}
		if (canPostSimulatedUser) {
			history.push('/resultados');
		}
	}, [wantsToMove, history, canContinueToRateSend, dispatch, isRootOrAgent, canPostSimulatedUser]);

	const canContinueWhenSending = (confirm?: boolean) => {
		if (hasOldContent) {
			return setNotification({
				message:
					'El contenido del paquete ha cambiado, en necesario actualizarlo para continuar con la creación de la guía',
				severity: 'error'
			});
		}

		if (
			addInsurance &&
			currentPackagesSend.every((item) => !item.insurance || item?.insurance === 0)
		) {
			return setNotification({
				message: 'Todos tus empaques tienen $0.00 en Valor Declarado',
				severity: 'error',
				time: 2000
			});
		}
		if (addInsurance && currentPackagesSend.some((item) => item.insurance === 0) && !confirm) {
			setOpenConfirmDialog(true);
			return;
		}

		setWantsToMove(true);
		dispatch(triggerValidationsSend(true));
		if (isRootOrAgent) {
			dispatch(triggerErrorSimulatedUser());
		}
	};

	useEffect(() => {
		dispatch(resetCreatedLabels());
	}, [dispatch]);

	return (
		<Fade in timeout={1500} mountOnEnter unmountOnExit>
			<>
				<ConfirmActionDialog
					isOpen={openConfirmDialog}
					onClose={() => setOpenConfirmDialog(false)}
					handleClickConfirm={() => {
						setOpenConfirmDialog(false);
						canContinueWhenSending(true);
					}}
					handleClickCancel={() => setOpenConfirmDialog(false)}
					bodyText={`Algunos de tus empaques tienen $0.00 en Valor Declarado.
					¿Deseas Continuar?
					`}
					confirmBtnLabel='Sí'
					cancelBtnLabel='No'
				/>

				<div className={classes.root}>
					{/* Container */}
					<Grid container alignItems='center'>
						{/* Origen */}
						<Grid item xs={12} md={6}>
							<AddressCard for='Origen' reset={reset} />
						</Grid>
						{(Boolean(originAddressSend) || Boolean(destinationAddressSend)) && (
							<div className={classes.centerFab}>
								<Tooltip title='Invertir Direcciones' placement='top'>
									<span>
										<Fab
											className={classes.convertBtn}
											onClick={() => dispatch(swipeAddressSend())}
										>
											<RefreshCcw />
										</Fab>
									</span>
								</Tooltip>
							</div>
						)}

						{/* Destino */}
						<Grid item xs={12} md={6}>
							<AddressCard for='Destino' reset={reset} />
						</Grid>
					</Grid>
					{/* Paquetes */}
					<br />
					<PackageCard
						currentPackages={currentPackagesSend}
						setAddInsurance={setAddInsurance}
						addInsurance={addInsurance}
					/>
				</div>
				{/* Bottom Bar */}
				<ControlBar
					onContinue={canContinueWhenSending}
					close
					onLeftControl={() => {
						dispatch(resetSend());
						dispatch(resetCreatedLabels());
					}}
					rightText='Continuar'
				>
					<RateOrSendControl />
				</ControlBar>
			</>
		</Fade>
	);
};

export default Send;
