import { Grid, LinearProgress, makeStyles, Theme, createStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			'& > * + *': {
				marginTop: theme.spacing(2)
			}
		},
		bkLine: {
			backgroundColor: 'unset'
		}
	})
);

const LoaderPage: React.FC = () => {
	const classes = useStyles();
	return (
		<Grid container justifyContent='center' alignItems='center'>
			<div className={classes.root}>
				<LinearProgress
					classes={{
						colorPrimary: classes.bkLine
					}}
				/>
			</div>
		</Grid>
	);
};

export default LoaderPage;
