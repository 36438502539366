import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
// Components
import { HeadingTwo, TextOne } from '~components/Headings';
// Styles
import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`
		},
		titleText: {
			color: '#E5E5E5'
		},
		textContent: {
			//padding: theme.spacing(1),
			color: '#E5E5E5',
			fontWeight: 500
		},
		dialogContent: {
			borderTop: `1px solid ${theme.palette.grey[800]}`,
			width: '100%'
		},
		dialogActions: {
			width: '100%',
			justifyContent: 'space-between',
			padding: 0
		},
		buttonConfirm: {
			margin: '0 16px 16px 16px',
			backgroundColor: theme.palette.primary.main,
			borderRadius: theme.spacing(1),
			'&:hover': {
				backgroundColor: theme.palette.primary.main
			},
			color: '#000000de'
		}
	})
);

type Props = {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
};

const ServerUpdatedModal: React.FC<Props> = ({ isOpen, onClose, onConfirm }) => {
	const classes = useStyles();

	return (
		<Dialog open={isOpen} maxWidth='xs' fullWidth disableEscapeKeyDown>
			<MuiDialogTitle disableTypography className={classes.title}>
				<HeadingTwo classes={classes.titleText} text='Nueva actualización' />
			</MuiDialogTitle>
			<DialogContent className={classes.dialogContent}>
				<TextOne
					classes={classes.textContent}
					text='Se actualizó la plataforma, por favor recargue la página.'
				/>
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				<Button onClick={onConfirm} autoFocus className={classes.buttonConfirm}>
					Confirmar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ServerUpdatedModal;
