const DEV_URL = 'https://stage.api.encamino.com';
const DEV_URL_WS = 'ws://stage.api.encamino.com';
const DEV_URL_RATES = 'https://stage.drive.api.encamino.com';
const DEV_PAYMENTS_URL = 'https://stage.wallet.api.encamino.com';

export const LOGROCKET_ENABLE =
	process.env.REACT_APP_ENABLE_LOGROCKET === '1' && process.env.NODE_ENV === 'production';

export const LOGROCKET_KEY = process.env.REACT_APP_LOGROCKET_KEY || '';

export const END_POINT =
	process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL_API : DEV_URL;

export const END_POINT_WS =
	process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL_API_WS : DEV_URL_WS;

export const END_POINT_RATES =
	process.env.NODE_ENV === 'production' ? process.env.REACT_APP_RATES_URL : DEV_URL_RATES;

export const END_POINT_PAYMENTS =
	process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PAYMENTS_URL : DEV_PAYMENTS_URL;
