import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	btn: {
		color: '#3f403f',
		backgroundColor: '#E5E5E5',
		paddingLeft: theme.spacing(1.75),
		paddingRight: theme.spacing(1.75),
		minWidth: '100px',
		display: 'flex',
		alignItems: 'center',
		'&:hover': {
			color: '#4EC0DF'
		},
		'& svg': {
			marginBottom: '2px'
		},
		'&:disabled': {
			color: 'grey',
			opacity: 0.9
		}
	},
	label: {
		marginLeft: '6px'
	}
}));

export { useStyles };
