import { useMemo } from 'react';
import { useSelector } from '~store/store';

export default function useCurrentFlow() {
	const { currentFlow } = useSelector(state => state.flow);

	const isSending = useMemo(() => currentFlow === 'send', [currentFlow]);

	return {
		isSending
	}
}
