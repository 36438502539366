import { Dispatch } from 'redux';
import { RootStore } from '../store';
import {
	FlowActions,
	INIT_FLOW,
	Flow,
	RESET_FLOW,
	ADD_ADDRESS_IN_FLOW,
	FILTER_CARRIER_FLOW,
	SORT_CARRIER_FLOW,
	DISABLE_GLOBAL_INSURANCE_FLOW,
	ENABLE_GLOBAL_INSURANCE_FLOW,
	SHOW_GLOBAL_INSURANCE_FLOW,
	SET_FILTERED_SERVICES,
} from './ActionTypes';

export const initFlow = (flow: Flow): FlowActions => {
	return { type: INIT_FLOW, flow };
};

export const resetFlow = (): FlowActions => {
	return { type: RESET_FLOW };
};


// FIXME delete? it was not exported
// Add and addres and know if it is for origin or destination
export const addAddressInFlow = (target: 'origin' | 'destination' | 'none'): FlowActions => {
	return { type: ADD_ADDRESS_IN_FLOW, for: target };
};

/**
 * Apply filter by carrier name
 */
export const filterCarrierFlow =
	(filter: string) => async (dispatch: Dispatch<FlowActions>, getStore: () => RootStore) => {
		const { data } = getStore();
		dispatch({ type: FILTER_CARRIER_FLOW, filter, services: data.services });
	};

/**
 * Apply sort
 */
export const sortCarrierFlow = (sort: number): FlowActions => {
	return { type: SORT_CARRIER_FLOW, sort };
};

/**
 * When there is no insurance applied
 */
export const disableInsuranceServicesFlow = (): FlowActions => {
	return { type: DISABLE_GLOBAL_INSURANCE_FLOW };
};

/**
 * When in the table the insurance applies
 */
export const enableInsuranceServicesFlow = (): FlowActions => {
	return { type: ENABLE_GLOBAL_INSURANCE_FLOW };
};

/**
 * Apply global insurance in the rate results page
 */
export const showInsuranceServicesFlow = (show: boolean): FlowActions => {
	return { type: SHOW_GLOBAL_INSURANCE_FLOW, show };
};

export const resetFilteredServicesFlow = () => async (dispatch: Dispatch<FlowActions>, getStore: () => RootStore) => {
	const { data } = getStore();
	dispatch({ type: SET_FILTERED_SERVICES, services: data.services });
};
