import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: '#353535',
		'& > ul > li > *': {
			backgroundColor: '#353535 !important'
		}
	}
}));

const DropdownPaper = ({ children }: { children: React.ReactNode }) => {
	const classes = useStyles();
	return <Paper className={classes.container}>{children}</Paper>;
};

export default DropdownPaper;
