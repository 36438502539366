import produce from 'immer';
import {
	ADD_LABEL_PICKUP,
	SET_LABELS_PICKUP,
	AddressRequest,
	CAN_CONTINUE_TO_EDIT_PICKUP,
	CAN_CONTINUE_TO_SUMMARY_PICKUP,
	CAN_MOVE_TO_BOOKLET_PICKUP,
	CANCEL_PICKUP,
	Contact,
	FETCH_PICKUPS,
	PickUpActions,
	PickUpLabelDetails,
	REMOVE_LABEL_PICKUP,
	RESET_LABELS_PICKUP,
	RESET_PICKUP,
	SET_ADDRESS_PICKUP,
	SET_CARRIER_FOLIO_PICKUP,
	SET_CARRRIER_PICKUP,
	SET_CONTACT_PICKUP,
	SET_DATE_PICKUP,
	SET_EXTRA_INDICATIONS_PICKUP,
	SET_INDICATIONS_PICKUP,
	SET_PICKUP_DETAILS,
	SET_REASON_PICKUP,
	SET_STATUS_PICKUP,
	SET_TIME_PICKUP,
	ShipmentPickUpResponse,
	ShipmentSendResponse,
	TRIGGER_VALIDATE_BEFORE_SUMMARY_PICKUP,
	UPDATE_PICKUP,
	UPDATE_PICKUP_DETAILS,
	SET_INSURANCE_FOR_PICKUP,
	SET_PICKUP_SUPERVISOR,
	SET_PICKUP_NOTES
} from '../actions/ActionTypes';
import { PickupStatus } from '~util/cellStatusColor';

type PickUpState = {
	labelsPickup: ShipmentSendResponse[];
	addressPickUp: AddressRequest | null;
	availableContactsPickUp: Contact[];
	indicationsPickUp: string;
	extraIndicationsPickUp: string;
	datePickUp: Date | null;
	startTimePickUp: Date | null;
	endTimePickUp: Date | null;
	isTouchedPickup: boolean; // if will trigger validations
	canContinueToSummaryPickUp: boolean;
	carrierPickup: string;
	canMoveToBookletPickup: boolean;

	pickUpStatus: string;
	pickUpReason: string;
	pickUpCarrierFolio: string;
	pickupSupervisor: string;
	pickupNotes: string;

	canContinueToEditPickUp: boolean;

	// TODO remove?
	pickups: ShipmentPickUpResponse[];

	insuranceForOrder: boolean;
};

const initialState: PickUpState = {
	labelsPickup: [],
	addressPickUp: null,
	availableContactsPickUp: [],
	indicationsPickUp: '',
	extraIndicationsPickUp: '',
	datePickUp: null,
	startTimePickUp: null,
	endTimePickUp: null,
	isTouchedPickup: false,
	canContinueToSummaryPickUp: false,
	carrierPickup: '',
	canMoveToBookletPickup: false,

	pickUpStatus: '',
	pickUpReason: '',
	pickUpCarrierFolio: '',
	pickupSupervisor: '',
	pickupNotes: '',

	canContinueToEditPickUp: false,

	pickups: [],

	insuranceForOrder: false
};

const PickUpReducer = (state: PickUpState = initialState, action: PickUpActions): PickUpState => {
	let nextState: PickUpState;
	switch (action.type) {
		case RESET_PICKUP:
			return initialState;
		case RESET_LABELS_PICKUP:
			nextState = produce(state, (draft) => {
				draft.labelsPickup = [];
				draft.canContinueToSummaryPickUp = false;
			});
			break;
		case SET_ADDRESS_PICKUP:
			nextState = produce(state, (draft) => {
				draft.addressPickUp = Object.assign({}, action.address);
				draft.availableContactsPickUp = action.contacts;
				if (action.contacts.length === 1 && draft.addressPickUp) {
					draft.addressPickUp.contact = Object.assign({}, action.contacts[0]);
				}
			});
			break;
		case SET_CONTACT_PICKUP:
			nextState = produce(state, (draft) => {
				if (draft.addressPickUp) {
					draft.addressPickUp.contact = action.contact;
				}
			});
			break;
		case ADD_LABEL_PICKUP:
			nextState = produce(state, (draft) => {
				draft.labelsPickup.push(action.label);
			});
			break;
		case SET_LABELS_PICKUP:
			nextState = produce(state, (draft) => {
				draft.labelsPickup = action.labels;
			});
			break;
		case REMOVE_LABEL_PICKUP:
			nextState = produce(state, (draft) => {
				draft.labelsPickup = draft.labelsPickup.filter((l) => l.parcel_number !== action.id);
			});
			break;
		case SET_INDICATIONS_PICKUP:
			nextState = produce(state, (draft) => {
				draft.indicationsPickUp = action.indications;
			});
			break;
		case SET_EXTRA_INDICATIONS_PICKUP:
			nextState = produce(state, (draft) => {
				draft.extraIndicationsPickUp = action.extra_indications;
			});
			break;
		case SET_DATE_PICKUP:
			nextState = produce(state, (draft) => {
				draft.datePickUp = action.date;
			});
			break;
		case SET_TIME_PICKUP:
			nextState = produce(state, (draft) => {
				if (action.target === 'start') {
					draft.startTimePickUp = action.time;
				} else {
					draft.endTimePickUp = action.time;
				}
			});
			break;
		case TRIGGER_VALIDATE_BEFORE_SUMMARY_PICKUP:
			nextState = produce(state, (draft) => {
				draft.isTouchedPickup = action.status;
			});
			break;
		case CAN_CONTINUE_TO_SUMMARY_PICKUP:
			nextState = produce(state, (draft) => {
				draft.canContinueToSummaryPickUp =
					Boolean(draft.addressPickUp) &&
					Boolean(draft.addressPickUp?.contact) &&
					Boolean(draft.datePickUp) &&
					Boolean(draft.startTimePickUp) &&
					Boolean(draft.endTimePickUp) &&
					draft.labelsPickup.length > 0 &&
					Boolean(draft.carrierPickup);
			});
			break;
		case CAN_MOVE_TO_BOOKLET_PICKUP:
			nextState = produce(state, (draft) => {
				draft.canMoveToBookletPickup = action.value;
			});
			break;
		case SET_CARRRIER_PICKUP:
			nextState = produce(state, (draft) => {
				draft.carrierPickup = action.carrier;
			});
			break;

		case FETCH_PICKUPS:
			nextState = produce(state, (draft) => {
				draft.pickups = action.pickups;
			});
			break;
		case CANCEL_PICKUP:
			nextState = produce(state, (draft) => {
				draft.pickups = draft.pickups.map((p) => {
					if (p.id === action.id) {
						p.status = PickupStatus.CANCELLED;
					}

					return p;
				});
			});
			break;
		case UPDATE_PICKUP:
			nextState = produce(state, (draft) => {
				draft.pickups = draft.pickups.map((p) => {
					if (p.id === action.pickup.id) {
						return { ...p, ...action.pickup, details: p.details };
					}

					return p;
				});
			});
			break;
		case SET_PICKUP_DETAILS:
			nextState = produce(state, (draft) => {
				const index = draft.pickups.findIndex((p) => p.id === action.id);
				draft.pickups[index].details = Object.assign({}, action.details);
			});
			break;
		case UPDATE_PICKUP_DETAILS:
			nextState = produce(state, (draft) => {
				const pickup = draft.pickups.find((p) => p.id === action.id);
				if (!pickup) {
					console.log('@@@ No pickup was found in UPDATE_PICKUP_DETAILS');
					return;
				}
				pickup.labels = action.labels.map((l) => l.parcel_number);
				const details: PickUpLabelDetails = {};
				action.labels.forEach((l) => {
					details[l.parcel_number] = {
						origin: l.origin,
						destination: l.destination,
						packages: l.packages
					};
				});

				pickup.details = details;
			});
			break;
		case SET_STATUS_PICKUP:
			nextState = produce(state, (draft) => {
				draft.pickUpStatus = action.status;
			});
			break;
		case SET_CARRIER_FOLIO_PICKUP:
			nextState = produce(state, (draft) => {
				draft.pickUpCarrierFolio = action.carrierFolio;
			});
			break;
		case SET_REASON_PICKUP:
			nextState = produce(state, (draft) => {
				draft.pickUpReason = action.reason;
			});
			break;
		case CAN_CONTINUE_TO_EDIT_PICKUP:
			nextState = produce(state, (draft) => {
				draft.canContinueToEditPickUp =
					Boolean(draft.addressPickUp) &&
					Boolean(draft.addressPickUp?.contact) &&
					Boolean(draft.datePickUp) &&
					Boolean(draft.startTimePickUp) &&
					Boolean(draft.endTimePickUp);

				if (action.isAgent) {
					draft.canContinueToEditPickUp =
						draft.canContinueToEditPickUp && Boolean(draft.pickUpStatus);

					// if (draft.pickUpStatus === PickupStatus.PENDING) {
					//	draft.canContinueToEditPickUp =
					//		draft.canContinueToEditPickUp &&
					//		Boolean(draft.pickUpCarrierFolio);
					//}

					if (draft.pickUpStatus === PickupStatus.EXCEPTED) {
						draft.canContinueToEditPickUp =
							draft.canContinueToEditPickUp && Boolean(draft.pickUpReason);
					}
				}
			});
			break;
		case SET_INSURANCE_FOR_PICKUP:
			nextState = produce(state, (draft) => {
				draft.insuranceForOrder = action.insuranceForOrder;
			});
			break;
		case SET_PICKUP_SUPERVISOR:
			nextState = produce(state, (draft) => {
				draft.pickupSupervisor = action.pickupSupervisor;
			});
			break;
		case SET_PICKUP_NOTES:
			nextState = produce(state, (draft) => {
				draft.pickupNotes = action.pickupNotes;
			});
			break;
		default:
			return state;
	}
	return nextState;
};

export default PickUpReducer;
