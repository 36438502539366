type SplitTextIntoLinesOptions = {
	text: string;
	maxCharactersPerLine: number;
}

export function splitTextIntoLines({ text, maxCharactersPerLine }: SplitTextIntoLinesOptions): string[] {
	if (text === "") {
		return [""];
	}

	const EMPTY_SPACE = 1;
	const lines = [] as string[];
	const [first, ...words] = text.split(' ');
	let line = first;

	if (words.length === 0 && line.length > maxCharactersPerLine) {
		let ref = line;
		line = line.substr(0, maxCharactersPerLine);
		lines.push(line);
		line = ref.substr(maxCharactersPerLine, ref.length - maxCharactersPerLine);
	}

	for (const word of words) {
		// We hit this case for example with: { text: "Bodega 09", maxCharactersPerLine: 5 }
		if (line.length > maxCharactersPerLine) {
			let ref = line;
			line = line.substr(0, maxCharactersPerLine);
			lines.push(line);
			line = ref.substr(maxCharactersPerLine, ref.length - maxCharactersPerLine);
		}

		if ((line.length + word.length + EMPTY_SPACE) <= maxCharactersPerLine) {
			line += ' ' + word;
		} else {
			lines.push(line);
			line = word;
		}
	}

	if (line.length > 0) {
		if (line.length <= maxCharactersPerLine) lines.push(line);
		if (lines.length === 0) lines.push("");
	}

	return lines;
}


