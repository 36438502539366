import produce from 'immer';
import {
	SET_DESTINATION_ADDRESS_RATE,
	SET_ORIGIN_ADDRESS_RATE,
	RESET_RATE,
	ADD_PACKAGE_RATE,
	REMOVE_PACKAGE_RATE,
	UPDATE_COLUMN_PACKAGE_RATE,
	TRIGGER_VALIDATE_BEFORE_RATE_RATE,
	CAN_CONTINUE_TO_RATE_RATE,
	SET_FORM_PACKAGES_VALID_RATE,
	Package,
	RateActions,
	AddressRequest,
	TOGGLE_ORIGIN_ADDRESS_COMPONENT,
	TOGGLE_DESTINATION_ADDRESS_COMPONENT
} from '../actions/ActionTypes';

type RateState = {
	originAddressRate: AddressRequest | null;
	destinationAddressRate: AddressRequest | null;
	originAddressComponent: 'input' | 'maps';
	destinationAddressComponent: 'input' | 'maps';
	currentPackagesRate: Package[];
	canContinueToRateRate: boolean;
	isTouchedRate: boolean;
	areFormPkgsValidRate: boolean;
};

const initialState: RateState = {
	originAddressRate: null,
	destinationAddressRate: null,
	currentPackagesRate: [],
	canContinueToRateRate: false,
	isTouchedRate: false,
	areFormPkgsValidRate: false,
	originAddressComponent: 'input',
	destinationAddressComponent: 'maps'
};

export const RateReducer = (state: RateState = initialState, action: RateActions): RateState => {
	let nextState: RateState;
	switch (action.type) {
		case SET_DESTINATION_ADDRESS_RATE:
			nextState = produce(state, (draft) => {
				draft.destinationAddressRate = action.address;
			});
			break;
		case SET_ORIGIN_ADDRESS_RATE:
			nextState = produce(state, (draft) => {
				draft.originAddressRate = action.address;
			});
			break;
		case RESET_RATE:
			return initialState;
		case ADD_PACKAGE_RATE:
			nextState = produce(state, (draft) => {
				draft.currentPackagesRate.push(action.pkg);
			});
			break;
		case REMOVE_PACKAGE_RATE:
			nextState = produce(state, (draft) => {
				draft.currentPackagesRate = draft.currentPackagesRate.filter(
					(p) => (p.pkg_id ? p.pkg_id : p.id) !== action.id
				);
			});
			break;
		case UPDATE_COLUMN_PACKAGE_RATE:
			nextState = produce(state, (draft) => {
				const index = draft.currentPackagesRate.findIndex(
					(p) => (p.pkg_id ? p.pkg_id : p.id) === action.id
				);
				if (index === -1) return;
				const key = Object.keys(action.payload)[0];
				const value = Object.values(action.payload)[0];
				switch (key) {
					case 'quantity':
					case 'insurance':
					case 'height':
					case 'width':
					case 'length':
					case 'weight':
						draft.currentPackagesRate[index][key] = value as number;
						break;
					case 'type':
					case 'content':
						draft.currentPackagesRate[index][key] = value as string;
						break;
					case 'measurement':
						draft.currentPackagesRate[index][key] = value as 'cm' | 'm';
						break;
					case 'is_irregular_delivery':
						draft.currentPackagesRate[index][key] = value;
						break;
					default:
						break;
				}
			});
			break;
		case TRIGGER_VALIDATE_BEFORE_RATE_RATE:
			nextState = produce(state, (draft) => {
				draft.isTouchedRate = action.status;
			});
			break;
		case CAN_CONTINUE_TO_RATE_RATE:
			nextState = produce(state, (draft) => {
				draft.canContinueToRateRate =
					Boolean(draft.originAddressRate) &&
					Boolean(draft.destinationAddressRate) &&
					draft.currentPackagesRate.length > 0 &&
					draft.areFormPkgsValidRate;
			});
			break;
		case SET_FORM_PACKAGES_VALID_RATE:
			nextState = produce(state, (draft) => {
				draft.areFormPkgsValidRate = action.status;
			});
			break;
		case TOGGLE_ORIGIN_ADDRESS_COMPONENT:
			nextState = produce(state, (draft) => {
				if (draft.originAddressComponent === 'input') {
					draft.originAddressComponent = 'maps';
				} else {
					draft.originAddressComponent = 'input';
				}
			});
			break;
		case TOGGLE_DESTINATION_ADDRESS_COMPONENT:
			nextState = produce(state, (draft) => {
				if (draft.destinationAddressComponent === 'input') {
					draft.destinationAddressComponent = 'maps';
				} else {
					draft.destinationAddressComponent = 'input';
				}
			});
			break;
		default:
			return state;
	}
	return nextState;
};

export default RateReducer;
