import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { forwardRef, Ref, ReactElement, ReactNode } from 'react';
import {
	Dialog as MuiDialog,
	DialogTitle as MuiDialogTitle,
	DialogContent as MuiDialogContent,
	IconButton,
	Typography,
	Slide
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { styles } from './styles';
import { X } from 'react-feather';

interface DialogTitleProps extends WithStyles<typeof styles> {
	children: ReactNode;
	onClose: () => void;
}

export const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: ReactElement },
	ref: Ref<unknown>
) {
	return <Slide direction='left' ref={ref} {...props} />;
});

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant='h5'>{children}</Typography>
			{onClose ? (
				<IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
					<X />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

export const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: `0 ${theme.spacing(2)}px !important`,
		backgroundColor: theme.palette.background.default
	}
}))(MuiDialogContent);

export const Dialog = withStyles(() => ({
	paperFullScreen: {
		maxWidth: '80%'
	},
	scrollPaper: {
		justifyContent: 'flex-end'
	}
}))(MuiDialog);
