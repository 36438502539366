import { Option } from '~components/Forms/Select';

// Important: The order matters
export enum Modules {
	Apps = 'Aplicaciones',
	AddressesContacts = 'Direcciones',
	AgentsUsersGroups = 'Usuarios y Grupos',
	Labels = 'Guías',
	Items = 'Empaque',
	Pickups = 'Recolecciones',
	Rates = 'Cotizaciones',
	Dispersion = 'Stock/Inventario',
	Billing = 'Facturación'
}

export enum Permissions {
	Edit = '2',
	Show = '1',
	NoAccess = '0'
}

export const numberOfPermissions = Object.keys(Modules).length;

export enum PresetType {
	Administrator = 'Administrador',
	Custom = 'Personalizado',
	Biller = 'Facturación',
	SC = 'SC'
}

export type PermissionPreset = {
	name: PresetType;
	permission: string;
};

export const presets: PermissionPreset[] = [
	{
		name: PresetType.Biller,
		permission: Object.values(Modules)
			.map((item) => (item === Modules.Billing ? Permissions.Edit : Permissions.NoAccess))
			.join('')
	},
	{
		name: PresetType.SC,
		permission: Permissions.Show.repeat(numberOfPermissions)
	},
	{
		name: PresetType.Administrator,
		permission: Permissions.Edit.repeat(numberOfPermissions)
	},
	{
		name: PresetType.Custom,
		permission: Object.values(Modules)
			.map((item) => (item === Modules.Dispersion ? Permissions.Show : Permissions.Edit))
			.join('')
	}
];

export const presetOptions = ((): Option[] => {
	return presets.map((preset) => ({
		label: preset.name,
		value: preset.permission
	}));
})();

export const permissionOptions = (module: string): Option[] => {
	return [
		{ label: 'Sin acceso', value: Permissions.NoAccess },
		{ label: 'Visualizar', value: Permissions.Show },
		{
			label:
				module === 'Guías' || module === 'Recolecciones' || module === 'Cotizaciones'
					? 'Generar'
					: module === 'Stock/Inventario'
					? 'Asignar'
					: 'Editar',
			value: Permissions.Edit
			//subtext: 'Agregar, Eliminar y Modificar'
		}
	];
};
