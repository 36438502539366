import usePagination from './usePagination';
import { ShipmentSendResponse } from '~store/actions/ActionTypes';
import { LabelsPagination } from '~util/pagination';

export default function useLabels(queryParams?: string[]) {
	const { data, total, page, loading, onPageChange, initialFetch } =
		usePagination<ShipmentSendResponse>({
			url: LabelsPagination.URL,
			limit: LabelsPagination.Table,
			param: 'page',
			queryParams
		});

	return {
		data: data,
		total: total,
		loading: loading,
		onPageChange: onPageChange,
		page: page,
		pageSize: LabelsPagination.Table,
		initialFetch
	};
}
