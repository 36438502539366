import {create} from 'zustand';
import produce from 'immer';


interface PackagesStore {
	hasOldContent: boolean;
	setHasOldContent: (hasOldContent: boolean) => void;
}

// Store to validate the contents of the package

const usePackagesStore = create<PackagesStore>((set) => ({
	hasOldContent: false,
	setHasOldContent: (hasOldContent) =>
		set((state) =>
			produce(state, (draft) => {
				draft.hasOldContent = hasOldContent;
			})
		),
}));

export default usePackagesStore;