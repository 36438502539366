import axios, { AxiosResponse } from 'axios';
import { END_POINT, END_POINT_PAYMENTS, END_POINT_RATES } from '../config';

const instance = axios.create({
	baseURL: END_POINT
});

const waitFor = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

enum PollingStatus {
	QUEUED = 'QUEUED',
	PROCESSING = 'PROCESSING',
	DONE = 'DONE'
}

const checkAndRefreshToken = (response: AxiosResponse<any>) => {
	const reqToken = response.config.headers['Authorization'];
	const resToken = response.headers['token'];

	if (resToken && !reqToken?.includes(resToken)) {
		localStorage.setItem('auth', resToken);
	}
};

instance.interceptors.response.use(async (response) => {
	// Refresh toeen if it is expired
	checkAndRefreshToken(response);

	// This means we have to do some polling logic
	if (response.status === 202) {
		const url = response.headers.location;
		let pollingResponse = await instance.get(url);

		while (pollingResponse.data.status !== PollingStatus.DONE) {
			await waitFor(5000);
			pollingResponse = await instance.get(url);
		}

		if (pollingResponse.data.status === 'Failed') {
			throw new Error('Operation failed');
		} else {
			const final = await instance.get(url);

			if (final.data.results.errors.length > 0) {
				return Promise.reject(final.data.results.errors[0]);
			}

			return {
				...final,
				data: {
					labels: final.data.results.labels
				}
			};
		}
	}

	return response;
});

export default instance;

export const instanceRate = axios.create({
	baseURL: END_POINT_RATES
});

const timeOut = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

instanceRate.interceptors.response.use(async (response) => {
	// Refresh toeen if it is expired
	checkAndRefreshToken(response);

	// This means we have to do some polling logic
	if (response.status === 202) {
		const url = response.headers.location;
		let pollingResponse = await instanceRate.get(url);

		while (pollingResponse.data.status !== PollingStatus.DONE) {
			await timeOut(5000);
			pollingResponse = await instanceRate.get(url);
		}

		if (pollingResponse.data.status === 'Failed') {
			throw new Error('Operation failed');
		} else {
			const final = await instanceRate.get(url);

			if (final.data.results.errors.length > 0) {
				return Promise.reject(final.data.results.errors[0]);
			}

			return {
				...final,
				data: {
					labels: final.data.results.labels
				}
			};
		}
	}

	return response;
});

export const instancePayment = axios.create({
	baseURL: END_POINT_PAYMENTS
});

instancePayment.interceptors.response.use(async (response) => {
	// Refresh toeen if it is expired
	checkAndRefreshToken(response);

	// This means we have to do some polling logic
	if (response.status === 202) {
		const url = response.headers.location;
		let pollingResponse = await instancePayment.get(url);

		while (pollingResponse.data.status !== PollingStatus.DONE) {
			await timeOut(5000);
			pollingResponse = await instancePayment.get(url);
		}

		if (pollingResponse.data.status === 'Failed') {
			throw new Error('Operation failed');
		} else {
			const final = await instancePayment.get(url);

			if (final.data.results.errors.length > 0) {
				return Promise.reject(final.data.results.errors[0]);
			}

			return {
				...final,
				data: {
					labels: final.data.results.labels
				}
			};
		}
	}

	return response;
});
