import { useMemo, useState, useCallback, useEffect, useContext } from 'react';
import { NewAddress, Address, AddressRequest } from '../store/actions/ActionTypes';
import { AddressesPagination } from '../util/pagination';
import axios from '~util/axios';
import { useSelector, RootStore } from '~store/store';
import DrawContext from '~context/DrawContext';
import usePermissions from './usePermissions';

export type SortBy = 'created_at';
export type SortOrder = 'desc';

type AddressParams = {
	page: number;
	limit: number;

	sort_by: SortBy;
	sort_order: SortOrder;
	user_id?: number;
	query?: string;
};

type Data = {
	rows: Array<Address>;
	total: number;
	loading: boolean;
	params: AddressParams;
};

type Props = {
	isRootOrAgent: boolean;
};

export default function useInputSearchAddreses({ isRootOrAgent }: Props) {
	const [data, setData] = useState<Data>({
		rows: [],
		total: 0,
		loading: true,
		params: {
			page: 0,
			limit: 20,

			sort_order: 'desc',
			sort_by: 'created_at',
			user_id: undefined,
			query: undefined
		}
	});
	const [simpleData, setSimpleData] = useState<SimpleAddress[]>();

	const { currentFlow } = useSelector((state: RootStore) => state.flow);

	const { setNotification } = useContext(DrawContext);
	const { can } = usePermissions();
	const canHaveActions = can('canRead.addresses');

	const updateData = useCallback(
		<T extends keyof Data>(key: T, value: Data[T]) =>
			setData((prevState) => ({
				...prevState,
				[key]: value
			})),
		[setData]
	);

	const updateParam = useCallback(
		<T extends keyof AddressParams>(key: T, value: AddressParams[T]) =>
			setData((prevState) => ({
				...prevState,
				params: {
					...prevState.params,
					[key]: value
				}
			})),
		[setData]
	);

	const fetch = useCallback(async () => {
		let isMounted = true;
		updateData('loading', true);

		if (isRootOrAgent && data.params.user_id === undefined) {
			updateData('loading', false);
			return;
		}

		if (data.params.query) {
			data.params.limit = 1_000;
		}

		const params = JSON.parse(JSON.stringify(data.params));
		const asURLSearchParams = new URLSearchParams(params);
		asURLSearchParams.set('page', params.page + 1);
		const paramsAsString = asURLSearchParams.toString();

		if (currentFlow === 'create') {
			const addresses: SimpleAddress[] = [];
			try {
				const response = await axios.get(
					`/api/addresses?${paramsAsString}&origin_request=label_manual`
				);
				const data = response.data;
				if (isMounted) {
					data.data.map((addr: { address: SimpleAddress }) => {
						return addresses.push(addr.address);
					});
					setSimpleData(addresses);
					setData((prevState) => ({
						...prevState,
						loading: false,
						rows: data.data.map((a: SimpleAddress) => AddressesPagination.Transformer(a)),
						total: data.total
					}));
				}
			} catch (error) {
				setNotification({
					message: 'Algo salió mal, por favor contacta al equipo de Encamino',
					severity: 'error'
				});
			}
		} else if (canHaveActions) {
			try {
				const response = await axios.get(`/api/addresses?${paramsAsString}`);
				const data = response.data;
				setData((prevState) => ({
					...prevState,
					loading: false,
					rows: data.data.map((a: AddressRequest) => AddressesPagination.Transformer(a)),
					total: data.total
				}));
			} catch (error) {
				setNotification({
					message: 'Algo salió mal, por favor contacta al equipo de Encamino',
					severity: 'error'
				});
			}
		}

		return () => {
			isMounted = false;
		};
	}, [updateData, isRootOrAgent, data.params, currentFlow, setNotification, canHaveActions]);

	const temporalAddresses = useSelector((state) => state.data.addresses);

	const sortedData = useMemo(() => {
		let sortData = [...temporalAddresses, ...(data.rows || [])];

		sortData.sort((a: NewAddress, b: NewAddress) => {
			if (!a.scope) {
				return -1;
			}

			if (!b.scope) {
				return -1;
			}

			const scopeA = a.scope.toLowerCase(),
				scopeB = b.scope.toLowerCase();

			// ascending
			if (scopeA < scopeB) return -1;
			if (scopeA > scopeB) return 1;
			// no sorting
			return 0;
		});
		return sortData;
	}, [temporalAddresses, data.rows]);

	useEffect(() => {
		fetch();
	}, [fetch]);

	return {
		data: sortedData,
		loading: data.loading,
		updateParam,
		fetch,
		params: data.params,
		updateParams: setData,
		simpleData
	};
}
