function appendOrReplaceValueInUrlParams(query: URLSearchParams, key: string, value: string | number) {
	if (!query.has(key)) {
		query.set(key, value.toString());
		return query.toString();
	}

	for (let [it] of query) {
		if (key === it) {
			query.set(key, value.toString());
			break;
		}
	}

	return query.toString();
}

export { appendOrReplaceValueInUrlParams };
