import React, {
	useContext,
	useEffect,
	useRef,
	useState,
	SetStateAction,
	Dispatch,
	useMemo
} from 'react';
import { useHistory } from 'react-router';
import { GridColDef } from '@mui/x-data-grid';
import {
	CircularProgress,
	createStyles,
	FormControl,
	Grid,
	InputLabel,
	makeStyles,
	Select,
	Theme,
	Tooltip,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';
import ShipmentDetailsModal from '../../Modals/ShipmentDetailsModal/ShipmentDetailsModal';
import { ShipmentSendResponse } from '../../../store/actions/ActionTypes';
import CarrierService from '../CarrierService';
import AddressCell from '../_AddressCell';
import TableControls from '../_TableControls';
import StatusCell from '../_StatusCell';
import { HeadingOne } from '~components/Headings';

import DataGrid from '~components/Tables/DataGrid';
import useLabels from '~hooks/useLabels';
import { useCardStyles } from '~hooks/useStyles';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '~store/store';
import {
	addLabelPickup,
	resetLabelsPickup,
	setCarrierPickUp,
	setLabelsPickup
} from '~store/actions';
import { getCarrierName } from '~util/logos';
import { FollowUpLabelStatus } from '~util/cellStatusColor';
import useQuery from '~hooks/useQuery';
import AuthContext from '~context/AuthContext';
import usePermissions from '~hooks/usePermissions';
//styles
import '../../Tables/DataGrid.css';
import { GridSelectionModel } from '@mui/x-data-grid';

const getColumnsProps = ({
	hideColumns,
	hideDate,
	serviceDescription,
	getServiceDescription,
	fromOrderSummary
}: {
	hideColumns: boolean;
	hideDate?: boolean;
	serviceDescription?: string;
	getServiceDescription?: (serviceId: string) => string | null;
	fromOrderSummary?: boolean;
}): GridColDef[] => {
	const columns: GridColDef[] = [
		{
			field: 'service',
			headerName: 'Servicio',
			flex: 1,
			renderCell: (params) => {
				const serviceCellDescription = !!params.row.service_description
					? params.row.service_description
					: !!serviceDescription
					? serviceDescription
					: !hideDate && getServiceDescription
					? getServiceDescription(params.row.service_id)
					: '';
				return (
					<Tooltip title={serviceCellDescription} disableTouchListener>
						<strong>{serviceCellDescription}</strong>
					</Tooltip>
				);
			},
			valueGetter: (params) => {
				return params.row.service_description;
			}
		}
	];

	if (!hideDate && fromOrderSummary) {
		columns.push({
			field: 'created_at',
			headerName: 'Fecha de creación',
			flex: 1,
			renderCell: (params) => {
				return <p>{new Date(params.row.created_at).toLocaleString('es')}</p>;
			}
		});
	}
	columns.push({
		field: 'parcel_number',
		headerName: 'No. Guía',
		flex: 1.25
	});
	columns.push({
		field: 'origin',
		headerName: 'Origen',
		flex: 1,
		renderCell: (params) => {
			return <AddressCell address={params.row.origin} />;
		},
		valueGetter: (params) => {
			return JSON.stringify(params.row.origin);
		}
	});

	columns.push({
		field: 'destination',
		headerName: 'Destino',
		flex: 1,
		renderCell: (params) => {
			return <AddressCell address={params.row.destination} />;
		},
		valueGetter: (params) => {
			return JSON.stringify(params.row.destination);
		}
	});

	if (!hideColumns) {
		columns.unshift({
			field: 'carrier',
			headerName: 'Transportista',
			width: 140,
			renderCell: (params) => {
				return <CarrierService carrier={params.row.carrier} />;
			}
		});
	}

	if (!hideColumns) {
		columns.push({
			field: 'status',
			headerName: 'Estatus',
			flex: 1,
			renderCell: (params) => {
				return <StatusCell status={params.row.follow_up} />;
			}
		});
	}

	return columns;
};
// TODO use the Actions Columns
// {
// 	field: 'actions',
// 	headerName: 'Acciones',
// 	width: 120,
// 	sortable: false,
// 	disableClickEventBubbling: true,
// 	renderCell: (params: GridCellParams) => {
// 		// const onClick = () => {
// 		// 	const api: GridApi = params.api;
// 		// 	const fields = api
// 		// 		.getAllColumns()
// 		// 		.map((c) => c.field)
// 		// 		.filter((c) => c !== '__check__' && !!c);
// 		// 	const thisRow = {};

// 		// 	fields.forEach((f) => {
// 		// 		thisRow[f] = params.getValue(f);
// 		// 	});

// 		// 	return alert(JSON.stringify(thisRow, null, 4));
// 		// };
// 		//onClick={onClick}

// 		// return <Button onClick={() => console.log(params)}>...</Button>;
// 		return <MenuActionButton />;
// 	},
// },

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
			'&>*': {
				marginTop: theme.spacing(1)
			}
		},
		topSide: {
			'&>*': {
				marginLeft: theme.spacing(2.4)
			}
		},
		errorMessage: {
			color: '#f44336'
		}
	})
);

interface Props {
	isPickingUp?: boolean;
	isSummaryPickUp?: boolean;
	createdLabels?: ShipmentSendResponse[];
	fromOrderSummary?: boolean;
	isEdittingPickup?: boolean;
	setSelectionModelPickUp?: Dispatch<SetStateAction<GridSelectionModel>>;
	selectionModelPickUp?: GridSelectionModel;
}

/**
 * Table for shipments (Lables)
 */
const TableShipmentSend: React.FC<Props> = ({
	isPickingUp,
	isSummaryPickUp,
	setSelectionModelPickUp,
	createdLabels,
	fromOrderSummary,
	isEdittingPickup,
	selectionModelPickUp
}) => {
	const classes = useStyles();
	const { cardClasses } = useCardStyles();
	const werePreselectedLabelsAdded = useRef<boolean>(false);

	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

	const query = useQuery();
	const history = useHistory();
	const dispatch = useDispatch();
	const { isRootOrAgent } = usePermissions();
	const [parcelNumbers] = useState<string[]>((query.get('parcel_numbers') || '').split(','));

	const { isTouchedPickup, labelsPickup, carrierPickup, addressPickUp } = useSelector(
		(state: RootStore) => state.pickup
	);
	const { simulatedUser, simulatedCustomer } = useSelector(
		(state: RootStore) => state.simulatedUser
	);

	const paramsAccordingToFlow = useMemo(
		() =>
			isPickingUp || isSummaryPickUp
				? isRootOrAgent
					? ['user_id', 'carrier', 'for_pickup']
					: ['carrier', 'for_pickup']
				: [],
		[isPickingUp, isSummaryPickUp, isRootOrAgent]
	);
	const { data, loading, total, onPageChange, page, pageSize, initialFetch } =
		useLabels(paramsAccordingToFlow);

	const [showDetails, setShowDetails] = useState(false);
	const [SSR, setSSR] = useState<ShipmentSendResponse | null>(null);
	const [currentRows, setCurrentRows] = useState<ShipmentSendResponse[]>([]);
	const [currentCarriers, setCurrentCarriers] = useState<string[]>([]);

	const { currentUser } = useContext(AuthContext);
	const { serviceSend, currentPackagesSend } = useSelector((state: RootStore) => state.send);

	const getServiceDescription = (serviceId: string) => {
		const services = isRootOrAgent ? simulatedUser?.services : currentUser?.services;
		return (
			services?.find((item) =>
				typeof item.id === 'number' ? item.id.toString() === serviceId : item.id === serviceId
			)?.service ?? null
		);
	};

	// Set data
	useEffect(() => {
		let isMounted = true;
		if (data) {
			let toSet: ShipmentSendResponse[] = [];

			// Make sure we're filtering base on the right follow_up status
			if (labelsPickup.length > 0) {
				const [label] = labelsPickup;
				toSet = data.filter(
					(d) =>
						d.follow_up === FollowUpLabelStatus.REGISTERED && d.origin.state === label.origin.state
				);
			} else {
				toSet = data.filter((d) => d.follow_up === FollowUpLabelStatus.REGISTERED);
			}

			if (addressPickUp) {
				toSet = toSet.filter((d) => d.origin.state === addressPickUp.state);
			}

			if (isMounted) {
				setCurrentRows(toSet);
			}
		}
		return () => {
			isMounted = false;
		};
	}, [data, labelsPickup, addressPickUp]);

	useEffect(() => {
		if (!werePreselectedLabelsAdded.current && parcelNumbers.length && currentRows.length > 0) {
			werePreselectedLabelsAdded.current = true;
			for (const label of currentRows) {
				if (parcelNumbers.includes(label.parcel_number)) {
					dispatch(addLabelPickup(label));
				}
			}
		}
	}, [werePreselectedLabelsAdded, currentRows, parcelNumbers, dispatch]);

	// Set unique carriers
	useEffect(() => {
		if (isPickingUp) {
			let carriers: string[] = [];
			let services: any[] = [];

			if (isRootOrAgent) {
				services = !simulatedUser ? [] : simulatedUser.services;
			} else {
				services = currentUser.services;
			}
			carriers = services?.map((s) => s.carrier);
			if (carriers) carriers = ['', ...carriers];
			setCurrentCarriers([...new Set(carriers)]);
		}
	}, [isPickingUp, simulatedUser, currentUser, isRootOrAgent]);

	useEffect(() => {
		const param = 'for_pickup';
		// We get the query parameters from the current URL in the browser
		const keys = Array.from(query.keys());

		// This case covers for example when go to a page without specifying the pagination param
		// For example if we go to: /paquetes
		if (!keys.includes(param)) {
			keys.push(param);
		} else {
			return;
		}

		if (!keys.includes('user_id') && simulatedUser?.id && isRootOrAgent) {
			keys.push('user_id');
		}

		let url = keys
			.map((key) => {
				// If current key matches the one for this paginator, then we modify it
				if (key === param) {
					// DataGrid works with based-0 index for the pagination, that's why we add 1
					return `${key}=true`;
				}
				if (key === 'user_id' && isRootOrAgent) {
					return `${key}=${simulatedUser?.id}`;
				}
				// If there is other params, we leave as they are
				return `${key}=${query.get(key)}`;
			})
			.join('&');

		// Replace the current url to cause the fetch of the given module
		history.replace(`${history.location.pathname}?${url}`);
	}, [query, history, simulatedUser?.id, isRootOrAgent]);

	// FIXME
	//https://stackoverflow.com/questions/66158526/programmatically-preselecting-a-row-in-material-uis-data-gridreact
	// Since I can not preselect the packages it will be reseted every time
	// Only if is not in the summary
	useEffect(() => {
		if (isPickingUp) {
			dispatch(resetLabelsPickup());
			dispatch(setCarrierPickUp(query.get('carrier') || ''));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, isPickingUp]);

	// Clear selected labels when carrier changes
	useEffect(() => {
		if (isPickingUp) {
			dispatch(resetLabelsPickup());
		}
	}, [carrierPickup, dispatch, isPickingUp]);

	useEffect(() => {
		if (!simulatedCustomer && isRootOrAgent) {
			setCurrentRows([]);
			dispatch(setCarrierPickUp(''));
		}
	}, [setCurrentRows, simulatedCustomer, dispatch, isRootOrAgent]);

	useEffect(() => {
		if (isSummaryPickUp && fromOrderSummary && !!createdLabels?.length) {
			const labelsList: ShipmentSendResponse[] = [];
			createdLabels.forEach((item) => {
				labelsList.push({ ...item, packages: currentPackagesSend });
			});
			dispatch(setLabelsPickup(labelsList));
		}
	}, [isSummaryPickUp, fromOrderSummary, createdLabels, currentPackagesSend, dispatch]);

	return (
		<Grid container className={clsx([classes.root, cardClasses.card])}>
			<Grid className={classes.topSide} style={{ marginTop: 0 }} container>
				<Grid item xs={2} style={{ height: '100%' }} container alignItems='center'>
					{!isEdittingPickup && (
						<HeadingOne text={isPickingUp ? 'Guías' : isSummaryPickUp ? '' : 'Envíos'} />
					)}
				</Grid>
				{/* Espacio para un filtro */}
				{/* FIXME Get on change */}
				{isPickingUp && (
					<Grid item xs={9}>
						<Grid container direction='row' alignItems='center'>
							<Grid item xs={6}>
								<p style={{ marginTop: 0 }}>Seleccione un transportista</p>
								<FormControl variant='filled' style={{ width: '90%' }}>
									<InputLabel>Transportista</InputLabel>
									<Select
										native
										fullWidth
										style={{ minWidth: 200 }}
										value={carrierPickup}
										onChange={(e) => {
											dispatch(setCarrierPickUp(e.target.value as string));
											// TODO refactor this function where the keys are recycled
											const keys = Array.from(query.keys());

											// This case covers for example when go to a page without specifying the pagination param
											// For example if we go to: /paquetes
											if (!keys.includes('carrier')) {
												keys.push('carrier');
												keys.push('status');
											}

											let url = keys
												.map((key) => {
													// If current key matches the one for this paginator, then we modify it
													if (key === 'carrier') {
														// DataGrid works with based-0 index for the pagination, that's why we add 1
														return `carrier=${e.target.value as string}`;
													}

													if (key === 'status') {
														return 'followup=Registrado';
													}

													if (key === 'user_id') {
														return `user_id=${simulatedUser?.id}`;
													}

													// If there is other params, we leave as they are
													return `${key}=${query.get(key)}`;
												})
												.join('&');

											// Replace the current url to cause the fetch of the given module
											history.replace(`${history.location.pathname}?${url}`);
										}}
									>
										{currentCarriers.map((option: string) => (
											<option value={option} key={option}>
												{getCarrierName(option)}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<Typography variant='body2' style={{ opacity: '0.5' }}>
									Horarios de recolección sujetos a términos y condiciones de servicio de cada
									transportista. El equipo operativo atenderá su solicitud y se le notificará vía
									correo electrónico cuando quede concretada.
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				)}
				{/* Search and New */}
				{/* TODO filter with the currents filtered by the data grid */}
				<Grid item xs={5}>
					{!isPickingUp && (
						<TableControls
							{...(isSummaryPickUp || isEdittingPickup
								? {}
								: {
										permission: 'canCreateUpdateDelete.label'
								  })}
							linkToAdd='/enviar'
							rows={data || []}
							setFilteredRows={
								isPickingUp || isSummaryPickUp ? undefined : (data) => setCurrentRows(data)
							}
						/>
					)}
				</Grid>
			</Grid>

			<ShipmentDetailsModal
				data={SSR}
				isOpen={showDetails}
				handleOpen={(status) => setShowDetails(status)}
			/>

			{/* Error message */}
			{isTouchedPickup && isPickingUp && selectionModel.length === 0 && (
				<Grid className={classes.topSide} container alignItems='center' item xs={4}>
					<p className={classes.errorMessage}>Por favor elija al menos un paquete</p>
				</Grid>
			)}

			<Grid item xs={12}>
				{isPickingUp && carrierPickup !== '' && (
					<DataGrid
						rowHeight={90}
						columns={getColumnsProps({ hideColumns: true })}
						rows={
							initialFetch
								? []
								: !!simulatedUser
								? currentRows.filter((item) => item.user?.id === simulatedUser?.id)
								: currentRows
						}
						getRowId={(row) => row.parcel_number}
						autoHeight
						disableSelectionOnClick
						disableDensitySelector
						checkboxSelection
						disableColumnFilter
						onSelectionModelChange={(newSelectionModel) => {
							setSelectionModel(newSelectionModel);
							setSelectionModelPickUp && setSelectionModelPickUp(newSelectionModel);
						}}
						selectionModel={selectionModel}
						filterModel={{
							items: [
								{
									columnField: 'carrier',
									operatorValue: 'equals',
									value: carrierPickup
								}
							]
						}}
						onRowClick={(p) => {
							setSSR(p.row as ShipmentSendResponse);
							setShowDetails(true);
						}}
						paginationMode='server'
						page={loading ? 0 : page}
						rowCount={loading ? 0 : currentRows.length}
						pageSize={pageSize}
						onPageChange={onPageChange}
						loading={loading}
						rowsPerPageOptions={[pageSize]}
					/>
				)}
				{isEdittingPickup && (
					<DataGrid
						rowHeight={90}
						columns={getColumnsProps({ hideColumns: true })}
						rows={
							initialFetch
								? []
								: !!simulatedUser
								? currentRows.filter((item) => item.user?.id === simulatedUser?.id)
								: currentRows
						}
						getRowId={(row) => row.parcel_number}
						autoHeight
						disableSelectionOnClick
						disableDensitySelector
						checkboxSelection
						disableColumnFilter
						onSelectionModelChange={(newSelectionModel) => {
							if (!setSelectionModelPickUp || !newSelectionModel.length) return;
							setSelectionModelPickUp(newSelectionModel);
						}}
						selectionModel={selectionModelPickUp}
						paginationMode='server'
						page={loading ? 0 : page}
						rowCount={loading ? 0 : currentRows.length}
						pageSize={pageSize}
						onPageChange={onPageChange}
						loading={loading}
						rowsPerPageOptions={[pageSize]}
					/>
				)}
				{isSummaryPickUp ? (
					labelsPickup.length > 0 ? (
						<DataGrid
							rowHeight={90}
							columns={getColumnsProps({
								hideColumns: true,
								hideDate: fromOrderSummary,
								serviceDescription: serviceSend?.service_description,
								getServiceDescription,
								fromOrderSummary
							})}
							autoHeight
							disableSelectionOnClick
							getRowId={(row) => row.parcel_number}
							pageSize={10}
							rows={labelsPickup ?? []}
							loading={loading}
						/>
					) : (
						<Grid container alignItems='center' justifyContent='center'>
							<CircularProgress />
						</Grid>
					)
				) : null}
				{!isPickingUp && !isSummaryPickUp && !isEdittingPickup && (
					<DataGrid
						rowHeight={90}
						columns={getColumnsProps({ hideColumns: false })}
						autoHeight
						disableSelectionOnClick
						onRowClick={(p) => {
							setSSR(p.row as ShipmentSendResponse);
							setShowDetails(true);
						}}
						getRowId={(row) => row.parcel_number}
						paginationMode='server'
						page={loading ? 0 : page}
						rowCount={loading ? 0 : total}
						rows={initialFetch ? [] : currentRows}
						pageSize={pageSize}
						onPageChange={onPageChange}
						loading={loading}
						rowsPerPageOptions={[pageSize]}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default TableShipmentSend;
