import produce from 'immer';
import {
	BookletServiceForm,
	// NewAddress,
	//AddressRequest,
	CreateOrUpdateCustomerActions,
	// RESET_CUCUSTOMER,
	// SET_ADDRESS_INFO_DRAFT_CUCUSTOMER,
	// SET_GENERAL_INFO_DRAFT_CUCUSTOMER,
	// SET_GENERAL_INFO_VALID_CUCUSTOMER,
	// SET_LEGAL_INFO_DRAFT_CUCUSTOMER,
	// SET_LEGAL_INFO_VALID_CUCUSTOMER,
	SET_SERVICES_CUCUSTOMER,
	// SET_TOUCHED_ADDRESS_CUCUSTOMER,
	// SET_EDITING_ADDRESS_CUCUSTOMER,
} from '~store/actions/ActionTypes';

// type CUCustomerState = {
// 	generalInfoDraftCUCustomer: any | null;
// 	legalInfoDraftCUCustomer: any | null;
// 	addressCUCustomer: NewAddress | null;
// 	isTouchedCUCustomer: boolean;
// 	servicesInfoDraftCUCustomer: BookletServiceForm[];
// 	idCustomerEditingCUCustomer: number;
// 	idUserEditingCUCustomer: number;

// 	// For validations
// 	generalInfoIsValidCUCustomer: boolean;
// 	legalInfoIsValidCUCustomer: boolean;
// };

type CUCustomerState = {
	servicesInfoDraftCUCustomer: BookletServiceForm[];
};

const initialState: CUCustomerState = {
	servicesInfoDraftCUCustomer: [],
};

// const initialState: CUCustomerState = {
// 	generalInfoDraftCUCustomer: null,
// 	legalInfoDraftCUCustomer: null,
// 	servicesInfoDraftCUCustomer: [],
// 	addressCUCustomer: null,
// 	isTouchedCUCustomer: false,
// 	idCustomerEditingCUCustomer: 0,
// 	idUserEditingCUCustomer: 0,
// 	generalInfoIsValidCUCustomer: false,
// 	legalInfoIsValidCUCustomer: false,
// };

const createOrCustomerReducer = (
	state: CUCustomerState = initialState,
	action: CreateOrUpdateCustomerActions
): CUCustomerState => {
	let nextState: CUCustomerState;
	switch (action.type) {
// 		case RESET_CUCUSTOMER:
// 			return initialState;
// 		case SET_ADDRESS_INFO_DRAFT_CUCUSTOMER:
// 			nextState = produce(state, (draft) => {
// 				draft.addressCUCustomer = action.address;
// 			});
// 			break;
// 		case SET_TOUCHED_ADDRESS_CUCUSTOMER:
// 			nextState = produce(state, (draft) => {
// 				draft.isTouchedCUCustomer = action.touched;
// 			});
// 			break;
// 		case SET_GENERAL_INFO_DRAFT_CUCUSTOMER:
// 			nextState = produce(state, (draft) => {
// 				draft.generalInfoDraftCUCustomer = action.form;
// 			});
// 			break;
// 		case SET_GENERAL_INFO_VALID_CUCUSTOMER:
// 			nextState = produce(state, (draft) => {
// 				draft.generalInfoIsValidCUCustomer = action.valid;
// 			});
// 			break;
// 		case SET_LEGAL_INFO_DRAFT_CUCUSTOMER:
// 			nextState = produce(state, (draft) => {
// 				draft.legalInfoDraftCUCustomer = action.form;
// 			});
// 			break;
// 		case SET_LEGAL_INFO_VALID_CUCUSTOMER:
// 			nextState = produce(state, (draft) => {
// 				draft.legalInfoIsValidCUCustomer = action.valid;
// 			});
// 			break;
		case SET_SERVICES_CUCUSTOMER:
			nextState = produce(state, (draft) => {
				draft.servicesInfoDraftCUCustomer = action.services;
			});
			break;
// 		case SET_EDITING_ADDRESS_CUCUSTOMER:
// 			nextState = produce(state, (draft) => {
// 				draft.idCustomerEditingCUCustomer = action.idCustomer;
// 				draft.idUserEditingCUCustomer = action.idUser;
// 			});
// 			break;
		default:
			return state;
	}
	return nextState;
};

export default createOrCustomerReducer;
