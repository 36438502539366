import { create } from 'zustand';
import produce from 'immer';
import { NewBusiness, User } from './actions/ActionTypes';

export type PreinvoiceBusiness = {
	cfdi_use: string;
	payment_method: string;
	payment_type: string;
	tax_regimen: string;
	order?: string;
};

type WalletData = {
	walletDialog: boolean;
	toggleWalletDialog: () => void;

	currentBusiness: NewBusiness[] | null;
	setCurrentBusiness: (currentBusiness: NewBusiness[] | null) => void;
	isEditing: boolean;
	setIsEditing: (isEditing: boolean) => void;
	isPreinvoicesEditing: boolean;
	setIsPreinvoicesEditing: (isPreinvoicesEditing: boolean) => void;
	currentBusinessEditing: NewBusiness | null;
	setCurrentBusinessEditing: (currentBusinessItem: NewBusiness) => void;
	preinvoicePaymentData: PreinvoiceBusiness | null;
	setPreinvoicePaymentData: (preinvoice: PreinvoiceBusiness | null) => void;
	users: User[] | null;
	setUsers: (users: User[]) => void;
	reportFolioForRebill: string | null;
	setReportFolioForRebill: (reportFolio: string | null) => void;
	isRegisteringPayment: boolean;
	setIsRegisteringPayment: (isRegisteringPayment: boolean) => void;
	reset: () => void;
};

const useWalletStore = create<WalletData>((set) => ({
	walletDialog: false,
	toggleWalletDialog: () => set((state) => ({ walletDialog: !state.walletDialog })),
	currentBusiness: null,
	setCurrentBusiness: (currentBusiness: NewBusiness[] | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.currentBusiness = currentBusiness;
			})
		),
	isEditing: false,
	setIsEditing: (isEditing: boolean) =>
		set((state) =>
			produce(state, (draft) => {
				draft.isEditing = isEditing;
			})
		),
	isPreinvoicesEditing: false,
	setIsPreinvoicesEditing: (isPreinvoicesEditing: boolean) =>
		set((state) =>
			produce(state, (draft) => {
				draft.isPreinvoicesEditing = isPreinvoicesEditing;
			})
		),
	currentBusinessEditing: null,
	setCurrentBusinessEditing: (currentBusinessItem: NewBusiness) =>
		set((state) =>
			produce(state, (draft) => {
				draft.currentBusinessEditing = currentBusinessItem;
			})
		),
	preinvoicePaymentData: null,
	setPreinvoicePaymentData: (preinvoice: PreinvoiceBusiness | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.preinvoicePaymentData = preinvoice;
			})
		),
	users: null,
	setUsers: (users: User[] | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.users = users;
			})
		),
	reportFolioForRebill: null,
	setReportFolioForRebill: (reportFolio: string | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.reportFolioForRebill = reportFolio;
			})
		),
	isRegisteringPayment: false,
	setIsRegisteringPayment: (isRegisteringPayment: boolean) =>
		set((state) =>
			produce(state, (draft) => {
				draft.isRegisteringPayment = isRegisteringPayment;
			})
		),
	reset: () =>
		set((state) =>
			produce(state, (draft) => {
				draft.isEditing = false;
				draft.isPreinvoicesEditing = false;
				draft.currentBusinessEditing = null;
				draft.preinvoicePaymentData = null;
				draft.users = null;
				draft.reportFolioForRebill = null;
				draft.isRegisteringPayment = false;
			})
		)
}));

export default useWalletStore;
