import React, { useCallback, useState } from 'react';
import DrawContext, { rdrawstatus, SnackBarMessage } from './DrawContext';
import AuthContext, { PermissionState } from './AuthContext';
import { UserDetails, UserRole } from '../store/actions/ActionTypes';
import { numberOfPermissions } from '~components/Forms/Permissions/helpers';

const defaultPermissions: PermissionState = {
	canRead: {
		app: true,
		addresses: false,
		packages: false,
		users: false,
		client: false,
		groups: false,
		rate: false,
		label: false,
		pickup: false,
		dispersion: false,
		disperse: false,
		business: false,
		billing: false
	},
	canCreateUpdateDelete: {
		address: false,
		package: false,
		user: false,
		client: false,
		group: false,
		rate: false,
		label: false,
		pickup: false,
		dispersion: false,
		disperse: false,
		business: false,
		billing: false
	}
};

const defaultUser: UserDetails = {
	id: 0,
	customer: null,
	default_address: null,
	email: '',
	group: null,
	name: '',
	options: {
		rate_price: false,
		theme: false
	},
	permissions: '',
	phone_number: null,
	role: UserRole.CLIENT,
	services: []
};

const GlobalContext: React.FC<{ children: React.ReactNode }> = (props) => {
	//DrawContext
	const [mobileLeftDrawerStatus, setMobileLeftDrawerStatus] = useState(false);
	const [mobileRigthDrawerStatus, setMobileRigthDrawerStatus] = useState(false);
	const [helpDialogStatus, setHelpDialogStatus] = useState(false);
	const [rCardStatus, setRCardStatus] = useState<rdrawstatus>('INITIAL');
	const [notification, setNotification] = useState<SnackBarMessage>({ message: '' });
	const [showNotification, setShowNotification] = useState<boolean>(false);
	const [showBarLogo, setShowBarLogo] = useState(true);
	const [availableBalance, setAvailableBalance] = useState<number | null>(0);

	//AuthContext
	const [isAuth, setIsAuth] = useState(false);
	const [isCheckingIsAuth, setIsCheckingIsAuth] = useState(true);

	const [currentUser, setCurrentUser] = useState<UserDetails>(defaultUser);

	const [currentPermissions, setCurrentPermissions] = useState<PermissionState>(defaultPermissions);

	const updateUser = useCallback((user: UserDetails) => {
		setCurrentUser(user);

		const permissions = user.permissions + '0'.repeat(numberOfPermissions - numberOfPermissions);

		const nextPermissions: PermissionState = {
			canRead: {
				app: true,
				addresses: true,
				packages: true,
				users: true,
				groups: true,
				client: true,

				rate: true,
				label: true,
				pickup: true,
				dispersion: true,
				disperse: true,

				business: true,
				billing: true
			},
			canCreateUpdateDelete: {
				address: true,
				package: true,
				user: true,
				group: true,
				client: true,

				rate: true,
				label: true,
				pickup: true,
				dispersion: true,
				disperse: true,

				business: true,
				billing: true
			}
		};

		if (user.role !== UserRole.ROOT) {
			// Can READ
			nextPermissions.canRead.addresses = +permissions[1] >= 1;
			nextPermissions.canRead.groups = user.role === UserRole.AGENT ? true : +permissions[2] >= 1;
			nextPermissions.canRead.users = user.role === UserRole.AGENT ? true : +permissions[2] >= 1;
			nextPermissions.canRead.client = user.role !== UserRole.CLIENT;
			nextPermissions.canRead.label = +permissions[3] >= 1;
			nextPermissions.canRead.packages = +permissions[4] >= 1;
			nextPermissions.canRead.pickup = +permissions[5] >= 1;
			nextPermissions.canRead.rate = +permissions[6] >= 1;
			// TODO: NOT USED
			nextPermissions.canRead.disperse = user.role === UserRole.CUSTOMER;
			nextPermissions.canRead.dispersion =
				user.role === UserRole.AGENT || user.role === UserRole.CUSTOMER
					? true
					: +permissions[7] >= 1;
			nextPermissions.canRead.business = +permissions[8] >= 1;
			nextPermissions.canRead.billing = +permissions[8] >= 1;

			// Can CREATE/UPDATE/DELETE
			nextPermissions.canCreateUpdateDelete.address = +permissions[1] > 1;
			nextPermissions.canCreateUpdateDelete.group =
				user.role === UserRole.AGENT ? true : +permissions[2] > 1;
			nextPermissions.canCreateUpdateDelete.user =
				user.role === UserRole.AGENT ? true : +permissions[2] > 1;
			nextPermissions.canCreateUpdateDelete.client = user.role !== UserRole.CLIENT;
			nextPermissions.canCreateUpdateDelete.label = +permissions[3] > 1;
			nextPermissions.canCreateUpdateDelete.package = +permissions[4] > 1;
			nextPermissions.canCreateUpdateDelete.pickup = +permissions[5] > 1;
			nextPermissions.canCreateUpdateDelete.rate = +permissions[6] > 1;
			// TODO: NOT USED
			nextPermissions.canCreateUpdateDelete.disperse =
				user.role === UserRole.CUSTOMER ? true : +permissions[7] > 1;
			nextPermissions.canCreateUpdateDelete.dispersion = user.role === UserRole.AGENT;
			nextPermissions.canCreateUpdateDelete.business = +permissions[8] > 1;
			nextPermissions.canCreateUpdateDelete.billing = +permissions[8] > 1;
		} else {
			nextPermissions.canRead.groups = true;
			nextPermissions.canRead.users = true;
		}
		setCurrentPermissions(nextPermissions);
	}, []);

	return (
		<AuthContext.Provider
			value={{
				isAuth,
				setAuth: (auth: boolean) => setIsAuth(auth),
				isCheckingIsAuth,
				setIsCheckingIsAuth,
				currentUser: currentUser,
				setCurrentUser: updateUser,
				currentPermissions,
				clearUser: () => {
					setCurrentUser({
						id: 0,
						customer: null,
						default_address: null,
						email: '',
						group: null,
						name: '',
						options: {
							rate_price: false,
							theme: false
						},
						permissions: '',
						phone_number: null,
						role: UserRole.CLIENT,
						services: []
					});
					setCurrentPermissions(defaultPermissions);
					setAvailableBalance(null);
				}
			}}
		>
			<DrawContext.Provider
				value={{
					mobileLeftDrawerStatus,
					toggleLeftDrawer: () => setMobileLeftDrawerStatus((prev) => !prev),
					mobileRigthDrawerStatus,
					toggleRightDrawer: () => setMobileRigthDrawerStatus((prev) => !prev),
					helpDialogStatus,
					toggleHelpDialogStatus: () => setHelpDialogStatus((prev) => !prev),
					rCardStatus,
					setRCardStatus,
					notification,
					setNotification,
					showNotification,
					setShowNotification,
					showBarLogo,
					toggleBarLogo: (a: boolean) => setShowBarLogo(a),
					availableBalance,
					setAvailableBalance
				}}
			>
				{props.children}
			</DrawContext.Provider>

			{/* <WalletContext.Provider
				value={{
					walletDialog,
					toggleWalletDialog: () => setWalletDialog((prev) => !prev)
				}}
			>
				{props.children}
			</WalletContext.Provider> */}
		</AuthContext.Provider>
	);
};

export default GlobalContext;
