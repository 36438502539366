import produce from 'immer';
import {
	ADD_PACKAGE_SEND,
	ADD_MERCHANDISE_PACKAGE_SEND,
	AddressRequest,
	CAN_CONTINUE_TO_RATE_SEND,
	CAN_CONTINUE_TO_SUMMARY_SEND,
	Contact,
	Package,
	REMOVE_PACKAGE_SEND,
	RESET_SEND,
	RESET_SERVICE_SEND,
	SendActions,
	ServiceRequest,
	SET_ADDRESS_QUERY,
	SET_BATCH_ID,
	SET_DESTINATION_ADDRESS_SEND,
	SET_DESTINATION_CONTACT_SEND,
	SET_ESTAFETA_OUTPUT_TYPE,
	SET_EXTRA_INFORMATION,
	SET_FORM_PACKAGES_VALID_SEND,
	SET_FORMATTED_ADDRESS,
	SET_INSURANCE_ON_SUMMARY,
	SET_ORIGIN_ADDRESS_SEND,
	SET_ORIGIN_CONTACT_SEND,
	SET_RATE_ID,
	SET_SAVE_ESTAFETA_OUTPUT_TYPE,
	SET_SERVICE_SEND,
	SWIPE_ADDRESS_SEND,
	TRIGGER_VALIDATE_BEFORE_RATE_SEND,
	UPDATE_COLUMN_PACKAGE_SEND,
	SET_USE_TRADING,
	SET_NOTIFICATIONS,
	NotificationContacts,
	NewBusiness,
	Channel
} from '../actions/ActionTypes';

type SendState = {
	originAddressSend: AddressRequest | null;
	destinationAddressSend: AddressRequest | null;

	originFormattedAddress: any | null;
	destinationFormattedAddress: any | null;

	currentPackagesSend: Package[];
	serviceSend: ServiceRequest | null;
	estafetaOutputType: string | null;
	wantsToSaveOutputTypeForEstafeta: boolean;
	availableOriginContactsSend: Contact[];
	availableDestinationContactsSend: Contact[];
	canContinueToRateSend: boolean;
	canContinueToSummarySend: boolean;
	isTouchedSend: boolean;
	areFormPkgsValidSend: boolean;
	showInsuranceOnSummary: boolean;
	batchId: string | null;
	rateId: string | null;

	originQuery: string | null;
	destinationQuery: string | null;
	additionalInfo: string;
	reference: string;
	channel?: Channel;

	value: string;

	business?: NewBusiness;
	notifications?: NotificationContacts;
};

const initialState: SendState = {
	originAddressSend: null,
	destinationAddressSend: null,

	originFormattedAddress: null,
	destinationFormattedAddress: null,

	currentPackagesSend: [],
	serviceSend: null,
	estafetaOutputType: null,
	wantsToSaveOutputTypeForEstafeta: false,
	availableOriginContactsSend: [],
	availableDestinationContactsSend: [],
	canContinueToRateSend: false,
	canContinueToSummarySend: false,
	isTouchedSend: false,
	areFormPkgsValidSend: false,
	showInsuranceOnSummary: false,
	batchId: null,
	rateId: null,

	originQuery: null,
	destinationQuery: null,
	additionalInfo: '',
	reference: '',
	channel: undefined,

	value: 'prepaid',

	notifications: undefined,
	business: undefined
};

const SendReducer = (state: SendState = initialState, action: SendActions): SendState => {
	let nextState: SendState;
	switch (action.type) {
		case RESET_SEND:
			return initialState;
		case ADD_PACKAGE_SEND:
			nextState = produce(state, (draft) => {
				draft.currentPackagesSend.push(action.pkg);
			});
			break;
		case ADD_MERCHANDISE_PACKAGE_SEND:
			nextState = produce(state, (draft) => {
				const index = draft.currentPackagesSend.findIndex((p) => p.pkg_id === action.pkg_id);
				if (index === -1) return;
				if (!draft.currentPackagesSend[index].merchandise) {
					draft.currentPackagesSend[index].merchandise = [];
				}
				draft.currentPackagesSend[index].merchandise?.push(action.payload);
			});
			break;
		case CAN_CONTINUE_TO_RATE_SEND:
			nextState = produce(state, (draft) => {
				draft.canContinueToRateSend =
					Boolean(draft.originAddressSend) &&
					Boolean(draft.originAddressSend?.contact) &&
					Boolean(draft.destinationAddressSend) &&
					Boolean(draft.destinationAddressSend?.contact) &&
					Boolean(draft.currentPackagesSend.length > 0) &&
					draft.areFormPkgsValidSend;
			});
			break;
		case CAN_CONTINUE_TO_SUMMARY_SEND:
			nextState = produce(state, (draft) => {
				draft.canContinueToSummarySend = draft.canContinueToRateSend && Boolean(draft.serviceSend);
			});
			break;
		case SET_ORIGIN_ADDRESS_SEND:
			nextState = produce(state, (draft) => {
				draft.originAddressSend = action.address;
				draft.availableOriginContactsSend = action.contacts;
				if (action.contacts.length === 1 && draft.originAddressSend) {
					draft.originAddressSend.contact = action.contacts[0];
				}
			});
			break;
		case SET_DESTINATION_ADDRESS_SEND:
			nextState = produce(state, (draft) => {
				draft.destinationAddressSend = action.address;
				draft.availableDestinationContactsSend = action.contacts;
				if (action.contacts.length === 1 && draft.destinationAddressSend) {
					draft.destinationAddressSend.contact = action.contacts[0];
				}
			});
			break;
		case SET_ORIGIN_CONTACT_SEND:
			nextState = produce(state, (draft) => {
				if (draft.originAddressSend) {
					draft.originAddressSend.contact = action.contact;
				}
			});
			break;
		case SET_DESTINATION_CONTACT_SEND:
			nextState = produce(state, (draft) => {
				if (draft.destinationAddressSend) {
					draft.destinationAddressSend.contact = action.contact;
				}
			});
			break;
		case REMOVE_PACKAGE_SEND:
			nextState = produce(state, (draft) => {
				draft.currentPackagesSend = draft.currentPackagesSend.filter((p) => p.pkg_id !== action.id);
			});
			break;
		case SET_SERVICE_SEND:
			nextState = produce(state, (draft) => {
				draft.serviceSend = action.service;
			});
			break;
		case SWIPE_ADDRESS_SEND:
			nextState = produce(state, (draft) => {
				const temp = draft.destinationAddressSend;
				draft.destinationAddressSend = draft.originAddressSend;
				draft.originAddressSend = temp;
				const tempContacts = draft.availableDestinationContactsSend;
				draft.availableDestinationContactsSend = draft.availableOriginContactsSend;
				draft.availableOriginContactsSend = tempContacts;
			});
			break;
		case TRIGGER_VALIDATE_BEFORE_RATE_SEND:
			nextState = produce(state, (draft) => {
				draft.isTouchedSend = action.status;
			});
			break;
		case SET_FORM_PACKAGES_VALID_SEND:
			nextState = produce(state, (draft) => {
				draft.areFormPkgsValidSend = action.status;
			});
			break;
		case UPDATE_COLUMN_PACKAGE_SEND:
			nextState = produce(state, (draft) => {
				const index = draft.currentPackagesSend.findIndex((p) => p.pkg_id === action.pkg_id);
				if (index === -1) return;
				for (const [key, value] of Object.entries(action.payload)) {
					if (key === 'merchandise') continue;
					switch (key) {
						case 'quantity':
							if (!action.merchandiseId) {
								draft.currentPackagesSend[index][key] = value as number;
							}
							break;
						case 'insurance':
						case 'height':
						case 'width':
						case 'length':
						case 'weight':
							draft.currentPackagesSend[index][key] = value as number;
							break;
						case 'type':
						case 'alias':
						case 'measurement':
						case 'content':
							// @ts-ignore
							draft.currentPackagesSend[index][key] = value as string;
							break;
						// @ts-ignore
						case 'is_irregular_delivery':
							// @ts-ignore
							draft.currentPackagesSend[index][key] = value as boolean;
							break;
						default:
							break;
					}
				}

				if (index === -1 || !draft.currentPackagesSend[index].merchandise) return;
				const merchandiseIndex = draft.currentPackagesSend[index].merchandise?.findIndex(
					(m) => m.id === action.merchandiseId
				);
				if (merchandiseIndex === -1) return;

				for (const [key, value] of Object.entries(action.payload)) {
					switch (key) {
						case 'currency':
						case 'description':
						case 'product_code':
						case 'unit_code':
							if (!!draft.currentPackagesSend[index].merchandise) {
								//@ts-ignore
								draft.currentPackagesSend[index].merchandise[merchandiseIndex][key] =
									value as string;
							}
							break;
						case 'value':
							if (!!draft.currentPackagesSend[index].merchandise) {
								//@ts-ignore
								draft.currentPackagesSend[index].merchandise[merchandiseIndex][key] =
									value as number;
							}
							break;
						case 'quantity':
							if (!!draft.currentPackagesSend[index].merchandise && action.merchandiseId) {
								//@ts-ignore
								draft.currentPackagesSend[index].merchandise[merchandiseIndex].quantity =
									typeof value === 'string' ? parseInt(value) : value;
							}
							break;
						default:
							break;
					}
				}
			});
			break;

		case SET_INSURANCE_ON_SUMMARY:
			nextState = produce(state, (draft) => {
				draft.showInsuranceOnSummary = action.status;
			});
			break;
		case SET_BATCH_ID:
			nextState = produce(state, (draft) => {
				draft.batchId = action.batchId;
			});
			break;
		case SET_RATE_ID:
			nextState = produce(state, (draft) => {
				draft.rateId = action.rateId;
			});
			break;
		case SET_ADDRESS_QUERY:
			nextState = produce(state, (draft) => {
				if (action.target === 'origin') draft.originQuery = action.query;
				else draft.destinationQuery = action.query;
			});
			break;
		case RESET_SERVICE_SEND:
			nextState = produce(state, (draft) => {
				draft.serviceSend = null;
			});
			break;
		case SET_FORMATTED_ADDRESS:
			nextState = produce(state, (draft) => {
				draft.originFormattedAddress = action.origin;
				draft.destinationFormattedAddress = action.destination;
			});
			break;
		case SET_ESTAFETA_OUTPUT_TYPE:
			nextState = produce(state, (draft) => {
				draft.estafetaOutputType = action.outputType;
			});
			break;
		case SET_SAVE_ESTAFETA_OUTPUT_TYPE:
			nextState = produce(state, (draft) => {
				draft.wantsToSaveOutputTypeForEstafeta = action.save;
			});
			break;
		case SET_EXTRA_INFORMATION:
			nextState = produce(state, (draft) => {
				draft.reference = action.reference;
				draft.additionalInfo = action.additionalInfo;
				draft.business = action.business || undefined;
				draft.channel = action.channel || undefined;
			});
			break;
		case SET_NOTIFICATIONS:
			nextState = produce(state, (draft) => {
				draft.notifications = action.notifications;
			});
			break;
		default:
			return state;
		case SET_USE_TRADING:
			nextState = produce(state, (draft) => {
				draft.value = action.value;
			});
	}
	return nextState;
};

export default SendReducer;
