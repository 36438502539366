import { Dispatch } from 'redux';
import {
	ADD_LABEL_PICKUP,
	SET_LABELS_PICKUP,
	AddressRequest,
	CAN_CONTINUE_TO_EDIT_PICKUP,
	CAN_CONTINUE_TO_SUMMARY_PICKUP,
	Contact,
	ID,
	PickUpActions,
	REMOVE_LABEL_PICKUP,
	RESET_LABELS_PICKUP,
	RESET_PICKUP,
	SET_ADDRESS_PICKUP,
	SET_CARRIER_FOLIO_PICKUP,
	SET_CARRRIER_PICKUP,
	SET_CONTACT_PICKUP,
	SET_DATE_PICKUP,
	SET_EXTRA_INDICATIONS_PICKUP,
	SET_INDICATIONS_PICKUP,
	SET_REASON_PICKUP,
	SET_STATUS_PICKUP,
	SET_TIME_PICKUP,
	ShipmentSendResponse,
	TRIGGER_VALIDATE_BEFORE_SUMMARY_PICKUP,
	UPDATE_PICKUP_DETAILS,
	SET_INSURANCE_FOR_PICKUP,
	SET_PICKUP_SUPERVISOR,
	SET_PICKUP_NOTES
} from './ActionTypes';

export const resetPickup = (): PickUpActions => {
	return { type: RESET_PICKUP };
};

export const setAddressPickUp =
	(address: AddressRequest | null, contacts: Contact[]) => (dispatch: Dispatch<PickUpActions>) => {
		dispatch({ type: SET_ADDRESS_PICKUP, address, contacts });
		dispatch({ type: CAN_CONTINUE_TO_SUMMARY_PICKUP });
	};

export const setContactPickup =
	(contact: Contact | null) => (dispatch: Dispatch<PickUpActions>) => {
		dispatch({ type: SET_CONTACT_PICKUP, contact });
		dispatch({ type: CAN_CONTINUE_TO_SUMMARY_PICKUP });
	};

export const updatePickupDetails =
	(id: ID, labels: ShipmentSendResponse[]) => (dispatch: Dispatch<PickUpActions>) => {
		dispatch({ type: UPDATE_PICKUP_DETAILS, id, labels });
	};

export const addLabelPickup =
	(label: ShipmentSendResponse) => (dispatch: Dispatch<PickUpActions>) => {
		dispatch({ type: ADD_LABEL_PICKUP, label });
		dispatch({ type: CAN_CONTINUE_TO_SUMMARY_PICKUP });
	};

export const setLabelsPickup =
	(labels: ShipmentSendResponse[]) => (dispatch: Dispatch<PickUpActions>) => {
		dispatch({ type: SET_LABELS_PICKUP, labels });
		dispatch({ type: CAN_CONTINUE_TO_SUMMARY_PICKUP });
	};

export const removeLabelPickup = (id: ID) => (dispatch: Dispatch<PickUpActions>) => {
	dispatch({ type: REMOVE_LABEL_PICKUP, id });
	dispatch({ type: CAN_CONTINUE_TO_SUMMARY_PICKUP });
};

export const setIndicationsPickUp =
	(indications: string) => (dispatch: Dispatch<PickUpActions>) => {
		dispatch({ type: SET_INDICATIONS_PICKUP, indications });
		dispatch({ type: CAN_CONTINUE_TO_SUMMARY_PICKUP });
	};

export const setExtraIndicationsPickUp = (extra_indications: string): PickUpActions => {
	return { type: SET_EXTRA_INDICATIONS_PICKUP, extra_indications };
};

export const setDatePickUp = (date: Date | null) => (dispatch: Dispatch<PickUpActions>) => {
	dispatch({ type: SET_DATE_PICKUP, date });
	dispatch({ type: CAN_CONTINUE_TO_SUMMARY_PICKUP });
};

export const setTimePickUp =
	(time: Date | null, target: 'start' | 'end') => (dispatch: Dispatch<PickUpActions>) => {
		dispatch({ type: SET_TIME_PICKUP, target, time });
		dispatch({ type: CAN_CONTINUE_TO_SUMMARY_PICKUP });
	};

export const setStatus =
	(status: string, isAgent: boolean) => (dispatch: Dispatch<PickUpActions>) => {
		dispatch({ type: SET_STATUS_PICKUP, status });
		dispatch({ type: CAN_CONTINUE_TO_EDIT_PICKUP, isAgent });
	};

export const setPickUpReason =
	(reason: string, isAgent: boolean) => (dispatch: Dispatch<PickUpActions>) => {
		dispatch({ type: SET_REASON_PICKUP, reason });
		dispatch({ type: CAN_CONTINUE_TO_EDIT_PICKUP, isAgent });
	};

export const setPickUpCarrierFolio =
	(carrierFolio: string, isAgent: boolean) => (dispatch: Dispatch<PickUpActions>) => {
		dispatch({ type: SET_CARRIER_FOLIO_PICKUP, carrierFolio });
		dispatch({ type: CAN_CONTINUE_TO_EDIT_PICKUP, isAgent });
	};

export const setPickUpSupervisor =
	(pickupSupervisor: string) => (dispatch: Dispatch<PickUpActions>) => {
		dispatch({ type: SET_PICKUP_SUPERVISOR, pickupSupervisor });
	};

export const setPickupNotes = (pickupNotes: string) => (dispatch: Dispatch<PickUpActions>) => {
	dispatch({ type: SET_PICKUP_NOTES, pickupNotes });
};

export const setInsuranceForPickupOrder =
	(insuranceForOrder: boolean) => (dispatch: Dispatch<PickUpActions>) => {
		dispatch({ type: SET_INSURANCE_FOR_PICKUP, insuranceForOrder });
	};

/**
 * Will try to validate the existence of address, contact  date, times and indications
 */
export const triggerValidationsPickup = (status: boolean): PickUpActions => {
	return { type: TRIGGER_VALIDATE_BEFORE_SUMMARY_PICKUP, status };
};

export const resetLabelsPickup = (): PickUpActions => {
	return { type: RESET_LABELS_PICKUP };
};

export const setCarrierPickUp = (carrier: string) => (dispatch: Dispatch<PickUpActions>) => {
	dispatch({ type: SET_CARRRIER_PICKUP, carrier });
	dispatch({ type: CAN_CONTINUE_TO_SUMMARY_PICKUP });
};
