import thunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { useSelector as useReduxSelector, TypedUseSelectorHook } from 'react-redux';

import FlowReducer from './reducers/FlowReducer';
import DataReducer from './reducers/DataReducer';
import AppReducer from './reducers/AppReducer';
import PickUpReducer from './reducers/PickUpReducer';
import SendReducer from './reducers/SendReducer';
import RateReducer from './reducers/RateReducer';
import CreateReducer from './reducers/CreateReducer';
import ConsolidatedReducer from './reducers/ConsolidatedReducer';
import CreateOrUpdateCustomerReducer from './reducers/agents/CreateorUpdateCustomerReducer';
import SimulatedUserReducer from './reducers/agents/SimulatedUserReducer';
import MultiSendReducer from './reducers/MultiSendReducer';

const rootReducer = combineReducers({
	//maps:
	flow: FlowReducer,
	send: SendReducer,
	multisend: MultiSendReducer,
	rate: RateReducer,
	consolidated: ConsolidatedReducer,
	pickup: PickUpReducer,
	data: DataReducer,
	app: AppReducer,
	create: CreateReducer,
	simulatedUser: SimulatedUserReducer,
	cuCustomer: CreateOrUpdateCustomerReducer
});

const composeEnhancers =
	typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
				trace: true
		  })
		: compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const mainStore = createStore(rootReducer, enhancer);

export type RootStore = ReturnType<typeof rootReducer>;
// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/2ab7a8073719008b169a3069977cc66f2e87a1e2/types/react-redux/index.d.ts#L544
export const useSelector: TypedUseSelectorHook<RootStore> = useReduxSelector;

export default mainStore;
