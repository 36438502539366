import React from 'react';
import { UserDetails, UserRole } from '../store/actions/ActionTypes';

export type ReadPermissions = {
	app: boolean;
	addresses: boolean;
	packages: boolean;
	users: boolean;
	groups: boolean;
	client: boolean;
	rate: boolean;
	label: boolean;
	pickup: boolean;
	dispersion: boolean;
	disperse: boolean;
	business: boolean;
	billing: boolean;
}

export type CreateUpdateDeletePermissions = {
	address: boolean;
	package: boolean;
	user: boolean;
	group: boolean;
	client: boolean;
	rate: boolean;
	label: boolean;
	pickup: boolean;
	dispersion: boolean;
	disperse: boolean;
	business: boolean;
	billing: boolean;
}

export type PermissionState =  { canRead: ReadPermissions, canCreateUpdateDelete: CreateUpdateDeletePermissions };

// https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, ...0[]];

type Join<K, P> = K extends string | number
	? P extends string | number
		? `${K}${'' extends P ? '' : '.'}${P}`
		: never
	: never;

// type Paths<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
//     { [K in keyof T]-?: K extends string | number ?
//         `${K}` | Join<K, Paths<T[K], Prev[D]>>
//         : never
//     }[keyof T] : ""

type Leaves<T, D extends number = 10> = [D] extends [never]
	? never
	: T extends object
	? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
	: '';

export type PermissionsKey = Leaves<PermissionState>;

interface Params {
	// Log in
	isAuth: boolean;
	setAuth: (auth: boolean) => void;
	isCheckingIsAuth: boolean;
	setIsCheckingIsAuth: (auth: boolean) => void;
	// UserForm info
	currentUser: UserDetails;
	setCurrentUser: (currentUser: UserDetails) => void;
	currentPermissions: PermissionState;
	clearUser: () => void;
}


const AuthContext = React.createContext<Params>({
	isAuth: false,
	setAuth: () => {},
	isCheckingIsAuth: true,
	setIsCheckingIsAuth: () => {},
	currentUser: {
		id: 0,
		customer: null,
		default_address: null,
		email: '',
		group: null,
		name: '',
		options: {
			rate_price: false,
			theme: false,
		},
		permissions: '',
		phone_number: null,
		role: UserRole.CLIENT,
		services: [],
	},
	setCurrentUser: () => {},
	currentPermissions: {
		canRead: {
			app: true,
			addresses: false,
			packages: false,
			users: false,
			groups: false,
			client: false,

			rate: false,
			label: false,
			pickup: false,
			dispersion: false,
			disperse: false,

			business: false,
			billing: false,
		},
		canCreateUpdateDelete:{
			address: false,
			package: false,
			user: false,
			group: false,
			client: false,

			rate: false,
			label: false,
			pickup: false,
			dispersion: false,
			disperse: false,

			
			business: false,
			billing: false,
		},
	},
	clearUser: () => {}
});

export default AuthContext;
