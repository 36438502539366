import React from 'react';
import { getCellStatusColor } from '~util/cellStatusColor';

interface Props {
	status: string;
	rectangle?: boolean;
	backgroundColor?: string;
}

const StatusCell: React.FC<Props> = ({ status, rectangle, backgroundColor }) => {
	return (
		<div
			style={{
				backgroundColor: backgroundColor ?? getCellStatusColor(status),
				fontWeight: 'bold',
				padding: 10,
				borderRadius: !rectangle ? 20 : 8,
				textAlign: 'center',
				margin: '0 auto',
				minWidth: 0,
				overflow: 'hidden',
				width: '100%',
				lineHeight: 1.2,
				maxWidth: '140px'
			}}
		>
			{status}
		</div>
	);
};

export default StatusCell;
