import { Button, Grid, createStyles, makeStyles, Theme, alpha } from '@material-ui/core';
import React from 'react';
import { Plus } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { PermissionsKey } from '~context/AuthContext';
import OnlyIf from '~components/Guard/OnlyIf';
import { AddButton } from './Controls/AddButton';
import { resetFlow } from '../../store/actions';
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		newBtn: {
			'&:hover': {
				color: '#4EC0DF'
			},
			color: '#3f403f',
			width: 150,
			marginRight: 25,
			backgroundColor: '#E5E5E5',
			borderRadius: theme.spacing(0.5)
		},
		search: {
			position: 'relative',
			borderRadius: theme.spacing(3),
			backgroundColor: alpha(theme.palette.common.white, 0.15),
			'&:hover': {
				backgroundColor: alpha(theme.palette.common.white, 0.25)
			},
			justifySelf: 'flex-end',
			marginLeft: 0,
			width: '50%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(1),
				width: 225
			}
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		inputRoot: {
			color: 'inherit'
		},
		inputInput: {
			height: 30,
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create('width'),
			width: 150,
			[theme.breakpoints.up('md')]: {
				width: 150
			}
		}
	})
);

interface Props {
	rows?: any[];
	linkToAdd: string;
	setFilteredRows?: (rows: any[]) => void;
	permission?: PermissionsKey;
}

const TableControls: React.FC<Props> = (props) => {
	return (
		<Grid container justifyContent='flex-end'>
			{/* @@@ Remove the conditional */}
			{props.permission ? (
				<OnlyIf permission={props.permission}>
					{/* <Button
						className={classes.newBtn}
						onClick={() => {
							history.push(props.linkToAdd);
							dispatch(resetFlow());
						}}
					>
						<Plus size="15" />
						&nbsp;Nuevo
					</Button> */}
					<AddButton redirectTo={props.linkToAdd} />
				</OnlyIf>
			) : null}
			{/* {props.setFilteredRows && (
				<div className={classes.search}>
					<div className={classes.searchIcon}>
						<Search />
					</div>
					<InputBase
						placeholder='Buscar…'
						onChange={handleFilter}
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
					/>
				</div>
			)} */}
		</Grid>
	);
};

export default TableControls;
