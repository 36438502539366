import { FC, useCallback, useContext, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	createStyles,
	makeStyles,
	Theme,
	Grid,
	DialogActions,
	Button,
	FormControlLabel,
	Radio
} from '@material-ui/core';
import { HeadingTwo, TextOne } from '~components/Headings';
import axiosInstance from '~util/axios';
import DrawContext from '~context/DrawContext';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			'& .MuiPaper-root': {
				maxWidth: '60vw'
			},
			padding: theme.spacing(2)
		},
		dialogContent: {
			paddingTop: '0 !important',
			'& > img': {
				width: '100%'
			}
		},
		closeButton: {
			padding: '0',
			display: 'flex',
			justifyContent: 'flex-end',
			'& > button': {
				color: theme.palette.grey[500]
			}
		},
		spacing: {
			padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
				3
			)}px`
		}
	})
);

type Props = {
	isOpen: boolean;
	onFinish: () => void;
	notification: { type: string; title: string; message: string } | null;
};

const HomeNotificationModal: FC<Props> = ({ isOpen, onFinish, notification }) => {
	const classes = useStyles();
	const [confirm, setConfirm] = useState(false);
	const [loading, setLoading] = useState(false);
	const { setNotification } = useContext(DrawContext);

	// Confirm read notification
	const handleReadNotification = useCallback(async () => {
		setLoading(true);
		try {
			await axiosInstance.post('/api/notification/confirmRead', { type: notification?.type });
			setNotification({
				severity: 'success',
				message: 'La noticación se confirmó con éxito',
				time: 2000
			});
			onFinish();
		} catch (error) {
			setNotification({
				severity: 'error',
				message: 'Hubo un error al confirmar la notificación',
				time: 2000
			});
		} finally {
			onFinish();
			setLoading(false);
		}
	}, [onFinish, setNotification, notification?.type]);

	return (
		<Dialog
			open={isOpen}
			maxWidth='sm'
			fullWidth
			disableEscapeKeyDown
			className={classes.container}
		>
			<DialogTitle disableTypography>
				<Grid container alignItems='center' justifyContent='space-between'>
					<HeadingTwo text={notification?.title ?? ''} />
				</Grid>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<TextOne text={notification?.message ?? ''} />
			</DialogContent>
			<DialogActions className={classes.spacing}>
				<Grid container>
					<Grid item container justifyContent='flex-start'>
						<FormControlLabel
							value='confirm'
							control={<Radio color='primary' />}
							label='Acepto y estoy de acuerdo con el esquema'
							onChange={(e) => setConfirm((e.target as HTMLInputElement).value === 'confirm')}
						/>
					</Grid>

					<Grid item container justifyContent='flex-end'>
						<Button
							onClick={handleReadNotification}
							variant='contained'
							disabled={!confirm || loading}
							color='primary'
						>
							Confirmar
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};

export default HomeNotificationModal;