export default function capitalize(str: string = '') {
	return str.trim().toLowerCase().charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeAll(str: string = '') {
	return str
		.trim()
		.toLowerCase()
		?.split(' ')
		.map((item) => (item !== 'y' && item !== 'de' ? capitalize(item) : item))
		.join(' ');
}
