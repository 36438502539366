import produce from 'immer';
import {
	SET_DESTINATION_ADDRESS_CREATE,
	SET_ORIGIN_ADDRESS_CREATE,
	RESET_CREATE,
	ADD_PACKAGE_CREATE,
	REMOVE_PACKAGE_CREATE,
	UPDATE_COLUMN_PACKAGE_CREATE,
	TRIGGER_VALIDATE_BEFORE_CREATE_CREATE,
	CAN_CONTINUE_TO_CREATE_CREATE,
	SET_FORM_PACKAGES_VALID_CREATE,
	Package,
	Contact,
	CreateActions,
	AddressRequest,
	TOGGLE_ORIGIN_ADDRESS_COMPONENT,
	TOGGLE_DESTINATION_ADDRESS_COMPONENT
} from '../actions/ActionTypes';

type CreateState = {
	availableOriginContactsCreate: Contact[];
	availableDestinationContactsCreate: Contact[];
	originAddressCreate: AddressRequest | null;
	destinationAddressCreate: AddressRequest | null;
	originFormattedAddress: any | null;
	destinationFormattedAddress: any | null;
	originAddressComponent: 'input' | 'autocomplete';
	destinationAddressComponent: 'input' | 'autocomplete';
	currentPackagesCreate: Package[];
	canContinueToRateCreate: boolean;
	canContinueToCreateCreate: boolean;
	isTouchedCreate: boolean;
	areFormPkgsValidCreate: boolean;
};

const initialState: CreateState = {
	availableOriginContactsCreate: [],
	availableDestinationContactsCreate: [],
	originAddressCreate: null,
	destinationAddressCreate: null,
	originFormattedAddress: null,
	destinationFormattedAddress: null,
	currentPackagesCreate: [],
	canContinueToCreateCreate: false,
	canContinueToRateCreate: false,
	isTouchedCreate: false,
	areFormPkgsValidCreate: false,
	originAddressComponent: 'autocomplete',
	destinationAddressComponent: 'autocomplete'
};

export const CreateReducer = (
	state: CreateState = initialState,
	action: CreateActions
): CreateState => {
	let nextState: CreateState;
	switch (action.type) {
		case SET_DESTINATION_ADDRESS_CREATE:
			nextState = produce(state, (draft) => {
				draft.destinationAddressCreate = action.address;
				draft.availableDestinationContactsCreate = action.contacts;
				if (action.contacts.length === 1 && draft.destinationAddressCreate) {
					draft.destinationAddressCreate.contact = action.contacts[0];
				}
			});
			break;
		case SET_ORIGIN_ADDRESS_CREATE:
			nextState = produce(state, (draft) => {
				draft.originAddressCreate = action.address;
				draft.availableOriginContactsCreate = action.contacts;
				if (action.contacts.length === 1 && draft.originAddressCreate) {
					draft.originAddressCreate.contact = action.contacts[0];
				}
			});
			break;
		case CAN_CONTINUE_TO_CREATE_CREATE:
			nextState = produce(state, (draft) => {
				draft.canContinueToRateCreate =
					Boolean(draft.originAddressCreate) &&
					Boolean(draft.originAddressCreate?.contact) &&
					Boolean(draft.destinationAddressCreate) &&
					Boolean(draft.destinationAddressCreate?.contact) &&
					Boolean(draft.currentPackagesCreate.length > 0) &&
					draft.areFormPkgsValidCreate;
			});
			break;
		case RESET_CREATE:
			return initialState;
		case ADD_PACKAGE_CREATE:
			nextState = produce(state, (draft) => {
				draft.currentPackagesCreate.push(action.pkg);
			});
			break;

		case REMOVE_PACKAGE_CREATE:
			nextState = produce(state, (draft) => {
				draft.currentPackagesCreate = draft.currentPackagesCreate.filter(
					(p) => p.pkg_id !== action.id
				);
			});
			break;
		case UPDATE_COLUMN_PACKAGE_CREATE:
			nextState = produce(state, (draft) => {
				const index = draft.currentPackagesCreate.findIndex((p) => p.pkg_id === action.id);
				if (index === -1) return;
				const key = Object.keys(action.payload)[0];
				const value = Object.values(action.payload)[0];
				switch (key) {
					case 'quantity':
					case 'insurance':
					case 'height':
					case 'width':
					case 'length':
					case 'weight':
						draft.currentPackagesCreate[index][key] = value as number;
						break;
					case 'type':
						draft.currentPackagesCreate[index][key] = value as string;
						break;
					default:
						break;
				}
			});
			break;
		case TRIGGER_VALIDATE_BEFORE_CREATE_CREATE:
			nextState = produce(state, (draft) => {
				draft.isTouchedCreate = action.status;
			});
			break;
		case SET_FORM_PACKAGES_VALID_CREATE:
			nextState = produce(state, (draft) => {
				draft.areFormPkgsValidCreate = action.status;
			});
			break;
		case TOGGLE_ORIGIN_ADDRESS_COMPONENT:
			nextState = produce(state, (draft) => {
				if (draft.originAddressComponent === 'input') {
					draft.originAddressComponent = 'autocomplete';
				} else {
					draft.originAddressComponent = 'input';
				}
			});
			break;
		case TOGGLE_DESTINATION_ADDRESS_COMPONENT:
			nextState = produce(state, (draft) => {
				if (draft.destinationAddressComponent === 'input') {
					draft.destinationAddressComponent = 'autocomplete';
				} else {
					draft.destinationAddressComponent = 'input';
				}
			});
			break;
		default:
			return state;
	}
	return nextState;
};

export default CreateReducer;
