// Guarda las direcciones y packages para el flujo

import produce from 'immer';
import {
	ADD_ADDRESS_DATA,
	ADD_PACKAGE_TEMP_DATA,
	EDIT_PACKAGE_TEMP_DATA,
	CLEAR_ERROR_FETCH_DATA,
	CLEAR_SERVICES_DATA,
	FETCH_ADDRESSES_DATA,
	FETCH_FAIL_DATA,
	FETCH_INIT_DATA,
	FETCH_PACKAGES_DATA,
	FETCH_SUCCESS_DATA,
	POST_GUIDE_DATA,
	POST_PICKUP_DATA,
	POST_RATE_DATA,
	Package,
	DataActions,
	Label,
	ServiceResponse,
	NewAddress,
	REMOVE_PACKAGE,
	CLEAR_ADDRESS_DATA,
	CLEAR_PARCEL_NUMBERS,
	// CreatedLabel,
	ShipmentSendResponse,
	SET_CARRIERS,
	CarrierList
} from '../actions/ActionTypes';

type DataState = {
	addresses: NewAddress[];
	packages: Package[];
	//token: string | null;

	//del rate
	services: ServiceResponse[];
	createdLabels: Array<ShipmentSendResponse>;
	totalCost: number;

	//para pickup
	labels: Label[];
	pickupMessage: string;

	errorMessageData: string;
	// when fetching
	isLoading: boolean;

	carriers: CarrierList;
};

const initialState: DataState = {
	addresses: [],
	packages: [],
	labels: [
		{
			id: 1,
			created_at: '',
			parcel_number: '4781584780',
			label: '',
			service_description: '',
			service_id: '',
			carrier: 'dhl'
		},
		{
			id: 2,
			created_at: '',
			parcel_number: '5702254250',
			label: '',
			service_description: '',
			service_id: '',
			carrier: 'dhl'
		},
		{
			id: 3,
			created_at: '',
			label: '',
			service_description: '',
			service_id: '',
			parcel_number: '6015013271610680252912',
			carrier: 'estafeta'
		}
	],

	pickupMessage: '',

	services: [],
	createdLabels: [],
	totalCost: 0,

	errorMessageData: '',
	// when fetching
	isLoading: false,

	carriers: []
};

const DataReducer = (state: DataState = initialState, action: DataActions): DataState => {
	let nextState: DataState;
	switch (action.type) {
		case FETCH_ADDRESSES_DATA:
			nextState = produce(state, (draft) => {
				draft.addresses = action.addresses;
			});
			break;
		case FETCH_PACKAGES_DATA:
			nextState = produce(state, (draft) => {
				draft.packages = action.packages;
			});
			break;
		case CLEAR_ADDRESS_DATA:
			nextState = produce(state, (draft) => {
				draft.addresses = [];
			});
			break;
		case ADD_PACKAGE_TEMP_DATA:
			nextState = produce(state, (draft) => {
				draft.packages.push(action.package);
			});
			break;
		case EDIT_PACKAGE_TEMP_DATA:
			nextState = produce(state, (draft) => {
				const index = draft.packages.findIndex((pkg) => pkg.id === action.package.id);
				draft.packages[index] = action.package;
			});
			break;
		case REMOVE_PACKAGE:
			nextState = produce(state, (draft) => {
				draft.packages = draft.packages.filter((pkg) => pkg.id !== action.id);
			});
			break;
		case ADD_ADDRESS_DATA:
			nextState = produce(state, (draft) => {
				draft.addresses.push(action.address);
				draft.addresses.sort((a, b) => {
					if (a.scope < b.scope) {
						return -1;
					}
					if (a.scope > b.scope) {
						return 1;
					}
					return 0;
				});
			});
			break;
		case FETCH_INIT_DATA:
			nextState = produce(state, (draft) => {
				draft.isLoading = true;
			});
			break;
		case FETCH_FAIL_DATA:
			nextState = produce(state, (draft) => {
				draft.isLoading = false;
				draft.errorMessageData = action.message;
			});
			break;
		case FETCH_SUCCESS_DATA:
			nextState = produce(state, (draft) => {
				draft.isLoading = false;
			});
			break;
		case POST_RATE_DATA:
			nextState = produce(state, (draft) => {
				draft.services = action.services;
			});
			break;
		case CLEAR_ERROR_FETCH_DATA:
			nextState = produce(state, (draft) => {
				draft.errorMessageData = '';
			});
			break;
		case POST_GUIDE_DATA:
			nextState = produce(state, (draft) => {
				draft.createdLabels = action.labels;
				draft.totalCost = action.total;
			});
			break;
		case POST_PICKUP_DATA:
			nextState = produce(state, (draft) => {
				draft.pickupMessage = action.response;
			});
			break;
		case CLEAR_SERVICES_DATA:
			nextState = produce(state, (draft) => {
				draft.services = [];
			});
			break;
		case CLEAR_PARCEL_NUMBERS:
			nextState = produce(state, (draft) => {
				draft.createdLabels = [];
				draft.totalCost = 0;
			});
			break;
		case SET_CARRIERS:
			nextState = produce(state, (draft) => {
				draft.carriers = action.carriers;
			});
			break;
		default:
			return state;
	}
	return nextState;
};

export default DataReducer;
