import React from 'react';
import { createStyles, makeStyles /*Theme*/ } from '@material-ui/core';
import { getLogoByCarrierName } from '../../util/logos';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: 'white',
			color: 'black',
			fontSize: 15,
			textAlign: 'center',
			padding: 7,
			borderRadius: 20,
			fontWeight: 'bold',
			maxWidth: 156,
			height: 74
		},
		carrierLogo: {
			width: '90%',
			minWidth: 84,
			margin: '0 auto',
			height: 'auto',
			maxHeight: 100
		},
		carrierService: {
			fontSize: 10,
			width: '100%',
			textAlign: 'center',
			marginTop: 2
		}
	})
);

interface Props {
	carrier: string;
	service?: string;
}
const CarrierService: React.FC<Props> = (props) => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.root}>
				<img
					className={classes.carrierLogo}
					src={getLogoByCarrierName(props.carrier)}
					alt={props.carrier}
				/>
			</div>
			<p>{props.service ? 'Servicio: ' + props.service : null}</p>
		</>
	);
};

export default CarrierService;
