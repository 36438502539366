import produce from 'immer';
import {
	CLEAR_ERROR_SIMULATED_USER,
	CustomerDetails,
	RESET_SIMULATED_USER,
	SET_SIMULATED_CUSTOMER_SIMULATED_USER,
	SET_SIMULATED_USER,
	TRIGGER_ERRORS_SIMULATED_USER,
	SimulatedUserActions,
	UserDetails,
} from '~store/actions/ActionTypes';

type SimulatedUserState = {
	// When is agent or root
	simulatedUser: UserDetails | null;
	simulatedCustomer: CustomerDetails | null;
	isTouchedSimulatedUser: boolean;
	canPostSimulatedUser: boolean;
};

const initialState: SimulatedUserState = {
	simulatedUser: null,
	simulatedCustomer: null,
	canPostSimulatedUser: false,
	isTouchedSimulatedUser: false,
};

const SimulatedUserReducer = (
	state: SimulatedUserState = initialState,
	action: SimulatedUserActions
): SimulatedUserState => {
	let nextState: SimulatedUserState;
	switch (action.type) {
		case RESET_SIMULATED_USER:
			return initialState;
		case SET_SIMULATED_USER:
			nextState = produce(state, (draft) => {
				draft.simulatedUser = action.user;
				draft.canPostSimulatedUser = Boolean(action.user);
			});
			break;
		case SET_SIMULATED_CUSTOMER_SIMULATED_USER:
			nextState = produce(state, (draft) => {
				draft.simulatedCustomer = action.customer;
			});
			break;
		case TRIGGER_ERRORS_SIMULATED_USER:
			nextState = produce(state, (draft) => {
				draft.isTouchedSimulatedUser = true;
			});
			break;
		case CLEAR_ERROR_SIMULATED_USER:
			nextState = produce(state, (draft) => {
				draft.isTouchedSimulatedUser = false;
			});
			break;
		default:
			return state;
	}
	return nextState;
};

export default SimulatedUserReducer;
