export const MUIDataGridTranslations = {
	// Root
	noRowsLabel: 'Sin información',
	noResultsOverlayLabel: 'No se encontraron resultados',
	errorOverlayDefaultLabel: 'Ocurrió un error',

	// Density selector toolbar button text
	toolbarDensity: 'Acomodo',
	toolbarDensityLabel: 'Acomodo',
	toolbarDensityCompact: 'Pequeña',
	toolbarDensityStandard: 'Mediana',
	toolbarDensityComfortable: 'Larga',

	// Columns selector toolbar button text
	toolbarColumns: 'Columnas',
	toolbarColumnsLabel: 'Seleccionar Columnas',

	// Filters toolbar button text
	toolbarFilters: 'Filtros',
	toolbarFiltersLabel: 'Mostar Filtros',
	toolbarFiltersTooltipHide: 'Esconder Filtros',
	toolbarFiltersTooltipShow: 'Mostrar Filtros',
	toolbarFiltersTooltipActive: (count: number) => (count !== 1 ? `${count} filtros activos` : `${count} filtro activo`),

	// Export selector toolbar button text
	toolbarExport: 'Exportar',
	toolbarExportLabel: 'Exportat',
	toolbarExportCSV: 'Descargar como CSV',

	// Columns panel text
	columnsPanelTextFieldLabel: 'Encontrar Columna',
	columnsPanelTextFieldPlaceholder: 'Título de columna',
	columnsPanelDragIconLabel: 'Reorder column',
	columnsPanelShowAllButton: 'Mostar todo',
	columnsPanelHideAllButton: 'Esconder Todo',

	// Filter panel text
	filterPanelAddFilter: 'Agregar filtro',
	filterPanelDeleteIconLabel: 'Delete',
	filterPanelOperators: 'Operadores',
	filterPanelOperatorAnd: 'And',
	filterPanelOperatorOr: 'Or',
	filterPanelColumns: 'Columnas',
	filterPanelInputLabel: 'Valor',
	filterPanelInputPlaceholder: 'Valor filtrado',

	// Filter operators text
	filterOperatorContains: 'contiene',
	filterOperatorEquals: 'es igual',
	filterOperatorStartsWith: 'comienza con',
	filterOperatorEndsWith: 'termina con',
	filterOperatorIs: 'es',
	filterOperatorNot: 'no es',
	filterOperatorAfter: 'is after',
	filterOperatorOnOrAfter: 'is on or after',
	filterOperatorBefore: 'is before',
	filterOperatorOnOrBefore: 'is on or before',

	// Filter values text
	filterValueAny: 'any',
	filterValueTrue: 'true',
	filterValueFalse: 'false',

	// Column menu text
	columnMenuLabel: 'Menú',
	columnMenuShowColumns: 'Mostrar columnas',
	columnMenuFilter: 'Filtrar',
	columnMenuHideColumn: 'Esconder',
	columnMenuUnsort: 'Desordenar',
	columnMenuSortAsc: 'Ordenar ASC',
	columnMenuSortDesc: 'Ordenar DESC',

	// Column header text
	columnHeaderFiltersTooltipActive: (count: number) =>
		count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,
	columnHeaderFiltersLabel: 'Mostrar filtros',
	columnHeaderSortIconLabel: 'Sort',

	// Rows selected footer text
	footerRowSelected: (count: number) =>
		count !== 1 ? `${count.toLocaleString()} filas seleccionadas` : `${count.toLocaleString()} fila seleccionada`,

	// Total rows footer text
	footerTotalRows: 'Registros Totales',

	// Total visible rows footer text
	footerTotalVisibleRows: (visibleCount: number, totalCount: number) =>
		`${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

	// Checkbox selection text
	checkboxSelectionHeaderName: 'Checkbox selection',

	// Boolean cell text
	booleanCellTrueLabel: 'true',
	booleanCellFalseLabel: 'false',
};
export const REGEX_POSITIVE_FLOAT_NUMBERS = /^[0-9]+(\.\d+)?$/;
