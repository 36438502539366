import React, { useEffect, useState, useCallback, useContext } from 'react';
import { TextField } from '@material-ui/core';
import axios from '~util/axios';
import { Autocomplete } from '@material-ui/lab';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import DrawContext from '../../context/DrawContext';
import DropdownPaper from '~components/Forms/DropdownPaper';

export const options = [
	{ title: 'Atado' },
	{ title: 'Bidón' },
	{ title: 'Bolsa' },
	{ title: 'Bulto' },
	{ title: 'Caja' },
	{ title: 'Costal' },
	{ title: 'Cubeta' },
	{ title: 'Garrafa' },
	{ title: 'Huacal' },
	{ title: 'Pieza' },
	{ title: 'Reja' },
	{ title: 'Rollo' },
	{ title: 'Sobre' },
	{ title: 'Tambo' },
	{ title: 'Tarima' }
];

interface Props {
	control: Control;
	errors: FieldErrors;
	autoFocus?: boolean;
	defaultValue?: string;

	callBack?: (t: string) => void;

	fieldName?: string;
}

type SATProducts = {
	code: string;
	packaging: string;
	description: string;
	weight_unit: string;
};

const PackageTypeField: React.FC<Props> = (props) => {
	const [options, setOptions] = useState<SATProducts[]>([]);

	const { setNotification } = useContext(DrawContext);

	const getSATTypesCatalog = useCallback(async () => {
		try {
			const SATTypes = await axios.get('api/sat/packagingType');
			setOptions(SATTypes.data);
		} catch (error) {
			setNotification({
				message: 'Algo salió mal. Por favor contacta al equipo de Encamino',
				severity: 'error'
			});
		}
	}, [setNotification]);

	useEffect(() => {
		getSATTypesCatalog();
	}, [getSATTypesCatalog]);

	return (
		<Controller
			render={({ onChange, ...propsown }) => (
				<Autocomplete
					{...propsown}
					openOnFocus
					options={options}
					getOptionLabel={(option) => option.packaging}
					getOptionSelected={(option, value) => option.packaging === value.packaging}
					style={{ width: 136 }}
					onChange={(_, data) => {
						onChange(data);
						if (props.callBack && data?.packaging) {
							props.callBack(data.packaging);
						}
					}}
					renderInput={(params) => (
						<TextField
							autoFocus={props.autoFocus}
							{...params}
							label='Tipo&nbsp;*'
							variant='filled'
							error={Boolean(props.errors?.package_type)}
							helperText={props.errors?.package_type?.message}
						/>
					)}
					PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
				/>
			)}
			name={props.fieldName ? props.fieldName : 'package_type'}
			control={props.control}
			rules={{ required: true }}
			defaultValue={props.defaultValue ? { packaging: props.defaultValue } : null}
		/>
	);
};

export default PackageTypeField;
