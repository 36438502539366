import { useState, useCallback } from 'react';
import { createGenerateClassName } from '@material-ui/core/styles';
import { createTheme as createThemeV4 } from '@material-ui/core/styles';
import { createTheme as createThemeV5 } from '@mui/material/styles';
import { esES } from '@material-ui/core/locale';

export const useTheme = (initialTheme?: boolean) => {
	const generateClassName = createGenerateClassName({
		// By enabling this option, if you have non-MUI elements (e.g. `<div />`)
		// using MUI classes (e.g. `.MuiButton`) they will lose styles.
		// Make sure to convert them to use `styled()` or `<Box />` first.
		disableGlobal: true,
		// Class names will receive this seed to avoid name collisions.
		seed: 'mui-jss'
	});
	const [currentTheme, setCurrentTheme] = useState(!!initialTheme);

	const themeV4 = createThemeV4(
		{
			palette: {
				//@ts-ignore
				type: currentTheme ? 'light' : 'dark',
				primary: {
					main: '#4ec0df'
				},
				background: {
					default: '#181818',
					paper: '#212121'
				},
				error: {
					main: '#aa3e3d'
				}
			},
			typography: {
				h4: {
					fontWeight: 'bold'
				},
				h5: {
					fontWeight: 'bold'
				}
			},
			breakpoints: {
				values: {
					xs: 0,
					sm: 600,
					md: 900,
					lg: 1300,
					xl: 1536
				}
			},
			overrides: {
				MuiButton: {
					root: {
						textTransform: 'capitalize'
					}
				},
				MuiFab: {
					root: {
						textTransform: 'capitalize'
					}
				}
			},
			shape: {
				borderRadius: 8
			},
			props: {}
		},
		esES
	);

	const themeV5 = createThemeV5(
		{
			palette: {
				//@ts-ignore
				type: currentTheme ? 'light' : 'dark',
				primary: {
					main: '#4ec0df'
				},
				background: {
					default: '#181818',
					paper: '#212121'
				},
				error: {
					main: '#aa3e3d'
				}
			},
			typography: {
				h4: {
					fontWeight: 'bold'
				},
				h5: {
					fontWeight: 'bold'
				}
			},
			breakpoints: {
				values: {
					xs: 0,
					sm: 600,
					md: 900,
					lg: 1300,
					xl: 1536
				}
			},
			overrides: {
				MuiButton: {
					root: {
						textTransform: 'capitalize'
					}
				},
				MuiFab: {
					root: {
						textTransform: 'capitalize'
					}
				},
				MuiSelect: {
					root: {
						backgroundColor: '#353535',
						'& ul': {
							backgroundColor: '#353535'
						}
					}
				}
			},
			shape: {
				borderRadius: 8
			},
			props: {}
		},
		esES
	);

	const toggleTheme = useCallback(() => {
		setCurrentTheme((prev) => !prev);
	}, []);

	return { themeV4, themeV5, toggleTheme, currentTheme, generateClassName };
};
