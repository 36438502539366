//import { Dispatch } from 'redux';
// import axios from '../../util/axios';
// import { RootStore } from '../store';
import { Dispatch } from 'redux';
import {
	AppActions,
	SET_MAPS_COORDINATES,
	RESET_PACKAGE_TO_EDIT,
	WILL_EDIT_PACKAGE,
	Package,
	SET_GOOGLE_MAPS_SCRIPT,
	SET_GOOGLE_MAP,
	SET_GOOGLE_MAPS_MARKER,
	RESET_FLOW,
	RESET_SEND,
	RESET_PICKUP,
	// RESET_CUCUSTOMER,
	RESET_SIMULATED_USER,
	RESET_LABELS_PICKUP,
	RESET_RATE,
	RESET_CONSOLIDATED,
	SET_DESTINATION_COORDINATES,
	SET_GOOGLE_MAPS_DESTINATION_MARKER,
	SET_GOOGLE_MAPS_POLYLINE,
	CLEAR_ADDRESS_DATA
} from './ActionTypes';

/**
 * When log in with other account, trigger this function because
 * the stored data could not match with the real data
 * IMPORTANT add the new redux stores here in order to work properly
 */

export const resetApp = () => async (dispatch: Dispatch<any>) => {
	dispatch({ type: RESET_FLOW });
	dispatch({ type: RESET_SEND });
	dispatch({ type: RESET_PICKUP });
	dispatch({ type: RESET_LABELS_PICKUP });
	dispatch({ type: RESET_RATE });
	dispatch({ type: RESET_CONSOLIDATED });
	// dispatch({ type: RESET_CUCUSTOMER });
	dispatch({ type: RESET_SIMULATED_USER });
	dispatch({ type: CLEAR_ADDRESS_DATA });
};

/**
 * Click on edit and redirect to the form
 */
export const editPackage = (pkg: Package): AppActions => {
	return { type: WILL_EDIT_PACKAGE, pkg };
};

/**
 * Once the package is used
 */
export const clearPackageToEdit = (): AppActions => {
	return { type: RESET_PACKAGE_TO_EDIT };
};

export const setMapCoordinates = (coordinates: null | { lat: number; lng: number }): AppActions => {
	return { type: SET_MAPS_COORDINATES, coordinates };
};

export const setDestinationMapCoordinates = (
	coordinates: null | { lat: number; lng: number }
): AppActions => {
	return { type: SET_DESTINATION_COORDINATES, coordinates };
};

/**
 * Google maps script
 */
export const setGoogleMapsScriptStatus = (status: boolean): AppActions => {
	return { type: SET_GOOGLE_MAPS_SCRIPT, status };
};

export const setGoogleMap = (map: google.maps.Map<HTMLDivElement> | null): AppActions => {
	return { type: SET_GOOGLE_MAP, map };
};

export const setGoogleMapsMarker = (marker: google.maps.Marker | null): AppActions => {
	return { type: SET_GOOGLE_MAPS_MARKER, marker };
};

export const setGoogleMapsDestinationMarker = (marker: google.maps.Marker | null): AppActions => {
	return { type: SET_GOOGLE_MAPS_DESTINATION_MARKER, marker };
};

export const setGoogleMapsPolyline = (path: google.maps.Polyline | null): AppActions => {
	return { type: SET_GOOGLE_MAPS_POLYLINE, path };
};
