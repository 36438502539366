import { PermissionsKey } from '~context/AuthContext';
import usePermissions from '~hooks/usePermissions';

type Props = {
	permission: PermissionsKey;
	children: React.ReactNode;
};

const OnlyIf: React.FC<Props> = ({ permission, children }) => {
	const { can } = usePermissions();

	// @ts-ignore
	if (!can(permission)) {
		// @@@ We can allow maybe for customization in this case. Maybe allow
		// to pass a component or message in case the user can't perform certain
		// action on the platform.
		return null;
	}

	return <div>{children}</div>;
};

export default OnlyIf;
