import produce from 'immer';
import {
	RESET_CONSOLIDATED,
	START_CONSOLIDATED,
	SET_CARRIERS_CONSOLIDATED,
	SET_CONSOLIDATED_ORIGIN_FORM,
	SET_CONSOLIDATED_DESTINATION_FORM,
	SET_CONSOLIDATED_SPECIALS,
	ConsolidatedActions,
	CANCEL_CONSOLIDATED,
	SET_OPTION_CONSOLIDATED,
	ID,
	SET_PICK_UP_CONSOLIDATED,
	SET_DELIVER_CONSOLIDATED,
	//TRIGGER_VALIDATE_BEFORE_CONSOLIDATED,
} from '../actions/ActionTypes';

type ConsolidatedState = {
	willBeConsolidated: boolean;
	currentCarriersConsolidated: string[];
	//when will select the service
	optionConsolidated: ID;

	useOriginRequirements: boolean;
	useDestinationRequirements: boolean;
	originRequirements: {
		maneuvers: string;
		three_meter_distance: string;
		pickup_description: string;
	} | null;
	destinationRequirements: {
		maneuvers: string;
		three_meter_distance: string;
		pickup_description: string;
	} | null;
	specials: [];
	selectedCarriers: string[];
};

const initialState: ConsolidatedState = {
	willBeConsolidated: false,
	currentCarriersConsolidated: [],
	originRequirements: null,
	destinationRequirements: null,
	optionConsolidated: '',
	specials: [],
	useOriginRequirements: false,
	useDestinationRequirements: false,
	selectedCarriers: []
};

const ConsolidatedReducer = (
	state: ConsolidatedState = initialState,
	action: ConsolidatedActions
): ConsolidatedState => {
	let nextState: ConsolidatedState;
	switch (action.type) {
		case RESET_CONSOLIDATED:
			nextState = produce(state, (draft) => {
				draft.willBeConsolidated = false;
				draft.currentCarriersConsolidated = [];
				draft.optionConsolidated = '';
				draft.originRequirements = null;
				draft.destinationRequirements = null;
				draft.specials = [];
				draft.selectedCarriers = [];
				draft.useDestinationRequirements = false;
				draft.useOriginRequirements = false;
			});
			break;
		case START_CONSOLIDATED:
			nextState = produce(state, (draft) => {
				draft.willBeConsolidated = true;
			});
			break;
		case CANCEL_CONSOLIDATED:
			nextState = produce(state, (draft) => {
				draft.willBeConsolidated = false;
			});
			break;
		case SET_CARRIERS_CONSOLIDATED:
			nextState = produce(state, (draft) => {
				draft.currentCarriersConsolidated = action.carriers;
			});
			break;
		case SET_CONSOLIDATED_ORIGIN_FORM:
			nextState = produce(state, (draft) => {
				draft.originRequirements = action.form;
			});
			break;
		case SET_CONSOLIDATED_DESTINATION_FORM:
			nextState = produce(state, (draft) => {
				draft.destinationRequirements = action.form;
			});
			break;
		case SET_CONSOLIDATED_SPECIALS:
			nextState = produce(state, (draft) => {
				draft.specials = action.form;
			});
			break;
		case SET_OPTION_CONSOLIDATED:
			nextState = produce(state, (draft) => {
				draft.optionConsolidated = action.option;
			});
			break;
		case SET_DELIVER_CONSOLIDATED:
			nextState = produce(state, (draft) => {
				draft.useDestinationRequirements = action.status;
			});
			break;
		case SET_PICK_UP_CONSOLIDATED:
			nextState = produce(state, (draft) => {
				draft.useOriginRequirements = action.status;
			});
			break;
		default:
			return state;
	}
	return nextState;
};

export default ConsolidatedReducer;
