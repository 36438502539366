import {
	CLEAR_ERROR_SIMULATED_USER,
	CustomerDetails,
	RESET_SIMULATED_USER,
	SET_SIMULATED_CUSTOMER_SIMULATED_USER,
	SET_SIMULATED_USER,
	TRIGGER_ERRORS_SIMULATED_USER,
	SimulatedUserActions,
	UserDetails,
} from '../ActionTypes';

export const setSimulatedUser = (user: UserDetails | null): SimulatedUserActions => {
	return { type: SET_SIMULATED_USER, user };
};

export const setSimulatedCustomer = (customer: CustomerDetails | null): SimulatedUserActions => {
	return { type: SET_SIMULATED_CUSTOMER_SIMULATED_USER, customer };
};

export const resetSimulatedUser = (): SimulatedUserActions => {
	return { type: RESET_SIMULATED_USER };
};

export const triggerErrorSimulatedUser = (): SimulatedUserActions => {
	return { type: TRIGGER_ERRORS_SIMULATED_USER }
}

export const clearErrorsSimulatedUser = (): SimulatedUserActions => {
	return { type: CLEAR_ERROR_SIMULATED_USER }
}