import { create } from 'zustand';
import { CustomerModality } from './actions/ActionTypes';
// import produce from 'immer';

type ShouldDisplayBalanceProps = {
	// isRootOrAgent: boolean;
	serviceDescription?: string;
	availableBalance: number | null;
	hasBalance: boolean;
};

type HasBalanceProps = { customer_modality?: CustomerModality; [key: string]: any } | null;

interface Balance {
	customerHasBalance: (customer: HasBalanceProps) => boolean;
	shouldDisplayBalance: (props: ShouldDisplayBalanceProps) => boolean;
}

const useBalanceStore = create<Balance>((set) => ({
	customerHasBalance: (customer: HasBalanceProps) =>
		Boolean(
			customer?.customer_modality &&
				(customer?.customer_modality === CustomerModality.BALANCE ||
					customer?.customer_modality === CustomerModality.MIXED)
		),
	shouldDisplayBalance: ({
		// isRootOrAgent,
		serviceDescription,
		availableBalance,
		hasBalance
	}: ShouldDisplayBalanceProps) =>
		hasBalance &&
		((serviceDescription && !serviceDescription?.includes('Prepago')) || !serviceDescription) &&
		availableBalance !== null &&
		availableBalance > 0
}));

export default useBalanceStore;
