import { mutate } from 'swr';
import { Address } from '../store/actions/ActionTypes';

export function reload(url: string, page: Page = 1, elements: number, args: any[] = []) {
	// console.log('reload', [url, page, elements, ...args]);
	mutate([url, page, elements, ...args]);
}

type Page = string | number;

export const CustomersPagination = {
	URL: '/api/customers',
	Table: 20,

	reloadTable: function (page: Page = 1) {
		reload(this.URL, page, this.Table);
	}
};

export const LabelsPagination = {
	URL: '/api/labels',
	Table: 80,

	reloadTable: function (page: Page = 1) {
		reload(this.URL, page, this.Table);
	}
};

export const AgentsPagination = {
	URL: '/api/agents',
	Table: 20,

	reloadTable: function (page: Page = 1, ...args: any[]) {
		reload(this.URL, page, this.Table, args);
	}
};

export const Items = {
	URL: '/api/items',
	InputSearch: 250,
	Table: 20,

	reloadTable: function (page: Page = 1, ...args: any[]) {
		reload(this.URL, page, this.Table, args);
	},
	reloadInputSearch: function (page: Page = 1) {
		reload(this.URL, page, this.InputSearch);
	}
};

export const PickupPagination = {
	Table: 10
};

export const AddressesPagination = {
	URL: '/api/addresses',
	InputSearch: 800,
	Table: 20,

	Transformer: (addr: any): Address => ({
		id: addr.address.id,
		address: addr.address.address,
		alias: addr.address.alias,
		scope:
			addr.scope.scope === 'global' ? 'global' : addr.scope.scope === 'user' ? 'Personal' : 'group',
		coordinates: addr.address.coordinates,
		street: addr.address.street,
		number: addr.address.number,
		suite_number: addr.address.suite_number,
		city: addr.address.city,
		business_name: addr.address.business_name,
		neighborhood: addr.address.neighborhood,
		state: addr.address.state,
		zip_code: addr.address.zip_code,
		country: addr.address.country,
		tax_id: addr.address.tax_id,
		contacts: addr.address.contacts,
		reference: addr.address.reference,
		reference_streets: addr.address.reference_streets,
		customer: { id: addr.scope.customer.id, name: addr.scope.customer.name },
		owner: addr.scope.owner ? { id: addr.scope.owner.id, name: addr.scope.owner.name } : null
	}),

	reloadTable: function (page: Page = 1, ...args: any[]) {
		reload(this.URL, page, this.Table, args);
	},
	reloadInputSearch: function (page: Page = 1) {
		reload(this.URL, page, this.InputSearch);
	}
};
