import { Dispatch, SetStateAction, createContext } from 'react';

export type rdrawstatus = 'INITIAL' | 'MAPS' | 'SUMMARY_ADDRESS' | 'HIDDEN';
export type SnackBarMessage = {
	message: string;
	severity?: 'warning' | 'error' | 'info' | 'success';
	time?: number;
};
interface Params {
	mobileLeftDrawerStatus: boolean;
	toggleLeftDrawer: () => void;

	mobileRigthDrawerStatus: boolean;
	toggleRightDrawer: () => void;

	helpDialogStatus: boolean;
	toggleHelpDialogStatus: () => void;

	rCardStatus: rdrawstatus;
	setRCardStatus: Dispatch<SetStateAction<rdrawstatus>>;

	notification: SnackBarMessage;
	setNotification: Dispatch<SetStateAction<SnackBarMessage>>;

	showNotification: boolean;
	setShowNotification: Dispatch<SetStateAction<boolean>>;

	showBarLogo: boolean;
	toggleBarLogo: (a: boolean) => void;

	availableBalance: number | null;
	setAvailableBalance: Dispatch<SetStateAction<number | null>>;
}

/**
 * This context defines the status of
 * TopNav
 * Left sidebar
 * Rigth sidebar
 * Rigth Down sidebar
 */

const DrawContext = createContext<Params>({
	mobileLeftDrawerStatus: false,
	mobileRigthDrawerStatus: false,
	toggleLeftDrawer: () => {},
	toggleRightDrawer: () => {},
	helpDialogStatus: false,
	toggleHelpDialogStatus: () => {},
	rCardStatus: 'HIDDEN',
	setRCardStatus: () => {},
	notification: { message: '' },
	setNotification: () => {},
	showNotification: false,
	setShowNotification: () => {},
	showBarLogo: false,
	toggleBarLogo: () => {},
	availableBalance: null,
	setAvailableBalance: () => {}
});

export default DrawContext;
