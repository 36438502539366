import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import LoaderPage from './pages/loader/LoaderPage';

import GlobalContext from './context/_GlobalContext';

import mainStore from './store/store';


ReactDOM.render(
	<Suspense fallback={<LoaderPage />}>
		<Provider store={mainStore}>
			<GlobalContext>
				<App />
			</GlobalContext>
		</Provider>
	</Suspense>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
