import React, { useContext, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { alpha, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import {
	AppBar,
	Avatar,
	Toolbar,
	IconButton,
	MenuItem,
	Menu,
	Tooltip
	// Divider
} from '@material-ui/core/';
import { Menu as MenuIcon, MoreVertical as MoreIcon } from 'react-feather';

import NotificationTopNav from './TopNavComponents/NotificationTopNav';
import CustomerSelector from '~components/Inputs/CustomerSelector';

import AuthContext from '~context/AuthContext';
import DrawContext from '~context/DrawContext';
import { resetFlow } from '~store/actions';
import usePermissions from '~hooks/usePermissions';
import instanceAPI, { instanceRate, instancePayment } from '~util/axios';
import logo from '~assets/logo.svg';
import useNotificationStore from '~store/newstore/useNotificationStore';
import { shallow } from 'zustand/shallow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { TextOne } from '~components/Headings';
import { formatPrice } from '~util/formatPrice';
import axiosInstance from '~util/axios';
import { useSelector } from '~store/store';
import useBalanceStore from '~store/useBalanceStore';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		grow: {
			//flexGrow: 1,
			zIndex: 12
		},
		helpButton: {
			textTransform: 'capitalize',
			color: 'white',
			borderRadius: 20,
			height: 44,
			marginTop: 10,
			marginRight: 10
		},
		menuButton: {
			marginRight: theme.spacing(2),
			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		},
		brand: {
			//flexGrow: 0.8,
			display: 'none',
			[theme.breakpoints.up('sm')]: {
				display: 'block',
				zIndex: 9
			}
		},
		search: {
			position: 'relative',
			borderRadius: theme.spacing(3),
			backgroundColor: alpha(theme.palette.common.white, 0.15),
			'&:hover': {
				backgroundColor: alpha(theme.palette.common.white, 0.25)
			},
			marginLeft: 0,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(1),
				width: '500px'
			}
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		inputRoot: {
			color: 'inherit'
		},
		inputInput: {
			height: 30,
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create('width'),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: '20ch'
			}
		},
		sectionDesktop: {
			display: 'none',
			[theme.breakpoints.up('md')]: {
				display: 'flex'
			}
		},
		sectionMobile: {
			display: 'flex',
			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		},
		appBar: {
			backgroundColor: '#181818',
			boxShadow: 'none',
			'&>div': {
				display: 'flex',
				alignItems: 'center',
				minHeight: '72px'
			}
		},
		avatar: {
			backgroundColor: theme.palette.primary.main
		},
		customerSelector: {
			width: '100%'
		},
		balanceData: {
			marginRight: theme.spacing(1),
			display: 'flex',
			alignItems: 'flex-start',
			// color: theme.palette.primary.main,
			'& svg': {
				marginRight: theme.spacing(1),
				fontSize: '20px'
			},
			'& p': {
				fontWeight: 'bold'
			}
		}
	})
);

interface Props {
	currentTheme: boolean;
	toggleTheme: () => void;
}

const TopNav: React.FC<Props> = (props) => {
	const classes = useStyles();
	const history = useHistory();

	const { setAuth, currentUser, clearUser } = useContext(AuthContext);
	const { toggleLeftDrawer, showBarLogo, availableBalance, setAvailableBalance, setNotification } =
		useContext(DrawContext);
	const { isCustomer, isRootOrAgent, isSeller } = usePermissions();
	const { simulatedCustomer, simulatedUser } = useSelector((state) => state.simulatedUser);
	const dispatch = useDispatch();

	let matchClients = useRouteMatch('/clientes');
	let matchClient = useRouteMatch('/cliente');
	let matchAgent = useRouteMatch('/agente');
	let matchAgents = useRouteMatch('/agentes');
	let matchOrder = useRouteMatch('/orden');
	let matchSummary = useRouteMatch('/resultados');
	let matchRate = useRouteMatch('/tarifas');
	let matchFiscalForm = useRouteMatch('/razon-social');
	let matchFiscalSummary = useRouteMatch('/resumen-razon-social');
	let matchGroupForm = useRouteMatch('/grupo');
	let matchProspects = useRouteMatch('/prospectos');
	let matchProspect = useRouteMatch('/prospecto');
	let matchDispersion = useRouteMatch('/dispersion');

	const matchRoutes = useMemo(
		() =>
			Boolean(
				matchClients ||
					matchAgents ||
					matchOrder ||
					matchSummary ||
					matchAgent ||
					matchClient ||
					matchRate ||
					matchFiscalForm ||
					matchFiscalSummary ||
					matchGroupForm ||
					matchProspects ||
					matchProspect ||
					matchDispersion ||
					history.location.pathname === '/'
			),
		[
			matchClients,
			matchAgents,
			matchOrder,
			matchSummary,
			matchAgent,
			matchClient,
			matchRate,
			matchFiscalForm,
			matchFiscalSummary,
			matchGroupForm,
			matchProspects,
			matchProspect,
			matchDispersion,
			history.location.pathname
		]
	);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
	const { customerHasBalance } = useBalanceStore();

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const [clearNotifications] = useNotificationStore(
		(state) => [state.clearNotificationsQueue],
		shallow
	);

	const getUserBalance = useCallback(async () => {
		try {
			const { data } = await axiosInstance.get('/api/dispersion/balance');
			// if it is normal user without permission to disperse, it will see his balance
			// if it is admin user or user with permission to disperse, it will see his balance
			setAvailableBalance(data.remaining);
		} catch (error) {
			setNotification({
				message: 'Hubo un error al obtener el saldo disponible',
				severity: 'error'
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setAvailableBalance]);

	// Get user balance to nav info
	useEffect(() => {
		if (!!currentUser && customerHasBalance(currentUser?.customer) && !isRootOrAgent) {
			getUserBalance();
		}
	}, [currentUser, getUserBalance, isRootOrAgent, customerHasBalance]);

	const menuTop = useCallback(() => {
		return (
			<div>
				<MenuItem disabled style={{ opacity: 'unset' }}>
					{currentUser.name}
				</MenuItem>
				{/* {isCustomer && (
					<MenuItem
						onClick={() => {
							history.push(`/cliente/datos/${currentUser?.customer?.id}`);
							handleMenuClose();
						}}
					>
						Empresa
					</MenuItem>
				)} */}
				{/* {!isRootOrAgent && isCustomer && (
					<>
						<Divider />
						<MenuItem
							onClick={() => {
								history.push('/perfil/datos');
								handleMenuClose();
							}}
						>
							Datos generales
						</MenuItem>
						<MenuItem
							onClick={() => {
								history.push('/perfil/tarifas');
								handleMenuClose();
							}}
						>
							Tarifas
						</MenuItem>
						<MenuItem
							onClick={() => {
								history.push('/perfil/documentos');
								handleMenuClose();
							}}
						>
							Documentos
						</MenuItem>
						<MenuItem
							onClick={() => {
								history.push('/perfil/contrato');
								handleMenuClose();
							}}
						>
							Contrato
						</MenuItem>
						<Divider />
					</>
				)} */}
				<MenuItem
					onClick={() => {
						delete instanceAPI.defaults.headers.common['Authorization'];
						delete instanceRate.defaults.headers.common['Authorization'];
						delete instancePayment.defaults.headers.common['Authorization'];
						localStorage.removeItem('auth');
						setAuth(false);
						clearNotifications();
						clearUser();
					}}
				>
					Salir
				</MenuItem>
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, setAuth, clearUser, isCustomer]);

	const userMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			keepMounted
			transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
			style={{ top: '42px' }}
		>
			{menuTop()}
		</Menu>
	);

	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			keepMounted
			transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			{menuTop()}
		</Menu>
	);

	// Top Nav
	return (
		<div className={classes.grow}>
			<AppBar position='fixed' color='inherit' className={classes.appBar}>
				<Toolbar>
					<IconButton
						edge='start'
						className={classes.menuButton}
						color='inherit'
						onClick={() => toggleLeftDrawer()}
					>
						<MenuIcon />
					</IconButton>
					<div className={classes.brand} onClick={() => dispatch(resetFlow())}>
						<Link to='/'>
							<img
								style={{ visibility: showBarLogo ? 'initial' : 'hidden' }}
								src={logo}
								alt={logo}
								height='45px'
							/>
						</Link>
					</div>
					<div className={classes.customerSelector}>
						{/* Simulated customer and user for roots or agents */}
						{(isRootOrAgent || isSeller) && <CustomerSelector matchRoutes={matchRoutes} />}
					</div>
					{/* Available balance */}
					{((!isRootOrAgent && customerHasBalance(currentUser?.customer)) ||
						(isRootOrAgent && customerHasBalance(simulatedUser?.customer ?? simulatedCustomer))) &&
						availableBalance !== null && (
							<Tooltip title='Saldo disponible'>
								<div className={classes.balanceData}>
									<FontAwesomeIcon icon={faWallet} />
									<TextOne text={formatPrice(availableBalance)} />
								</div>
							</Tooltip>
						)}
					<div className={classes.sectionDesktop}>
						{/* Notification */}
						<NotificationTopNav />
						<IconButton edge='end' onClick={handleProfileMenuOpen} color='inherit'>
							<Avatar className={classes.avatar}>{currentUser.name.charAt(0)}</Avatar>
						</IconButton>
					</div>
					<div className={classes.sectionMobile}>
						<IconButton onClick={handleMobileMenuOpen} color='inherit'>
							<MoreIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
			{userMenu}
		</div>
	);
};

export default TopNav;
