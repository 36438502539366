import produce from 'immer';
import {
	AppActions,
	Package,
	SET_MAPS_COORDINATES,
	SET_DESTINATION_COORDINATES,
	RESET_PACKAGE_TO_EDIT,
	WILL_EDIT_PACKAGE,
	SET_GOOGLE_MAPS_SCRIPT,
	SET_GOOGLE_MAP,
	SET_GOOGLE_MAPS_MARKER,
	SET_GOOGLE_MAPS_DESTINATION_MARKER,
	SET_GOOGLE_MAPS_POLYLINE,
} from '../actions/ActionTypes';

type AppState = {
	//when clic on edit
	editingPackage: Package | null;
	// to handle coordinates
	addressCoordinates: { lat: number; lng: number } | null;
	destinationCoordinates: { lat: number; lng: number } | null;
	// if scripts loads
	isLoadedGoogleMapsScript: boolean;
	// googlemaps
	map: google.maps.Map<HTMLDivElement> | null;
	// marker of goolge maps
	marker: google.maps.Marker | null;
	destinationMarker: google.maps.Marker | null;
	// line
	pathPolyline: google.maps.Polyline | null;
};

const initialState: AppState = {
	addressCoordinates: null,
	destinationCoordinates: null,
	editingPackage: null,
	isLoadedGoogleMapsScript: false,
	map: null,
	marker: null,
	destinationMarker: null,
	pathPolyline: null,
};

const AppReducer = (state: AppState = initialState, action: AppActions): AppState => {
	let nextState: AppState;
	switch (action.type) {
		case SET_MAPS_COORDINATES:
			nextState = produce(state, (draft) => {
				draft.addressCoordinates = action.coordinates;
			});
			break;
		case SET_DESTINATION_COORDINATES:
			nextState = produce(state, (draft) => {
				draft.destinationCoordinates = action.coordinates;
			});
			break;
		case WILL_EDIT_PACKAGE:
			nextState = produce(state, (draft) => {
				draft.editingPackage = action.pkg;
			});
			break;
		case RESET_PACKAGE_TO_EDIT:
			nextState = produce(state, (draft) => {
				draft.editingPackage = null;
			});
			break;
		case SET_GOOGLE_MAPS_SCRIPT:
			nextState = produce(state, (draft) => {
				draft.isLoadedGoogleMapsScript = action.status;
			});
			break;
		case SET_GOOGLE_MAP:
			nextState = produce(state, (draft) => {
				draft.map = action.map;
			});
			break;
		case SET_GOOGLE_MAPS_MARKER:
			nextState = produce(state, (draft) => {
				draft.marker = action.marker;
			});
			break;
		case SET_GOOGLE_MAPS_DESTINATION_MARKER:
			nextState = produce(state, (draft) => {
				draft.destinationMarker = action.marker;
			});
			break;
		case SET_GOOGLE_MAPS_POLYLINE:
			nextState = produce(state, (draft) => {
				draft.pathPolyline = action.path;
			});
			break;
		default:
			return state;
	}
	return nextState;
};

export default AppReducer;
