import React, { useContext } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Drawer, SwipeableDrawer } from '@material-ui/core';
import { Bell } from 'react-feather';
import { Hidden } from '@mui/material';

import RCard from '../Cards/RCard';

import DrawContext from '../../context/DrawContext';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperRightDrawer: {
			top: '10%',
			height: '90%',
			backgroundColor: 'transparent',
			border: 'none',
			'& > div': {
				boxShadow: 'none'
			}
		},
		rightDrawButton: {
			position: 'fixed',
			right: 0,
			//bottom: '50%'
			top: '12%',
			maxHeight: 490,
			height: '80%',
			backgroundColor: theme.palette.background.paper,
			borderRadius: '14px 0 0 14px'
		}
	})
);

/**
 * Menú de la derecha
 */

const RDrawer: React.FC = () => {
	const classes = useStyles();

	const { mobileRigthDrawerStatus, toggleRightDrawer } = useContext(DrawContext);

	return (
		<React.Fragment>
			{/* Laptop */}
			<Hidden lgUp>
				<Button onClick={toggleRightDrawer} className={classes.rightDrawButton}>
					<Bell />
				</Button>
				<SwipeableDrawer
					classes={{ paper: classes.paperRightDrawer }}
					anchor='right'
					open={mobileRigthDrawerStatus}
					onClose={toggleRightDrawer}
					onOpen={toggleRightDrawer}
				>
					<RCard />
				</SwipeableDrawer>
			</Hidden>

			{/* Desktop */}
			<Hidden lgDown>
				<Drawer
					classes={{ paper: classes.paperRightDrawer }}
					anchor='right'
					variant='permanent'
					open={true}
				>
					<RCard />
				</Drawer>
			</Hidden>
		</React.Fragment>
	);
};

export default RDrawer;
