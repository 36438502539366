import { Dispatch } from 'react';
import {
	ADD_PACKAGE_SEND,
	ADD_MERCHANDISE_PACKAGE_SEND,
	CAN_CONTINUE_TO_RATE_SEND,
	CAN_CONTINUE_TO_SUMMARY_SEND,
	SET_ORIGIN_ADDRESS_SEND,
	SET_DESTINATION_ADDRESS_SEND,
	SET_ORIGIN_CONTACT_SEND,
	SET_DESTINATION_CONTACT_SEND,
	REMOVE_PACKAGE_SEND,
	SET_SERVICE_SEND,
	SWIPE_ADDRESS_SEND,
	TRIGGER_VALIDATE_BEFORE_RATE_SEND,
	UPDATE_COLUMN_PACKAGE_SEND,
	SET_ADDRESS_QUERY,
	AddressRequest,
	SendActions,
	Contact,
	Package,
	ID,
	ServiceRequest,
	PayloadPackageRequest,
	RESET_SEND,
	RESET_SERVICE_SEND,
	SET_FORM_PACKAGES_VALID_SEND,
	SET_INSURANCE_ON_SUMMARY,
	SET_FORMATTED_ADDRESS,
	SET_ESTAFETA_OUTPUT_TYPE,
	SET_SAVE_ESTAFETA_OUTPUT_TYPE,
	SET_EXTRA_INFORMATION,
	SET_NOTIFICATIONS,
	NotificationContacts,
	NewBusiness,
	Channel
} from './ActionTypes';

export const setAddressSend =
	(address: AddressRequest | null, target: 'destination' | 'origin', contacts: Contact[]) =>
	(dispatch: Dispatch<SendActions>) => {
		if (target === 'destination') {
			dispatch({ type: SET_DESTINATION_ADDRESS_SEND, address, contacts });
		} else {
			dispatch({ type: SET_ORIGIN_ADDRESS_SEND, address, contacts });
		}
		dispatch({ type: CAN_CONTINUE_TO_RATE_SEND });
	};

export const setContactSend =
	(contact: Contact | null, target: 'destination' | 'origin') =>
	(dispatch: Dispatch<SendActions>) => {
		if (target === 'destination') {
			dispatch({ type: SET_DESTINATION_CONTACT_SEND, contact });
		} else {
			dispatch({ type: SET_ORIGIN_CONTACT_SEND, contact });
		}
		dispatch({ type: CAN_CONTINUE_TO_RATE_SEND });
	};

export const addPkgSend = (pkg: Package) => (dispatch: Dispatch<SendActions>) => {
	dispatch({ type: ADD_PACKAGE_SEND, pkg });
	dispatch({ type: CAN_CONTINUE_TO_RATE_SEND });
};

export const removePackageSend = (id: ID) => (dispatch: Dispatch<SendActions>) => {
	dispatch({ type: REMOVE_PACKAGE_SEND, id });
	dispatch({ type: CAN_CONTINUE_TO_RATE_SEND });
};
export const updatePkgSend = (
	pkg_id: string | number,
	payload: PayloadPackageRequest,
	merchandiseId?: string
): SendActions => {
	return { type: UPDATE_COLUMN_PACKAGE_SEND, pkg_id, payload, merchandiseId };
};
export const addMerchandisePkgSend = (pkg_id: string | number, payload: Merchandise) => {
	return { type: ADD_MERCHANDISE_PACKAGE_SEND, pkg_id, payload };
};
export const setServiceSend = (service: ServiceRequest) => (dispatch: Dispatch<SendActions>) => {
	dispatch({ type: SET_SERVICE_SEND, service });
	dispatch({ type: CAN_CONTINUE_TO_SUMMARY_SEND });
};

export const swipeAddressSend = (): SendActions => {
	return { type: SWIPE_ADDRESS_SEND };
};

export const triggerValidationsSend = (status: boolean): SendActions => {
	return { type: TRIGGER_VALIDATE_BEFORE_RATE_SEND, status };
};

export const setValidateFormPkgsSend = (status: boolean) => (dispatch: Dispatch<SendActions>) => {
	dispatch({ type: SET_FORM_PACKAGES_VALID_SEND, status });
	dispatch({ type: CAN_CONTINUE_TO_RATE_SEND });
};

export const resetSend = (): SendActions => {
	return { type: RESET_SEND };
};

export const setAddressQuery = (
	query: string | null,
	target: 'origin' | 'destination'
): SendActions => {
	return {
		type: SET_ADDRESS_QUERY,
		query: query,
		target: target
	};
};

export const resetServiceSend = (): SendActions => {
	return { type: RESET_SERVICE_SEND };
};

/**
 * To show the insurance on the summary
 */
export const setInsuranceOnSummary = (status: boolean) => {
	return { type: SET_INSURANCE_ON_SUMMARY, status };
};

export const setFormattedAddresses = (origin: any, destination: any) => {
	return { type: SET_FORMATTED_ADDRESS, origin, destination };
};

export const setExtraInformation = (
	reference: string,
	additionalInfo: any,
	business: NewBusiness,
	channel: Channel
) => {
	if (business) {
		return { type: SET_EXTRA_INFORMATION, reference, additionalInfo, business, channel };
	} else {
		return { type: SET_EXTRA_INFORMATION, reference, additionalInfo, channel };
	}
};

export const setNotificationsContacts = (notifications: NotificationContacts) => {
	return { type: SET_NOTIFICATIONS, notifications };
};

export const setEstafetaOutputType = (outputType: string | null) => {
	return { type: SET_ESTAFETA_OUTPUT_TYPE, outputType: outputType };
};

export const setSaveEstafetaOutputType = (save: boolean) => {
	return { type: SET_SAVE_ESTAFETA_OUTPUT_TYPE, save: save };
};
