import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { radiusCard } from '~hooks/useStyles';

export const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(1)
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(4),
			top: theme.spacing(4),
			color: theme.palette.grey[500]
		}
	});

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			backgroundColor: theme.palette.background.paper,
			borderRadius: radiusCard,
			padding: theme.spacing(3),
			marginTop: 20,
			'&>h4': {
				margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
				width: '100%',
				textAlign: 'left'
			}
		},
		sectionTitle: {
			fontSize: '18px'
		},
		leftSide: {
			padding: `0 ${theme.spacing(2)}px`
		},
		rightSide: {
			padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(5)}px`
		},
		box: {
			padding: `${theme.spacing(2)}px 0`,
			'&>h5': {
				display: 'flex',
				alignItems: 'center',
				gap: '4px'
			}
		},
		guideIdBox: {
			fontWeight: 400,
			paddingTop: '4px'
		},
		guideData: {
			paddingLeft: theme.spacing(1),
			fontSize: '14px',
			'&>p': {
				display: 'flex',
				alignItems: 'center',
				margin: 0,
				color: 'rgba(255, 255, 255, 0.7)',
				'&>span': {
					fontWeight: 700,
					marginLeft: '4px',
					color: '#FFF'
				},
				'&>svg': {
					marginRight: '12px',
					width: '18px'
				}
			}
		},
		boxWithBorder: {
			border: '1px solid rgba(255, 255, 255, 0.2)',
			filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08))',
			borderRadius: '8px',
			padding: theme.spacing(2),
			margin: `${theme.spacing(2)}px 0`,

			'& p': {
				fontWeight: 500,
				fontSize: '16px'
			}
		},
		signImg: {
			width: '100%',
			background: '#f2f2f2',
			objectFit: 'contain',
			marginTop: theme.spacing(2)
		},
		signFile: {
			width: '100%',
			margin: 0,
			'& > li': {
				margin: 0,
				padding: 0
			}
		},
		package: {
			padding: `${theme.spacing(2)}px 0 0 0`,
			'&>p': {
				color: 'rgba(255, 255, 255, 0.7)',
				marginBottom: 0
			},
			'& span': {
				marginLeft: '4px',
				fontWeight: 700,
				color: '#fff'
			},
			'& div': {
				fontWeight: 700,
				color: '#fff'
			}
		},
		merchandise: {
			backgroundColor: '#181818',
			'& td, th': {
				border: 'none !important'
			},
			'& th': {
				padding: '8px 16px'
			},
			borderRadius: '8px'
		},
		card: {
			padding: theme.spacing(4),
			marginTop: theme.spacing(4)
		},
		tableHead: {
			fontSize: '12px',
			color: '#ffffffb3'
		},
		address: {
			'&>div': {
				padding: `${theme.spacing(2)}px 0`
			},
			'& h5': {
				fontSize: '20px'
			}
		},
		files: {
			paddingTop: theme.spacing(2),
			'& table td:nth-child(2)': {
				display: 'flex',
				justifyContent: 'flex-end',
				'& svg': {
					backgroundColor: '#919eab29',
					padding: '6px',
					borderRadius: '50px',
					width: '32px',
					height: '32px',
					cursor: 'pointer',
					color: '#fff'
				}
			},
			'& th, td': {
				borderBottom: '1px solid #ffffff1a',
				boxShadow: 'none'
			}
		},
		btns: {
			minWidth: '230px',
			background: 'linear-gradient(180deg, #57BBD8 0%, #0088D5 100%), #57BBD8',
			color: 'white',
			'&:disabled': {
				color: '#f2f2f2',
				opacity: 0.7
			},
			padding: '10px 24px'
		},
		immediateBillingBtn: {
			marginTop: '10px'
		}
	})
);
