const loadGMaps = (callback: () => void) => {
	const existingScript = document.getElementById('googleMaps');
	if (!existingScript) {
		const script = document.createElement('script');
		script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA9mQ-ghQDAssm31Tonf5dCG0ln0SNXyu0&libraries=places';
		script.id = 'googleMaps';
		document.body.appendChild(script);
		script.onload = () => {
			if (callback) callback();
		};
	}
	// if (existingScript && callback) callback();
};
export default loadGMaps;
