import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Divider, Grid } from '@material-ui/core/';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { ChevronDown as ExpandMoreIcon } from 'react-feather';
import { useSelector } from 'react-redux';
import { RootStore } from '../../store/store';
import AddressSummaryCard from '../Cards/AddressSummaryCard';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			backgroundColor: 'transparent',
			'&>*': {
				backgroundColor: 'transparent',
				boxShadow: 'none'
			}
		},
		heading: {
			fontSize: theme.typography.pxToRem(15)
			// flexBasis: '33.33%',
			// flexShrink: 0,
		},
		secondaryHeading: {
			fontSize: theme.typography.pxToRem(15),
			color: theme.palette.text.secondary
		},
		addressCard: {
			'& div': {
				backgroundColor: 'transparent !important'
			}
		}
	})
);

const AddressCollapse = () => {
	const classes = useStyles();

	const { currentFlow } = useSelector((state: RootStore) => state.flow);
	const { originAddressSend, destinationAddressSend, currentPackagesSend } = useSelector(
		(state: RootStore) => state.send
	);
	const { originAddressRate, destinationAddressRate, currentPackagesRate } = useSelector(
		(state: RootStore) => state.rate
	);
	const { addressPickUp } = useSelector((state: RootStore) => state.pickup);

	const [expanded, setExpanded] = React.useState<string | false>(false);

	// ===== pickup
	useEffect(() => {
		if (addressPickUp && currentFlow === 'pickup') {
			setExpanded('panel1');
		}
	}, [addressPickUp, currentFlow]);

	// ======== Rate
	useEffect(() => {
		if (originAddressRate && currentFlow === 'rate') {
			setExpanded('panel1');
		}
	}, [originAddressRate, currentFlow]);

	useEffect(() => {
		if (destinationAddressRate && currentFlow === 'rate') {
			setExpanded('panel2');
		}
	}, [destinationAddressRate, currentFlow]);

	useEffect(() => {
		if (currentPackagesRate.length > 0 && currentFlow === 'rate') {
			setExpanded('panel3');
		}
	}, [currentPackagesRate.length, currentFlow]);

	// ======= Send

	useEffect(() => {
		if (originAddressSend && currentFlow === 'send') {
			setExpanded('panel1');
		}
	}, [originAddressSend, currentFlow]);

	useEffect(() => {
		if (destinationAddressSend && currentFlow === 'send') {
			setExpanded('panel2');
		}
	}, [destinationAddressSend, currentFlow]);

	useEffect(() => {
		if (currentPackagesSend.length > 0 && currentFlow === 'send') {
			setExpanded('panel3');
		}
	}, [currentPackagesSend.length, currentFlow]);

	const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<div className={classes.root}>
			<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography className={classes.heading}>
						<b>Dirección {currentFlow !== 'pickup' ? 'Origen' : null}</b>
					</Typography>
					{/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
				</AccordionSummary>
				<AccordionDetails className={classes.addressCard}>
					{originAddressSend && currentFlow === 'send' && (
						<AddressSummaryCard showTitle={false} type='Origen' address={originAddressSend} />
					)}
					{originAddressRate && currentFlow === 'rate' && (
						<AddressSummaryCard showTitle={false} type='Origen' address={originAddressRate} />
					)}
					{addressPickUp && currentFlow === 'pickup' && (
						<AddressSummaryCard showTitle={false} type='Origen' address={addressPickUp} />
					)}
				</AccordionDetails>
			</Accordion>
			{currentFlow !== 'pickup' && (
				<Accordion
					expanded={expanded === 'panel2'}
					onChange={handleChange('panel2')}
					className={classes.addressCard}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography className={classes.heading}>
							<b>Dirección Destino</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{destinationAddressSend && currentFlow === 'send' && (
							<AddressSummaryCard
								showTitle={false}
								type='Origen'
								address={destinationAddressSend}
							/>
						)}
						{destinationAddressRate && currentFlow === 'rate' && (
							<AddressSummaryCard
								showTitle={false}
								type='Origen'
								address={destinationAddressRate}
							/>
						)}
					</AccordionDetails>
				</Accordion>
			)}
			{currentFlow !== 'pickup' && (
				<Accordion
					expanded={expanded === 'panel3'}
					onChange={handleChange('panel3')}
					className={classes.addressCard}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography className={classes.heading}>
							<b>Empaques</b>
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.addressCard}>
						<Grid container>
							{(currentFlow === 'send' ? currentPackagesSend : currentPackagesRate).map((p, i) => {
								return (
									<Grid item xs={12} style={{ marginBottom: 15 }} key={i}>
										<Grid item xs={12}>
											<b>Paquete #{i + 1}</b>
										</Grid>
										<Grid item xs={12}>
											{p.quantity} - {`${p.alias ? p.alias : 'Artículo'}`}
										</Grid>
										<Grid item xs={12}>
											{`${p.length}x${p.width}x${p.height} `} | {Math.ceil(p.weight)} Kg
										</Grid>
										<Grid item xs={12}>
											<Divider style={{ width: '100%', height: 2, marginTop: 10 }} />
										</Grid>
									</Grid>
								);
							})}
						</Grid>
					</AccordionDetails>
				</Accordion>
			)}
		</div>
	);
};

export default AddressCollapse;
