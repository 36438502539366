type IconColor = {
	color?: string;
};

export function SendIcon({ color }: IconColor) {
	return (
		<svg width={24} height={26} fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2.382 6.637 21.902.573c1.242-.385 2.398.82 2.028 2.111l-5.824 20.34c-.438 1.533-2.483 1.64-3.068.162l-3.456-8.734a.742.742 0 0 0 .245-.17l4.286-4.464a.8.8 0 0 0 0-1.1.725.725 0 0 0-1.056 0l-4.287 4.464a.764.764 0 0 0-.162.253L2.227 9.837c-1.422-.61-1.32-2.743.155-3.2ZM.22 16.9a.727.727 0 0 0 1.056 0l2.377-2.475a.8.8 0 0 0 0-1.1.725.725 0 0 0-1.057 0L.22 15.8a.8.8 0 0 0 0 1.1ZM2.144 23.267a.73.73 0 0 0 1.056.001l4.472-4.658a.8.8 0 0 0 0-1.1.725.725 0 0 0-1.057 0l-4.471 4.657a.8.8 0 0 0 0 1.1ZM8.784 25.5a.73.73 0 0 1-.528-.228.8.8 0 0 1 0-1.1l2.377-2.477a.725.725 0 0 1 1.057 0 .8.8 0 0 1 0 1.1l-2.377 2.477a.727.727 0 0 1-.529.228Z'
				fill={color || '#fff'}
			/>
		</svg>
	);
}

export function RateIcon({ color }: IconColor) {
	return (
		<svg width={24} height={26} fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M19.826 0H4.174a4.185 4.185 0 0 0-2.95 1.22A4.157 4.157 0 0 0 0 4.16v17.68a4.158 4.158 0 0 0 1.224 2.94A4.186 4.186 0 0 0 4.174 26h15.652a4.186 4.186 0 0 0 2.95-1.22A4.158 4.158 0 0 0 24 21.84V4.16a4.158 4.158 0 0 0-1.224-2.94A4.186 4.186 0 0 0 19.826 0ZM5.74 22.88a2.615 2.615 0 0 1-2.41-1.605 2.592 2.592 0 0 1 .565-2.833 2.611 2.611 0 0 1 2.843-.564 2.607 2.607 0 0 1 1.61 2.402c0 .69-.275 1.35-.764 1.838a2.616 2.616 0 0 1-1.844.762Zm0-6.24a2.615 2.615 0 0 1-2.41-1.605 2.592 2.592 0 0 1 .565-2.834 2.611 2.611 0 0 1 2.843-.563 2.594 2.594 0 0 1 .846 4.24 2.616 2.616 0 0 1-1.844.762ZM12 22.88a2.615 2.615 0 0 1-2.41-1.605 2.592 2.592 0 0 1 .565-2.833 2.61 2.61 0 0 1 2.843-.564 2.607 2.607 0 0 1 1.61 2.402c0 .69-.275 1.35-.764 1.838A2.616 2.616 0 0 1 12 22.88Zm0-6.24a2.615 2.615 0 0 1-2.41-1.605 2.592 2.592 0 0 1 .565-2.834 2.61 2.61 0 0 1 2.843-.563 2.594 2.594 0 0 1 .845 4.24A2.616 2.616 0 0 1 12 16.64Zm8.87 3.64c0 .69-.275 1.35-.765 1.838a2.613 2.613 0 0 1-3.689 0 2.596 2.596 0 0 1-.764-1.838v-6.24c0-.69.275-1.35.764-1.838a2.613 2.613 0 0 1 3.69 0c.489.487.764 1.148.764 1.838v6.24Zm0-13c-.001.551-.221 1.08-.612 1.47-.392.39-.922.61-1.475.61H5.217c-.553 0-1.083-.22-1.475-.61a2.079 2.079 0 0 1-.612-1.47V5.2c.001-.551.221-1.08.612-1.47.392-.39.922-.61 1.475-.61h13.566c.553 0 1.083.22 1.475.61.39.39.61.919.612 1.47v2.08Z'
				fill={color || '#fff'}
			/>
		</svg>
	);
}

export function PickupIcon({ color }: IconColor) {
	return (
		<svg width={26} height={24} fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M25.872 12.335 23.056 8.25l2.816-4.084a.741.741 0 0 0 .087-.66.754.754 0 0 0-.48-.467l-9.191-3a.764.764 0 0 0-.875.295L13 3.9 10.587.335A.764.764 0 0 0 9.712.04l-9.191 3a.755.755 0 0 0-.48.468.741.741 0 0 0 .087.659l2.816 4.084-2.816 4.084a.741.741 0 0 0-.087.659.755.755 0 0 0 .48.468l9.191 3a.768.768 0 0 0 .875-.296L13 12.603l2.413 3.563a.767.767 0 0 0 .875.296l9.191-3a.755.755 0 0 0 .48-.468.741.741 0 0 0-.087-.66ZM13 10.46 6.267 8.25 13 6.042l6.733 2.21L13 10.46Z'
				fill={color || '#fff'}
			/>
			<path
				d='M16.77 17.885a2.303 2.303 0 0 1-2.624-.887L13 15.307l-1.146 1.691a2.302 2.302 0 0 1-2.628.885l-6.128-2.011v4.378c0 .323.21.61.52.711l9.137 2.999A.71.71 0 0 0 13 24a.785.785 0 0 0 .245-.04l9.137-2.999a.75.75 0 0 0 .52-.71v-4.379l-6.132 2.013Z'
				fill={color || '#fff'}
			/>
		</svg>
	);
}

export function HistoryIcon({ color }: IconColor) {
	return (
		<svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M21.6 2.4h-1.2V1.2c0-.72-.48-1.2-1.2-1.2S18 .48 18 1.2v1.2H6V1.2C6 .48 5.52 0 4.8 0S3.6.48 3.6 1.2v1.2H1.2C.6 2.4 0 2.88 0 3.6v16.8c0 .72.6 1.2 1.2 1.2h7.32c-.84-1.44-1.32-3.12-1.32-4.8 0-5.28 4.32-9.6 9.6-9.6 2.28 0 4.32.84 6 2.16V3.6c0-.6-.6-1.2-1.2-1.2Z'
				fill={color || '#fff'}
			/>
			<path
				d='M16.8 9.6c-3.96 0-7.2 3.24-7.2 7.2s3.24 7.2 7.2 7.2 7.2-3.24 7.2-7.2-3.24-7.2-7.2-7.2Zm2.4 8.4h-2.4c-.72 0-1.2-.48-1.2-1.2v-3.6c0-.72.48-1.2 1.2-1.2s1.2.48 1.2 1.2v2.4h1.2c.72 0 1.2.48 1.2 1.2s-.48 1.2-1.2 1.2Z'
				fill={color || '#fff'}
			/>
		</svg>
	);
}

export function NotebookIcon({ color }: IconColor) {
	return (
		<svg width={26} height={20} fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.542 20H6.255C4.7 20 3.14 18.806 2.708 17.283L.615 9.93c-.48-1.682.591-3.138 2.31-3.138h.372V3.016C3.297 1.35 4.621 0 6.255 0h5.921c.675 0 1.417.437 1.75 1.033l.75 1.336c.069.124.32.273.46.273h7.401c1.635 0 2.963 1.352 2.963 3.017v11.324c0 1.535-1.123 2.802-2.578 2.992a2.888 2.888 0 0 1-.38.025ZM4.777 6.792h14.435c1.554 0 3.113 1.195 3.547 2.718l1.26 4.429v-8.28c0-.831-.664-1.508-1.482-1.508h-7.402c-.67 0-1.412-.438-1.745-1.033l-.748-1.336c-.07-.125-.321-.273-.466-.273h-5.92c-.817 0-1.479.675-1.479 1.507v3.776Z'
				fill={color || '#fff'}
			/>
		</svg>
	);
}

export function ClientsIcon({ color }: IconColor) {
	return (
		<svg width={26} height={26} fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M19.583 15.783c-3.22 1.228-4.628 3.489-4.182 6.879H.647c-.52 0-.662-.137-.646-.654.088-2.699 1.134-4.976 3.19-6.801 1.787-1.585 3.922-2.363 6.311-2.425 1.508-.039 3.028-.024 4.525.135 2.049.216 3.8 1.143 5.292 2.525.085.08.164.17.24.26.02.024.02.065.026.08h-.002ZM5.1 5.88C5.087 2.642 7.76.014 11.08 0c3.313-.014 6.046 2.586 6.06 5.77.017 3.337-2.619 5.926-6.052 5.947-3.283.02-5.975-2.605-5.988-5.835V5.88Z'
				fill={color || '#fff'}
			/>
			<path
				d='M21.198 25.999c-2.645-.004-4.772-2.077-4.768-4.652.003-2.543 2.158-4.648 4.754-4.645 2.674.004 4.807 2.072 4.816 4.656.01 2.601-2.239 4.7-4.802 4.64Zm-.468-3.773c-.503-.44-.994-.865-1.484-1.294-.357-.312-.69-.345-.946-.092-.252.252-.2.603.148.911.621.548 1.246 1.088 1.875 1.627.321.274.646.271.957-.034a310.602 310.602 0 0 0 2.997-2.966c.323-.323.332-.642.062-.892-.25-.23-.576-.198-.887.101-.314.303-.615.62-.928.924-.585.565-1.178 1.123-1.796 1.715h.002Z'
				fill={color || '#fff'}
			/>
		</svg>
	);
}

export function AgentsIcon({ color }: IconColor) {
	return (
		<svg width={26} height={24} fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M20.392 6.085c0 3.084-2.444 5.584-5.457 5.584-3.014 0-5.458-2.5-5.458-5.584C9.477 3 11.921.5 14.935.5c3.013 0 5.457 2.5 5.457 5.585Z'
				fill={color || '#fff'}
			/>
			<path
				d='M2.862 18.216a2.78 2.78 0 0 1-1.564-.478A2.951 2.951 0 0 1 0 15.28c0-.174.012-.348.042-.515.055-.152.108-.306.161-.46l.16-.459.242-.691c.876-2.518 1.752-5.033 2.724-7.506.443-1.13 1.158-2.234 2.62-2.258 1.624-.031 2.855 1.8 2.291 3.363-.27.748-.613 1.77-.95 2.773-.486 1.45-.96 2.858-1.178 3.339h5.433l1.053 3.748c.512 1.825 1.02 3.64 1.033 3.68.024-.584.673-5.753.673-5.753l-.012-.025-.91-1.526h3.238l-.91 1.526c-.005.019-.011.025-.011.025s.648 5.175.673 5.752c.024-.08 2.05-7.31 2.05-7.31h.005c.164.007 4.069.18 5.518 1.9.625.744.97 1.538 1.11 2.407l.14.925L26 23.5H8.259v-5.284H2.862Z'
				fill={color || '#fff'}
			/>
		</svg>
	);
}

export function BillingIcon({ color }: IconColor) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={22} height={24} fill={color || '#fff'}>
			<path
				d='m15.846 19.638-2.693 2.311c-.334.287-.667.575-1.003.86-.7.594-1.505.599-2.204.012-1.22-1.022-2.438-2.047-3.656-3.07-.039-.033-.079-.063-.136-.109l-1.605 1.359c-.878.744-1.758 1.486-2.633 2.235-.29.247-.598.352-.953.178C.611 23.24.5 22.93.5 22.547.503 16.055.502 9.563.503 3.072c0-1.508 1.041-2.57 2.522-2.57C8.339.5 13.654.5 18.969.501c1.485 0 2.527 1.055 2.528 2.563.001 6.492 0 12.984.003 19.476 0 .383-.105.695-.456.871-.365.183-.679.064-.973-.187-1.399-1.192-2.8-2.379-4.224-3.586ZM10.99 6.25h5.576c.416 0 .712-.189.84-.528.213-.564-.194-1.114-.83-1.114-3.609-.002-7.217 0-10.825 0-.143 0-.286-.005-.429 0a.803.803 0 0 0-.73.54c-.201.557.199 1.1.821 1.1 1.859.004 3.718.002 5.577.002Zm.018 4.107h5.577c.397-.001.693-.193.82-.523.216-.562-.19-1.119-.823-1.12H6.917c-.521 0-1.043-.004-1.564.002a.797.797 0 0 0-.76.532c-.206.557.194 1.107.813 1.108 1.867.003 3.734 0 5.602 0Zm.018 2.464H8.048c-.9 0-1.8-.005-2.7.002a.808.808 0 0 0-.804.735.82.82 0 0 0 .617.88c.112.027.233.025.35.025h10.976c.076 0 .152.006.227-.003.34-.04.58-.22.697-.548.195-.548-.203-1.088-.81-1.09-1.858-.003-3.717 0-5.576 0v-.001Z'
				fill={color || '#fff'}
			/>
		</svg>
	);
}
