import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import {
	setGoogleMap,
	setGoogleMapsDestinationMarker,
	setGoogleMapsMarker,
	setGoogleMapsPolyline,
	setGoogleMapsScriptStatus,
	setMapCoordinates
} from '../../store/actions';
import { RootStore } from '../../store/store';
import loadGMaps from '~js-scripts/loadGMaps';

const Maps: React.FC = () => {
	/**
	 * Google Maps Scripts
	 */
	const dispatch = useDispatch();
	const {
		isLoadedGoogleMapsScript,
		addressCoordinates,
		map,
		marker,
		destinationCoordinates,
		destinationMarker,
		pathPolyline
	} = useSelector((state: RootStore) => state.app);

	const mapsDivRef = useRef<HTMLDivElement>(null);

	// Load google maps script
	useEffect(() => {
		if (!isLoadedGoogleMapsScript) {
			loadGMaps(() => dispatch(setGoogleMapsScriptStatus(true)));
		}
	}, [isLoadedGoogleMapsScript, dispatch]);

	// Mount the Map
	useEffect(() => {
		if (isLoadedGoogleMapsScript && mapsDivRef) {
			//console.log('mounting map');
			dispatch(
				setGoogleMap(
					// @ts-ignore
					new window.google.maps.Map(mapsDivRef.current, {
						center: { lat: 23.634501, lng: -102.552784 },
						zoom: 5,
						gestureHandling: 'cooperative' //enable crtl on scroll
					})
				)
			);
		}
	}, [isLoadedGoogleMapsScript, dispatch]);

	// Create the marker and listen for coordinates
	useEffect(() => {
		if (!map) return;
		// Create the marker
		if (!marker) {
			dispatch(
				setGoogleMapsMarker(
					new google.maps.Marker({
						map,
						anchorPoint: new google.maps.Point(0, -29),
						animation: google.maps.Animation.DROP,
						draggable: true,
						crossOnDrag: true
					})
				)
			);
			return;
		}

		// Simple Marker
		if (addressCoordinates) {
			if (destinationCoordinates) {
				marker.setDraggable(false);
				map.setZoom(11);
			} else {
				marker.setDraggable(true);
				map.setZoom(17);
			}
			// Marker
			map.setCenter(addressCoordinates);
			marker.setPosition(addressCoordinates);
		}

		// Listen for the destination marker
		if (destinationCoordinates) {
			if (!destinationMarker) {
				dispatch(
					setGoogleMapsDestinationMarker(
						new google.maps.Marker({
							map,
							animation: google.maps.Animation.DROP
						})
					)
				);
				return;
			}
			if (!pathPolyline) {
				dispatch(
					setGoogleMapsPolyline(
						new google.maps.Polyline({
							path: [destinationCoordinates, addressCoordinates!],
							geodesic: true,
							strokeColor: '#FF0000',
							strokeOpacity: 1.0,
							strokeWeight: 2
						})
					)
				);
				return;
			}
			destinationMarker.setPosition(destinationCoordinates);
			pathPolyline.setMap(map);
		}
	}, [
		addressCoordinates,
		map,
		dispatch,
		marker,
		destinationCoordinates,
		destinationMarker,
		pathPolyline
	]);

	useEffect(() => {
		if (!marker) return;
		google.maps.event.addListener(marker, 'dragend', function (evt) {
			dispatch(setMapCoordinates({ lat: evt.latLng.lat(), lng: evt.latLng.lng() }));
		});
	}, [marker, dispatch]);

	// Clean the map
	useEffect(() => {
		return () => {
			//console.log('cleaned up map');
			dispatch(setGoogleMap(null));
			dispatch(setGoogleMapsMarker(null));
			dispatch(setGoogleMapsDestinationMarker(null));
			dispatch(setGoogleMapsPolyline(null));
		};
	}, [dispatch]);

	if (!isLoadedGoogleMapsScript) {
		return <CircularProgress style={{ marginTop: 100, marginLeft: 120 }} />;
	}

	return <div ref={mapsDivRef} style={{ width: '100%', height: '100%', borderRadius: '8px' }} />;
};

export default Maps;
